/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import videoPlayicon from "../../assets/images/video-playicon.png";
import "../../assets/css/video.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faXmark } from "@fortawesome/free-solid-svg-icons";
import videoContent from "../../assets/video/Healthcare Organizations.mp4";

export default function Video() {
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);

  const toggleVideoPopup = () => {
    setIsVideoPopupOpen(!isVideoPopupOpen);
  };

  return (
    <section className="video-con">
      <div className="video_bg position-relative">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12">
              <div className="video_content">
                {/* <a
                  className="play-icon mr-0 popup-vimeo"
                  onClick={toggleVideoPopup}
                >
                  <figure className="thumb mb-0">
                    <img
                      src={videoPlayicon}
                      style={{ cursor: "pointer" }}
                      alt=""
                      className="img-fluid hover-effect"
                    />
                  </figure>
                </a> */}
                <h2 className="text-white">
                  Immersive Video Training Experience
                </h2>
                <p className="col-lg-11 mx-auto text-white text-size-18">
                  Engage in an immersive learning experience with our video
                  training modules. Visualize complex concepts, follow
                  step-by-step tutorials, and grasp intricate details through
                  our dynamic video content.
                </p>
                <a
                  className="play-button mr-0 popup-vimeo"
                  onClick={toggleVideoPopup}
                >
                  <i style={{ cursor: "pointer" }} className="thumb">
                    <FontAwesomeIcon icon={faPlay} />
                  </i>
                  Watch Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVideoPopupOpen && (
        <div className="video-popup-container">
          <div className="video-popup">
            <div className="video-popup-content">
              <video
                controls
                autoPlay
                src={videoContent}
                style={{ width: "100%", height: "auto" }}
              ></video>
              <Button className="close-btn" onClick={toggleVideoPopup}>
                <FontAwesomeIcon icon={faXmark} />
              </Button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
