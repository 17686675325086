import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const exportToExcel = (userData) => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(userData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Student Data");

    const headers = userData.length > 0 ? Object.keys(userData[0]) : [];

    const wscols = headers.map(() => ({ wch: 25 }));

    worksheet['!cols'] = wscols;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: "application/octet-stream" });

    // const now = new Date();
    // const dateString = now.toLocaleDateString().replace(/\//g, '-'); 
    // const timeString = now.toLocaleTimeString().replace(/:/g, '-'); 

    // const fileName = `GDA_Student_${dateString}_${timeString}.xlsx`;

    // saveAs(data, fileName);

    saveAs(data, "GDA_Student_Data.xlsx");
  };

const ExportExcel = ({ userData }) => {
//   const  exportToExcel = () => {

//     const workbook = XLSX.utils.book_new();

//     const worksheet = XLSX.utils.json_to_sheet(userData);

//     XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

//     const excelBuffer = XLSX.write(workbook, {
//       bookType: "xlsx",
//       type: "array",
//     });

//     const data = new Blob([excelBuffer], { type: "application/octet-stream" });

//     saveAs(data, "users.xlsx");
//   };

  return (
    <button onClick={exportToExcel} style={{ cursor: "pointer" }}>
      Export Excel
    </button>
  );
};

export default ExportExcel;
