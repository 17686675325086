import React, { useEffect, useState } from "react";
import "../assets/admin/adminStyle.css";
import "../admin/StudentDetailsCard.css";
// import { fetchingDataById } from "../js/adminData";
// import { useParams } from "react-router-dom";
// import { photoUri } from "../js/uri";
// import BackPage from "../components/Admin/BackPage";
import receipt from "../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { SERVER_URL } from "../utils/database_Config";

const StudentDetailsCard = ({ studentId }) => {
  // const {u_uid} = useParams();
  // const [employeeDetail, setEmployeeDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalSrc, setModalSrc] = useState("");
  const [studentDetail, setStudentDetail] = useState([]);
  //   useEffect(() => {
  //     if(u_uid){
  //      fetchData();
  //     }
  //  },[u_uid]);

  //  const fetchData = async () => {
  //    try{
  //    const {results} = await fetchingDataById(`/employee/${u_uid}`);
  //   setEmployeeDetails({
  //        ...results[0],

  //      });
  //    }
  //    catch(err){
  //     console.log(err);
  //    }
  //   }

  console.log("studentDetail : ", studentDetail);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}getStudentsAllDetails/${studentId}`)
      .then((response) => {
        setStudentDetail(response.data[0]);
      })
      .catch((error) => console.error("Error fetching details:", error));
  }, [studentId]);

  const openModal = (photourl) => {
    setModalSrc(photourl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  console.log(`${SERVER_URL}${studentDetail?.student_photo}`);

  return (
    <div className="container">
      <div className="row">
        {/* <BackPage link ="/employee"/> */}
        <div className="col-6">
          <div className="container1">
            <h2>Student Details</h2>
            <div className="detail-item">
              <span className="detail-label">First Name:</span>{" "}
              {studentDetail.first_name}
            </div>
            <div className="detail-item">
              <span className="detail-label">Last Name:</span>{" "}
              {studentDetail.last_name}
            </div>
            <div className="detail-item">
              <span className="detail-label">Registration Number:</span>{" "}
              {studentDetail.registration_number}
            </div>
            <div className="detail-item">
              <span className="detail-label">Batch Name:</span>{" "}
              {studentDetail.batch_name}
            </div>
            <div className="detail-item">
              <span className="detail-label">Course Name:</span>{" "}
              {studentDetail.course_name}
            </div>
            <div className="detail-item">
              <span className="detail-label">Deposite Fees:</span>{" "}
              {studentDetail.total_payment}
            </div>
            <div className="detail-item">
              <span className="detail-label">Email:</span> {studentDetail.email}
            </div>
            <div className="detail-item">
              <span className="detail-label">Phone:</span> {studentDetail.phone}
            </div>

            {/* <div className="detail-item">
              <span className="detail-label">Receiver Name:</span>{" "}
              {studentDetail.receiverName}
            </div>
            <div className="detail-item">
              <span className="detail-label">Cheque Date:</span>{" "}
              {studentDetail.chequeDate}
            </div> */}
          </div>
        </div>
        <div className="col-6">
          <div className="container1">
            <div className="detail-item">
              <span className="detail-label">Student Photo:</span>
              {studentDetail.student_photo ? (
                <img
                  className="img-thumbnail"
                  src={`${SERVER_URL}${studentDetail?.student_photo}`}
                  onClick={() =>
                    openModal(`${SERVER_URL}${studentDetail?.student_photo}`)
                  }
                  width="100"
                  alt="receipt"
                />
              ) : (
                <img
                  className="img-thumbnail"
                  src={``}
                  onClick={() => openModal(receipt)}
                  width="100"
                  alt="receipt_thumb"
                />
              )}
            </div>
            <div className="detail-item">
              <span className="detail-label">Student Signature Photo:</span>
              {studentDetail.student_signature ? (
                <img
                  className="img-thumbnail"
                  src={`${SERVER_URL}${studentDetail?.student_signature}`}
                  onClick={() =>
                    openModal(
                      `${SERVER_URL}${studentDetail?.student_signature}`
                    )
                  }
                  width="100"
                  alt="receipt"
                />
              ) : (
                <img
                  className="img-thumbnail"
                  src={``}
                  onClick={() => openModal(receipt)}
                  width="100"
                  alt="receipt_thumb"
                />
              )}
            </div>
            <div className="detail-item">
              <span className="detail-label">Student Aadhar Photo:</span>
              {studentDetail.student_aadhar_photo ? (
                <img
                  className="img-thumbnail"
                  src={`${SERVER_URL}${studentDetail?.student_aadhar_photo}`}
                  onClick={() =>
                    openModal(
                      `${SERVER_URL}${studentDetail?.student_aadhar_photo}`
                    )
                  }
                  width="100"
                  alt="receipt"
                />
              ) : (
                <img
                  className="img-thumbnail"
                  src={``}
                  onClick={() => openModal(receipt)}
                  width="100"
                  alt="receipt_thumb"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------modal section--------------------- */}

      {showModal && (
        <div
          className="modal"
          // tabIndex="-1"
          role="dialog"
          style={{ display: "block", border: "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ border: "none", maxWidth: "calc(100% - 30.5rem)" }}
          >
            <div
              className="modal-content"
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <div className="fade show" style={{ textAlign: "end" }}>
                <span onClick={closeModal} className="close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{
                      fontSize: "30px",
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
              <div className="">
                <img src={modalSrc} className="img-fluid" alt="Employee" />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal backdrop */}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default StudentDetailsCard;
