import React, { useState, useEffect } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import JobDetails from "./JobDetails";
import jobsData from "./jobsData.json";
import SubBanner from "../components/subBanner/SubBanner";

const JobList = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  const handleApplyClick = (job) => {
    setSelectedJob(job);
  };

  return (
    <>
      <SubBanner
        title={"Job openings"}
        page={"Job openings"}
        subTitle={`Explore our current job openings and discover how you can make an impact in a dynamic and supportive environment.
              `}
      />
      <Container className="mt-5 mb-5">
        <Row>
          {jobsData.map((job) => (
            <Col key={job.id} sm={12} md={6} lg={4}>
              <Card className="mb-4 job-card">
                <Card.Body>
                  <Card.Title className="job-title">{job.title}</Card.Title>
                  <Card.Text>
                    <strong>Positions:</strong> {job.positions}
                  </Card.Text>
                  <Card.Text>
                    <strong>Salary:</strong> {job.salary}
                  </Card.Text>
                  <Card.Text>
                    <strong>Eligibility:</strong> {job.eligibility}
                  </Card.Text>
                  <Card.Text>
                    <strong>Districts:</strong> {job.districts.join(", ")}
                  </Card.Text>
                  <Button
                    variant="outline-primary"
                    className="apply-btn"
                    onClick={() => handleApplyClick(job)}
                  >
                    Apply Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {selectedJob && (
          <JobDetails job={selectedJob} onClose={() => setSelectedJob(null)} />
        )}
      </Container>
    </>
  );
};

export default JobList;
