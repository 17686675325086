import { faEye, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import "./addStudent.css";
import MultiStepProgressBar from "../../stepProgressBar/MultiStepProgressBar";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import QRcode from "../../assets/images/QR code.png";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { SERVER_URL } from "../../utils/database_Config";
import { isAdult } from "../../utils/isAdult";

function AddStudent() {
  const { id } = useParams();

  const [addressUpdate, setAddressUpdate] = useState(true);

  const [qualificationUpdate, setQualificationUpdate] = useState(false);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}getStudentsPersonalDetails/${id}`)
      .then((response) => {
        setPersonalDetails({
          firstName: response.data[0]?.first_name || "",
          lastName: response.data[0]?.last_name || "",
          fatherName: response.data[0]?.father_name || "",
          motherName: response.data[0]?.mother_name || "",
          dob: response.data[0]?.age || "",
          casteCategory: response.data[0]?.caste_category || "",
          gender: response.data[0]?.gender || "",
          maritalStatus: response.data[0]?.marital_status || "",
          aadharNo: response.data[0]?.aadhar_no || "",
          email: response.data[0]?.email || "",
          phone: response.data[0]?.phone || "",
          studentPhoto: response.data[0]?.student_photo || "",
          studentSignature: response.data[0]?.student_signature || "",
          aadharPhoto: response.data[0]?.student_aadhar_photo || "",
        });
      })
      .catch((error) => console.error("Error fetching details:", error));
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}getStudentAddress/${id}/address`
        );
        if (response.data.length > 0) {
          const info1 = response.data[0].information_one.split(", ");
          const info2 = response.data[0].information_two.split(", ");

          if (info1.length >= 4) {
            setStudentPresentAddress({
              presentAddress: `${info1[0]}`,
              state: info1[1],
              district: info1[2],
              pinCode: info1[3] || "",
            });
            setAddressUpdate(false);
          }

          if (info2.length >= 4) {
            setStudentPermanentAddress({
              permanentAddress: `${info2[0]}`,
              permanentState: info2[1],
              permanentDistrict: info2[2],
              permanentPinCode: info2[3] || "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}getStudentCourseRegistration/${id}`
        );
        const { course_id, batch_id, enrollment_date } = response.data[0];

        course_id && setEnrollmentDisable(true);

        setEnrollment({
          courseId: course_id,
          batchId: batch_id,
          enrollmentDate: moment(enrollment_date)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        });
        setSelectedCourse(course_id);
        setPayment({
          ...payment,
          courseId: course_id,
          totalAmount: getCourseFees(courses, course_id),
        });
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchStudentDetails();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${SERVER_URL}getStudentQualification/${id}`
        );

        const formattedData = response.data.map((item) => ({
          studentId: item.student_id,
          type: item.type,
          qualification: item.title,
          boardUniversity: item.information_one,
          year: item.information_two,
          percentage: item.information_three,
          document: item.attachment,
        }));

        setEducations(formattedData);
        formattedData.length === 0 && setQualificationUpdate(true);
      } catch (error) {
        console.error("Error fetching student information:", error);
      }
    }

    fetchData();
  }, [id]);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [imageModal, setImageModal] = useState(false);

  const [imageURL, setImageURL] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const handleClose = () => setShow(false);

  const handleImageModal = () => setImageModal(false);

  const studentFileInputRef = useRef(null);
  const signatureFileInputRef = useRef(null);
  const aadharFileInputRef = useRef(null);
  // const documentFileInputRef = useRef(null);

  const documentFileInputRefs = useRef([]);

  const [courses, setCourses] = useState([]);

  const [batches, setBatches] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState("");

  const [enrollmentDisable, setEnrollmentDisable] = useState(false);

  const types = [
    { id: 1, type: "10th", title: "High School" },
    { id: 2, type: "12th", title: "Intermediate" },
    { id: 3, type: "diploma", title: "Diploma" },
    { id: 4, type: "bachelor", title: "Bachelor" },
    { id: 5, type: "master", title: "Master" },
  ];

  const [studentId, setStudentId] = useState(null);

  const modeOfTransaction = [
    { id: 1, mode: "Cash" },
    { id: 2, mode: "QR Code" },
  ];

  const [payment, setPayment] = useState({
    studentId: studentId,
    courseId: null,
    registrationFees: "",
    currency: "INR",
    totalAmount: "",
    modeOfTransaction: "",
    transactionID: "",
  });

  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    fatherName: "",
    motherName: "",
    dob: "",
    casteCategory: "",
    gender: "",
    maritalStatus: "",
    aadharNo: "",
    email: "",
    phone: "",
    studentPhoto: "",
    studentSignature: "",
    aadharPhoto: "",
    status: 1,
  });

  const [personalDetailsErrors, setPersonalDetailsErrors] = useState({
    firstName: false,
    lastName: false,
    fatherName: false,
    motherName: false,
    dob: false,
    casteCategory: false,
    gender: false,
    maritalStatus: false,
    aadharNo: false,
    email: false,
    phone: false,
    studentPhoto: false,
    studentSignature: false,
    aadharPhoto: false,
  });

  const [enrollment, setEnrollment] = useState({
    courseId: "",
    batchId: "",
    enrollmentDate: "",
  });

  const [enrollmentError, setEnrollmentError] = useState({
    courseId: false,
    batchId: false,
    enrollmentDate: false,
    registrationFees: false,
  });

  const [studentPresentAddress, setStudentPresentAddress] = useState({
    presentAddress: "",
    state: "",
    district: "",
    pinCode: "",
  });

  const [studentPermanentAddress, setStudentPermanentAddress] = useState({
    permanentAddress: "",
    permanentState: "",
    permanentDistrict: "",
    permanentPinCode: "",
  });

  const [
    studentPresentAddressErrors,
    setStudentPresentAddressErrors,
  ] = useState({
    presentAddress: false,
    state: false,
    district: false,
    pinCode: false,
  });

  const [
    studentPermanentAddressErrors,
    setStudentPermanentAddressErrors,
  ] = useState({
    permanentAddress: false,
    permanentState: false,
    permanentDistrict: false,
    permanentPinCode: false,
  });

  const [isChecked, setIsChecked] = useState(false);

  const [educations, setEducations] = useState([
    {
      studentId: studentId,
      type: "",
      qualification: "",
      boardUniversity: "",
      year: "",
      percentage: "",
      document: "",
    },
  ]);

  //fix me
  if (documentFileInputRefs.current.length !== educations.length) {
    documentFileInputRefs.current = Array(educations.length)
      .fill()
      .map((_, i) => documentFileInputRefs.current[i] || React.createRef());
  }

  const [validationErrors, setValidationErrors] = useState([{}]);

  const [page, setPage] = useState("pageone");

  useEffect(() => {
    axios
      .get(`${SERVER_URL}courses`)
      .then((response) => setCourses(response.data))
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      axios
        .get(`${SERVER_URL}batches/${selectedCourse}`)
        .then((response) => setBatches(response.data))
        .catch((error) => console.error("Error fetching batches:", error));
    } else {
      setBatches([]);
    }
  }, [selectedCourse]);

  const showSelectedFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const addEducationRow = (e) => {
    e.preventDefault();
    setEducations([
      ...educations,
      {
        studentId: studentId || id,
        type: "",
        qualification: "",
        boardUniversity: "",
        year: "",
        percentage: "",
        document: "",
      },
    ]);
    setValidationErrors([...validationErrors, {}]);
  };

  const deleteEducationRow = (e, index) => {
    e.preventDefault();
    if (index !== 0) {
      const updatedEducations = [...educations];
      updatedEducations.splice(index, 1);
      setEducations(updatedEducations);
      const updatedValidationErrors = [...validationErrors];
      updatedValidationErrors.splice(index, 1);
      setValidationErrors(updatedValidationErrors);
    } else {
      console.log("Cannot delete the first row");
    }
  };

  const handleDocument = (e, index) => {
    const { name, files } = e.target;

    const selectedFile = files[0];

    const validationMessage = validateFile(selectedFile);

    if (!validationMessage) {
      const updatedEducations = [...educations];
      updatedEducations[index][name] = selectedFile;
      setEducations(updatedEducations);
      validateInput(updatedEducations[index], index);
    } else {
      console.log(" handleDocument : file validation failed . ");
    }
  };

  const handleTableDataChange = (e, index) => {
    const { name, value } = e.target;

    const updatedEducations = [...educations];

    if (name === "qualification") {
      const selectedType = types.find((type) => type.type === value);
      updatedEducations[index].type = selectedType.type;
      updatedEducations[index].qualification = selectedType.title;
    } else {
      updatedEducations[index][name] = value;
    }

    setEducations(updatedEducations);
    validateInput(updatedEducations[index], index);
  };

  const validateInput = (education, index) => {
    const errors = {};
    if (!education.qualification)
      errors.qualification = "* Qualification is required";
    if (!education.boardUniversity)
      errors.boardUniversity = "* University is required";
    if (!education.year) errors.year = "* Year is required";
    if (!education.percentage) errors.percentage = "* Percentage is required";
    if (!education.document) errors.document = "* Upload Document";

    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;

    setValidationErrors(updatedValidationErrors);
  };

  useEffect(() => {
    const saveAddressIfSame = () => {
      if (isChecked) {
        setStudentPermanentAddress({
          permanentAddress: studentPresentAddress.presentAddress,
          permanentState: studentPresentAddress.state,
          permanentDistrict: studentPresentAddress.district,
          permanentPinCode: studentPresentAddress.pinCode,
        });
      }
      // else {
      //   setStudentPermanentAddress({
      //     permanentAddress: "",
      //     permanentState: "",
      //     permanentDistrict: "",
      //     permanentPinCode: "",
      //   });
      // }
    };
    saveAddressIfSame();
  }, [isChecked, studentPresentAddress]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const triggerFileInput = (fileInputRef) => {
    fileInputRef.current.click();
  };

  const triggerQualificationFileInput = (index) => {
    documentFileInputRefs.current[index].current.click();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetails({ ...personalDetails, [name]: value });
    setPersonalDetailsErrors({ ...personalDetailsErrors, [name]: false });
  };

  function getCourseFees(courses, id) {
    const course = courses.find((course) => course.id === Number(id));
    return course ? course.course_fees : null;
  }

  const enrollmentOnChange = (e) => {
    const { name, value } = e.target;
    setEnrollment({ ...enrollment, [name]: value });
    setEnrollmentError({ ...enrollmentError, [name]: false });
    if (name === "courseId") {
      setSelectedCourse(e.target.value);
      setPayment({
        ...payment,
        courseId: e.target.value,
        totalAmount: getCourseFees(courses, e.target.value),
      });
    }
  };

  const paymentOnChange = (e) => {
    const { name, value } = e.target;
    setPayment({ ...payment, [name]: value });
    if (value === "QR Code") setShow(true);
    // setEnrollmentError({ ...enrollmentError, [name]: false });
    // setSelectedCourse(e.target.value);
  };

  const handleOnChangePresentAddress = (e) => {
    const { name, value } = e.target;
    setStudentPresentAddress({ ...studentPresentAddress, [name]: value });
    setStudentPresentAddressErrors({
      ...studentPresentAddressErrors,
      [name]: false,
    });
  };

  const handleOnChangePermanentAddress = (e) => {
    const { name, value } = e.target;
    setStudentPermanentAddress({ ...studentPermanentAddress, [name]: value });
    setStudentPermanentAddressErrors({
      ...studentPermanentAddressErrors,
      [name]: false,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;

    const selectedFile = files[0];
    const validationMessage = validateFile(selectedFile);

    if (validationMessage) {
      setPersonalDetails({ ...personalDetails, [name]: "" });
      setPersonalDetailsErrors({
        ...personalDetailsErrors,
        [name]: validationMessage,
      });
    } else {
      setPersonalDetails({ ...personalDetails, [name]: selectedFile });
      setPersonalDetailsErrors({ ...personalDetailsErrors, [name]: false });
    }
  };

  const validateFile = (file) => {
    const validFileTypes = ["image/jpeg", "image/png"];
    const maxFileSize = 200 * 1024;

    if (!file) {
      return "Please select a file.";
    }
    if (!validFileTypes.includes(file.type)) {
      return "Invalid file type. Please upload an image.";
    }
    if (file.size > maxFileSize) {
      return "File size exceeds the limit of 2MB.";
    }
    return "";
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
        return value.trim() ? false : "Please enter first name";
      // case "lastName":
      //   return value.trim() ? false : "Please enter last name";
      case "fatherName":
        return value.trim() ? false : "Please enter father name";
      case "motherName":
        return value.trim() ? false : "Please enter mother name";
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return value.trim()
          ? emailRegex.test(value)
            ? false
            : "Please enter a valid email address"
          : "Please enter email";
      case "phone":
        const phoneRegex = /^\d{10}$/;
        return value.trim()
          ? phoneRegex.test(value)
            ? false
            : "Please enter a valid 10-digit phone number"
          : "Please enter phone number";
      case "dob":
        return value.trim()
          ? isAdult(value)
            ? false
            : "Minimum age for registration is 18 years"
          : "Please enter date of birth";
      case "casteCategory":
        return value && value !== "Select Category"
          ? false
          : "Please select a category";
      case "maritalStatus":
        return value && value !== "Select Marital Status"
          ? false
          : "Please select a status";
      case "gender":
        return value && value !== "Select Gender"
          ? false
          : "Please select a gender";
      case "aadharNo":
        const aadharNoRegex = /^\d{12}$/;
        return value.trim()
          ? aadharNoRegex.test(value)
            ? false
            : "Please enter a valid 12-digit aadhar number"
          : "Please enter aadhar number";
      case "studentPhoto":
        return value?.name?.trim() || value.trim()
          ? false
          : "Please upload student photo";
      case "studentSignature":
        return value?.name?.trim() || value.trim()
          ? false
          : "Please upload student signature";
      case "aadharPhoto":
        return value?.name?.trim() || value.trim()
          ? false
          : "Please upload student aadhar photo";
      case "presentAddress":
        return value.trim() ? false : "Please enter present address";
      case "state":
        return value && value !== "Select State"
          ? false
          : "Please select present state";
      case "district":
        return value.trim() ? false : "Please enter present district name";
      case "pinCode":
        const pinCodeRegex = /^\d{6}$/;
        return value.trim()
          ? pinCodeRegex.test(value)
            ? false
            : "Enter 6-digit pin code"
          : "Please enter present pin code";
      case "permanentAddress":
        return value.trim() ? false : "Please enter permanent address";
      case "permanentState":
        return value && value !== "Select State"
          ? false
          : "Please select permanent state";
      case "permanentDistrict":
        return value.trim() ? false : "Please enter permanent district name";
      case "permanentPinCode":
        return value.trim() ? false : "Please enter permanent pin code";
      default:
        return "";
    }
  };

  const submitPersonalDetails = async (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(personalDetails).forEach((field) => {
      const error = validateField(field, personalDetails[field]);
      if (error) newErrors[field] = error;
    });

    if (Object.keys(newErrors).length === 0) {
      setPersonalDetailsErrors({});
      const token = localStorage.getItem("admin-token");

      const formData = new FormData();

      for (const key in personalDetails) {
        formData.append(key, personalDetails[key]);
      }

      if (id) {
        axios
          .put(`${SERVER_URL}updateStudentPersonalDetails/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            nextPage("pagetwo");
          })
          .catch((error) => {
            console.error("There was an error adding the student:", error);
          });
      } else {
        axios
          .post(`${SERVER_URL}personal-details/add`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setStudentId(response.data.result.insertId);
            nextPage("pagetwo");
            setEducations([
              {
                studentId: response.data.result.insertId,
                type: "",
                qualification: "",
                boardUniversity: "",
                year: "",
                percentage: "",
                document: "",
              },
            ]);
          })
          .catch((error) => {
            console.error("There was an error adding the student:", error);
          });
      }
    } else {
      setPersonalDetailsErrors(newErrors);
    }
  };

  const validateEnrollmentField = (fieldName, value) => {
    switch (fieldName) {
      case "registrationFees":
        return value.trim() ? false : "Please enter deposite amount";
      case "batchId":
        return value.trim() ? false : "Please enter batch number";
      case "enrollmentDate":
        return value.trim() ? false : "Please enter enrollment date";
      case "courses":
        return value && value !== "Select Course"
          ? false
          : "Please select a course";
      default:
        return "";
    }
  };

  const submitEnrollment = async (e) => {
    e.preventDefault();

    const newErrors = {};
    // Object.keys(enrollment).forEach((field) => {
    //   const error = validateEnrollmentField(field, enrollment[field]);
    //   if (error) newErrors[field] = error;
    // });

    if (Object.keys(newErrors).length === 0) {
      setPersonalDetailsErrors({});

      const token = localStorage.getItem("admin-token");

      const enrollmentData = { ...enrollment, studentId: studentId || id };
      const paymentData = {
        ...payment,
        studentId: studentId || id,
        totalAmount: getCourseFees(courses, enrollment.courseId),
      };

      let courseRegistration;

      if (!enrollmentDisable) {
        courseRegistration = axios.post(
          `${SERVER_URL}studentDetails`,
          enrollmentData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      const paymentDetails = axios.post(
        `${SERVER_URL}studentPayment`,
        paymentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const allRequests = [paymentDetails];
      if (!enrollmentDisable) {
        allRequests.push(courseRegistration);
      }

      try {
        await axios.all(allRequests);

        if (!enrollmentDisable) {
        }
        nextPage("pagefour");
      } catch (err) {
        console.error("Error saving data:", err);
      }
    } else {
      setEnrollmentError(newErrors);
    }
  };

  const addressFormat = (address) => {
    const formattedAddress = [
      (address.presentAddress || address.permanentAddress).replace(/,/g, ' ').trim(),
      (address.state || address.permanentState).replace(/,/g, ' ').trim(),
      (address.district || address.permanentDistrict).replace(/,/g, ' ').trim(),
      (address.pinCode || address.permanentPinCode).replace(/,/g, ' ').trim()
    ]
    .filter(Boolean)
    .join(', ');
  
    return formattedAddress;
  };

  const submitAddress = async (e) => {
    e.preventDefault();

    const presentAddressErrors = {};
    Object.keys(studentPresentAddress).forEach((field) => {
      const error = validateField(field, studentPresentAddress[field]);
      if (error) presentAddressErrors[field] = error;
    });

    const permanentAddressErrors = {};
    Object.keys(studentPermanentAddress).forEach((field) => {
      const error = validateField(field, studentPermanentAddress[field]);
      if (error) permanentAddressErrors[field] = error;
    });

    if (
      Object.keys(presentAddressErrors).length === 0 &&
      Object.keys(permanentAddressErrors).length === 0
      // && Object.keys(personalDetailsErrors).length === 0
    ) {
      setStudentPresentAddressErrors({});
      setStudentPermanentAddressErrors({});

      const additionalInformation = [
        {
          studentId: studentId || id,
          type: "address",
          title: "Address",
          information_one: addressFormat(studentPresentAddress),
          information_two: addressFormat(studentPermanentAddress),
        },
      ];

      console.log('additionalInformation Test formate : ', additionalInformation);

      const token = localStorage.getItem("admin-token");

      if (!addressUpdate) {
        axios
          .put(
            `${SERVER_URL}updateStudentAdditionalInformation/${id}`,
            additionalInformation[0],
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            nextPage("pagethree");
          })
          .catch((error) => {
            console.error("There was an error adding the student:", error);
          });
      } else {
        axios
          .post(`${SERVER_URL}studentAddressData`, additionalInformation, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            nextPage("pagethree");
          })
          .catch((error) => {
            console.error("There was an error adding the address:", error);
          });
      }
    } else {
      setStudentPresentAddressErrors(presentAddressErrors);
      setStudentPermanentAddressErrors(permanentAddressErrors);
    }
  };

  function containsEmptyObject(arr) {
    return arr.some((item) => Object.keys(item).length === 0);
  }

  const submitQualification = (e) => {
    e.preventDefault();

    if (
      validateAllRows()
      // &&
      // containsEmptyObject(validationErrors) &&
      // Object.keys(personalDetailsErrors).length === 0 &&
      // Object.keys(studentPresentAddressErrors).length === 0 &&
      // Object.keys(studentPermanentAddressErrors).length === 0
    ) {
      const additionalInformation = educations.map((education) => ({
        studentId: studentId || id,
        type: education.type,
        title: education.qualification,
        information_one: education.boardUniversity,
        information_two: education.year,
        information_three: education.percentage,
        attachment: education.document,
      }));

      const token = localStorage.getItem("admin-token");

      const data = new FormData();
      additionalInformation.forEach((entry, index) => {
        data.append(`studentId[${index}]`, entry.studentId);
        data.append(`type[${index}]`, entry.type);
        data.append(`title[${index}]`, entry.title);
        data.append(`information_one[${index}]`, entry.information_one);
        data.append(`information_two[${index}]`, entry.information_two);
        data.append(`information_three[${index}]`, entry.information_three);
        data.append(`attachments`, entry.attachment);
      });

      axios
        .post(`${SERVER_URL}studentAdditionalInformation`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          navigate("/admin/studentsDetails");
        })
        .catch((error) => {
          console.error("There was an error adding the Qualification:", error);
        });
    }
  };

  const updateQualification = (e) => {
    e.preventDefault();

    if (
      validateAllRows()
      // &&
      // containsEmptyObject(validationErrors) &&
      // Object.keys(personalDetailsErrors).length === 0 &&
      // Object.keys(studentPresentAddressErrors).length === 0 &&
      // Object.keys(studentPermanentAddressErrors).length === 0
    ) {
      const additionalInformation = educations.map((education) => ({
        studentId: studentId || id,
        type: education.type,
        title: education.qualification,
        information_one: education.boardUniversity,
        information_two: education.year,
        information_three: education.percentage,
        attachment: education.document,
      }));

      const token = localStorage.getItem("admin-token");

      // const data = new FormData();
      // additionalInformation.forEach((entry, index) => {
      //   data.append(`studentId[${index}]`, entry.studentId);
      //   data.append(`type[${index}]`, entry.type);
      //   data.append(`title[${index}]`, entry.title);
      //   data.append(`information_one[${index}]`, entry.information_one);
      //   data.append(`information_two[${index}]`, entry.information_two);
      //   data.append(`information_three[${index}]`, entry.information_three);
      //   data.append(`attachments`, entry.attachment);
      // });

      const data = new FormData();
      data.append(
        "qualifications",
        JSON.stringify(
          additionalInformation.map((entry, index) => ({
            studentId: entry.studentId,
            type: entry.type,
            title: entry.title,
            information_one: entry.information_one,
            information_two: entry.information_two,
            information_three: entry.information_three,
            attachment: entry.attachment,
          }))
        )
      );

      additionalInformation.forEach((entry, index) => {
        if (entry.attachment) {
          data.append(`attachment_${index}`, entry.attachment);
        }
      });

      axios
        .put(`${SERVER_URL}updateStudentAdditionalData/${id}`, data, {
          //.post(`${SERVER_URL}studentAdditionalInformation`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          navigate("/admin/studentsDetails");
        })
        .catch((error) => {
          console.error("There was an error adding the Qualification:", error);
        });
    }
  };

  const nextPage = (page) => {
    setPage(page);
  };

  const nextPageNumber = (e, pageNumber) => {
    switch (pageNumber) {
      case "1":
        setPage("pageone");
        break;
      case "2":
        setPage("pagetwo");
        break;
      case "3":
        setPage("pagethree");
        break;
      case "4":
        setPage("pagefour");
        break;
      case "5":
        setPage("pagefive");
        break;
      default:
        setPage("1");
    }
  };

  const validateAllRows = () => {
    const errorsArray = educations.map((education, index) => {
      const errors = {};
      if (!education.qualification)
        errors.qualification = "Qualification is required";
      if (!education.boardUniversity)
        errors.boardUniversity = "Board/University is required";
      if (!education.year) errors.year = "Year is required";
      if (!education.percentage) errors.percentage = "Percentage is required";
      if (!education.document) errors.document = "* Upload Document";
      return errors;
    });

    setValidationErrors(errorsArray);
    return errorsArray.every((errors) => Object.keys(errors).length === 0);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Scan QR </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <img src={QRcode} alt="QR code" style={{ width: "80%" }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Payment Done
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imageModal}
        onHide={handleImageModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Student Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <img
            src={`${SERVER_URL}${imageURL}`}
            alt="modal_image"
            style={{ width: "80%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="stepProgess" style={{ marginTop: "10%" }}>
        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
        <section className="register-con" style={{ paddingBottom: "200px" }}>
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-12">
                <div className="register_content text-center">
                  <h6>Student Details Form</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="register-box">
                  <form id="contactpage" method="post">
                    <div className="row">
                      {
                        {
                          pageone: (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label> First Name</label>
                                      <input
                                        type="text"
                                        className="form_style"
                                        placeholder=" First Name"
                                        name="firstName"
                                        value={personalDetails.firstName}
                                        onChange={handleOnChange}
                                        id="firstName"
                                      />
                                      {personalDetailsErrors.firstName && (
                                        <span
                                          htmlFor="firstName"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.firstName}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label> Last Name</label>
                                      <input
                                        type="text"
                                        className="form_style"
                                        placeholder=" Last Name"
                                        name="lastName"
                                        id="lastName"
                                        value={personalDetails.lastName}
                                        onChange={handleOnChange}
                                      />
                                      {personalDetailsErrors.lastName && (
                                        <span
                                          htmlFor="lastName"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.lastName}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Father Name</label>
                                      <input
                                        type="fatherName"
                                        className="form_style"
                                        placeholder="Father Name"
                                        name="fatherName"
                                        id="fatherName"
                                        value={personalDetails.fatherName}
                                        onChange={handleOnChange}
                                      />
                                      {personalDetailsErrors.fatherName && (
                                        <span
                                          htmlFor="fatherName"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.fatherName}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Mother Name</label>
                                      <input
                                        type="MotherName"
                                        className="form_style"
                                        placeholder="Mother Name"
                                        name="motherName"
                                        id="motherName"
                                        value={personalDetails.motherName}
                                        onChange={handleOnChange}
                                      />
                                      {personalDetailsErrors.motherName && (
                                        <span
                                          htmlFor="motherName"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.motherName}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Date of birth</label>
                                      <input
                                        type="date"
                                        className="form_style"
                                        name="dob"
                                        id="dob"
                                        value={personalDetails.dob}
                                        onChange={handleOnChange}
                                      />
                                      {personalDetailsErrors.dob && (
                                        <span htmlFor="dob" className="error">
                                          * {personalDetailsErrors.dob}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <div className="form-group courses">
                                      <label>Caste Category</label>
                                      <select
                                        className="form-control"
                                        name="casteCategory"
                                        id="casteCategory"
                                        value={personalDetails.casteCategory}
                                        onChange={handleOnChange}
                                      >
                                        <option>Select Category</option>
                                        <option>GEN</option>
                                        <option>OBC</option>
                                        <option>SC</option>
                                        <option>ST</option>
                                      </select>
                                      {personalDetailsErrors.casteCategory && (
                                        <span
                                          htmlFor="casteCategory"
                                          className="error"
                                        >
                                          *{" "}
                                          {personalDetailsErrors.casteCategory}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Aadhar No </label>
                                      <input
                                        type="text"
                                        className="form_style"
                                        placeholder="Aadhar No."
                                        name="aadharNo"
                                        id="aadharNo"
                                        maxLength={12}
                                        value={personalDetails.aadharNo}
                                        onChange={handleOnChange}
                                      />
                                      {personalDetailsErrors.aadharNo && (
                                        <span
                                          htmlFor="aadharNo"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.aadharNo}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group courses">
                                      <label>Marital Status</label>
                                      <select
                                        className="form-control"
                                        name="maritalStatus"
                                        id="maritalStatus"
                                        value={personalDetails.maritalStatus}
                                        onChange={handleOnChange}
                                      >
                                        <option>Select Marital Status</option>
                                        <option>Married</option>
                                        <option>Unmarried</option>
                                        <option>Divorced</option>
                                        <option>Separated</option>
                                        <option>Widow</option>
                                      </select>
                                      {personalDetailsErrors.maritalStatus && (
                                        <span
                                          htmlFor="maritalStatus"
                                          className="error"
                                        >
                                          *{personalDetailsErrors.maritalStatus}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group courses">
                                      <label>Gender</label>
                                      <select
                                        className="form-control"
                                        name="gender"
                                        id="gender"
                                        value={personalDetails.gender}
                                        onChange={handleOnChange}
                                      >
                                        <option>Select Gender</option>
                                        <option>Male</option>
                                        <option>Female</option>
                                      </select>
                                      {personalDetailsErrors.gender && (
                                        <span
                                          htmlFor="gender"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.gender}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Email</label>
                                      <input
                                        type="email"
                                        className="form_style"
                                        placeholder="Email Address"
                                        name="email"
                                        id="email"
                                        value={personalDetails.email}
                                        onChange={handleOnChange}
                                      />
                                      {personalDetailsErrors.email && (
                                        <span htmlFor="email" className="error">
                                          * {personalDetailsErrors.email}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Phone</label>
                                      <input
                                        type="tel"
                                        className="form_style"
                                        placeholder="Phone Number"
                                        name="phone"
                                        id="phone"
                                        maxLength={10}
                                        value={personalDetails.phone}
                                        onChange={handleOnChange}
                                      />
                                      {personalDetailsErrors.phone && (
                                        <span htmlFor="phone" className="error">
                                          * {personalDetailsErrors.phone}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label> Photo </label>
                                      <input
                                        ref={studentFileInputRef}
                                        type="file"
                                        className="form_style"
                                        name="studentPhoto"
                                        id="studentPhoto"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                      />
                                      <FontAwesomeIcon
                                        style={{ cursor: "pointer" }}
                                        icon={faUpload}
                                        onClick={() =>
                                          triggerFileInput(studentFileInputRef)
                                        }
                                      />
                                      {id && (
                                        <FontAwesomeIcon
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          icon={faEye}
                                          onClick={() => {
                                            // personalDetails?.studentPhoto?.type?.startsWith(
                                            //   "image/"
                                            // )
                                            //   ? showSelectedFile(
                                            //       personalDetails.studentPhoto
                                            //     )
                                            //   : setImageURL(
                                            //       personalDetails.studentPhoto
                                            //     );
                                            setImageURL(
                                              personalDetails.studentPhoto
                                            );
                                            setImageModal(true);
                                          }}
                                        />
                                      )}
                                      <div
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {personalDetails.studentPhoto.name ||
                                          personalDetails.studentPhoto}
                                      </div>
                                      {personalDetailsErrors.studentPhoto && (
                                        <span
                                          htmlFor="studentPhoto"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.studentPhoto}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label> Signature </label>
                                      <input
                                        ref={signatureFileInputRef}
                                        type="file"
                                        className="form_style"
                                        name="studentSignature"
                                        id="studentSignature"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                      />
                                      <FontAwesomeIcon
                                        style={{ cursor: "pointer" }}
                                        icon={faUpload}
                                        onClick={() =>
                                          triggerFileInput(
                                            signatureFileInputRef
                                          )
                                        }
                                      />
                                      {id && (
                                        <FontAwesomeIcon
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          icon={faEye}
                                          onClick={() => {
                                            // personalDetails?.studentSignature?.type?.startsWith(
                                            //   "image/"
                                            // )
                                            //   ? showSelectedFile(
                                            //       personalDetails.studentSignature
                                            //     )
                                            //   : setImageURL(
                                            //       personalDetails.studentSignature
                                            //     );
                                            setImageURL(
                                              personalDetails.studentSignature
                                            );
                                            setImageModal(true);
                                          }}
                                        />
                                      )}
                                      <div
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {personalDetails.studentSignature
                                          .name ||
                                          personalDetails.studentSignature}
                                      </div>

                                      {personalDetailsErrors.studentSignature && (
                                        <span
                                          htmlFor="studentSignature"
                                          className="error"
                                        >
                                          *{" "}
                                          {
                                            personalDetailsErrors.studentSignature
                                          }
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Aadhar Photo </label>
                                      <input
                                        ref={aadharFileInputRef}
                                        type="file"
                                        className="form_style"
                                        placeholder="Aadhar Photo."
                                        name="aadharPhoto"
                                        id="aadharPhoto"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                      />
                                      <FontAwesomeIcon
                                        style={{ cursor: "pointer" }}
                                        icon={faUpload}
                                        onClick={() =>
                                          triggerFileInput(aadharFileInputRef)
                                        }
                                      />
                                      {id && (
                                        <FontAwesomeIcon
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          icon={faEye}
                                          onClick={() => {
                                            // personalDetails?.aadharPhoto?.type?.startsWith(
                                            //   "image/"
                                            // )
                                            //   ? showSelectedFile(
                                            //       personalDetails.aadharPhoto
                                            //     )
                                            //   : setImageURL(
                                            //       personalDetails.aadharPhoto
                                            //     );
                                            setImageURL(
                                              personalDetails.aadharPhoto
                                            );
                                            setImageModal(true);
                                          }}
                                        />
                                      )}
                                      <div
                                        style={{
                                          display: "inline-block",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {personalDetails.aadharPhoto.name ||
                                          personalDetails.aadharPhoto}
                                      </div>
                                      {personalDetailsErrors.aadharPhoto && (
                                        <span
                                          htmlFor="aadharPhoto"
                                          className="error"
                                        >
                                          * {personalDetailsErrors.aadharPhoto}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="register_button">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  <p className="text-size-14 mb-0">
                                    <span>*</span>All fields are mandatory.
                                  </p>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-12"
                                  style={{
                                    display: "flex",
                                    flexFlow: "row-reverse",
                                    gap: "20px",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    id="submit"
                                    className="register_now text-white text-decoration-none"
                                    onClick={(e) => {
                                      submitPersonalDetails(e);
                                    }}
                                  >
                                    Save
                                  </button>
                                  {id && (
                                    <button
                                      type="submit"
                                      id="submit"
                                      className="register_now text-white text-decoration-none"
                                      onClick={(e) => {
                                        nextPage("pagetwo");
                                      }}
                                    >
                                      Skip
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          ),
                          pagetwo: (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group mb-0">
                                      <label>Present Address</label>
                                      <textarea
                                        className="form_style"
                                        placeholder=" Present Address"
                                        rows="3"
                                        name="presentAddress"
                                        value={
                                          studentPresentAddress.presentAddress
                                        }
                                        onChange={handleOnChangePresentAddress}
                                      ></textarea>
                                      {studentPresentAddressErrors.presentAddress && (
                                        <span
                                          style={{ marginTop: "-15px" }}
                                          htmlFor="presentAddress"
                                          className="error"
                                        >
                                          *{" "}
                                          {
                                            studentPresentAddressErrors.presentAddress
                                          }
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group courses">
                                      <label>State</label>
                                      <select
                                        className="form-control"
                                        name="state"
                                        id="state"
                                        value={studentPresentAddress.state}
                                        onChange={handleOnChangePresentAddress}
                                      >
                                        <option>Select State</option>
                                        <option>UP</option>
                                        <option>MP</option>
                                        <option>MH</option>
                                      </select>
                                      {studentPresentAddressErrors.state && (
                                        <span htmlFor="state" className="error">
                                          * {studentPresentAddressErrors.state}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>District</label>
                                      <input
                                        className="form_style"
                                        placeholder="District Name"
                                        name="district"
                                        id="district"
                                        value={studentPresentAddress.district}
                                        onChange={handleOnChangePresentAddress}
                                      />
                                      {studentPresentAddressErrors.district && (
                                        <span
                                          htmlFor="district"
                                          className="error"
                                        >
                                          *{" "}
                                          {studentPresentAddressErrors.district}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>PIN</label>
                                      <input
                                        type="text"
                                        className="form_style"
                                        placeholder="Pin Code"
                                        name="pinCode"
                                        id="pinCode"
                                        maxLength={6}
                                        value={studentPresentAddress.pinCode}
                                        onChange={handleOnChangePresentAddress}
                                      />
                                      {studentPresentAddressErrors.pinCode && (
                                        <span
                                          htmlFor="pinCode"
                                          className="error"
                                        >
                                          *{studentPresentAddressErrors.pinCode}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 mt-4">
                                <div
                                  className="form-group d-flex"
                                  style={{ gap: "10px" }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    id="sameAddress"
                                    name="sameAddress"
                                    style={{ width: "auto" }}
                                    onChange={handleCheckboxChange}
                                  />
                                  <label
                                    htmlFor="sameAddress"
                                    style={{
                                      marginBottom: "0px",
                                      color: "#ff822d",
                                    }}
                                  >
                                    Permanent Address and Present Address are
                                    the same.
                                  </label>
                                </div>
                              </div>
                              {!isChecked && (
                                <>
                                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="form-group mb-0">
                                          <label>Permanent Address</label>
                                          <textarea
                                            className="form_style"
                                            placeholder=" Permanent Address"
                                            rows="3"
                                            name="permanentAddress"
                                            value={
                                              studentPermanentAddress.permanentAddress
                                            }
                                            onChange={
                                              handleOnChangePermanentAddress
                                            }
                                          ></textarea>
                                          {studentPermanentAddressErrors.permanentAddress && (
                                            <span
                                              style={{ marginTop: "-15px" }}
                                              htmlFor="permanentAddress"
                                              className="error"
                                            >
                                              *
                                              {
                                                studentPermanentAddressErrors.permanentAddress
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="form-group courses">
                                          <label>State</label>
                                          <select
                                            className="form-control"
                                            name="permanentState"
                                            value={
                                              studentPermanentAddress.permanentState
                                            }
                                            onChange={
                                              handleOnChangePermanentAddress
                                            }
                                          >
                                            <option>Select State</option>
                                            <option>UP</option>
                                            <option>MP</option>
                                            <option>MH</option>
                                          </select>
                                          {studentPermanentAddressErrors.permanentState && (
                                            <span
                                              htmlFor="permanentState"
                                              className="error"
                                            >
                                              *
                                              {
                                                studentPermanentAddressErrors.permanentState
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>District</label>
                                          <input
                                            className="form_style"
                                            placeholder="District Name"
                                            name="permanentDistrict"
                                            id="permanentDistrict"
                                            value={
                                              studentPermanentAddress.permanentDistrict
                                            }
                                            onChange={
                                              handleOnChangePermanentAddress
                                            }
                                          />
                                          {studentPermanentAddressErrors.permanentDistrict && (
                                            <span
                                              htmlFor="permanentDistrict"
                                              className="error"
                                            >
                                              *
                                              {
                                                studentPermanentAddressErrors.permanentDistrict
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>PIN</label>
                                          <input
                                            type="text"
                                            className="form_style"
                                            placeholder="Pin Code"
                                            name="permanentPinCode"
                                            id="permanentPinCode"
                                            value={
                                              studentPermanentAddress.permanentPinCode
                                            }
                                            onChange={
                                              handleOnChangePermanentAddress
                                            }
                                          />
                                          {studentPermanentAddressErrors.permanentPinCode && (
                                            <span
                                              htmlFor="permanentPinCode"
                                              className="error"
                                            >
                                              *
                                              {
                                                studentPermanentAddressErrors.permanentPinCode
                                              }
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="register_button">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  <p className="text-size-14 mb-0">
                                    <span>*</span>All fields are mandatory.
                                  </p>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-12"
                                  style={{
                                    display: "flex",
                                    flexFlow: "row-reverse",
                                    gap: "20px",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    id="submit"
                                    className="register_now text-white text-decoration-none"
                                    onClick={(e) => {
                                      submitAddress(e);
                                    }}
                                  >
                                    Save
                                  </button>
                                  {id && (
                                    <button
                                      type="submit"
                                      id="submit"
                                      className="register_now text-white text-decoration-none"
                                      onClick={(e) => {
                                        nextPage("pagethree");
                                      }}
                                    >
                                      Skip
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          ),
                          pagethree: (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group courses">
                                      <label>Courses</label>
                                      <select
                                        disabled={enrollmentDisable}
                                        className="form-control"
                                        name="courseId"
                                        id="courseId"
                                        value={enrollment.courseId}
                                        onChange={enrollmentOnChange}
                                      >
                                        <option>Select Course</option>
                                        {courses.map((course) => (
                                          <option
                                            key={course.id}
                                            value={course.id}
                                          >
                                            {course.course_name}
                                          </option>
                                        ))}
                                      </select>
                                      {enrollmentError.courseId && (
                                        <span
                                          htmlFor="courseId"
                                          className="error"
                                        >
                                          * {enrollmentError.courseId}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group courses">
                                      <label>Batch</label>
                                      <select
                                        disabled={enrollmentDisable}
                                        className="form-control"
                                        name="batchId"
                                        id="batchId"
                                        value={enrollment.batchId}
                                        onChange={enrollmentOnChange}
                                      >
                                        <option>Select Batch</option>
                                        {batches.map((batch) => (
                                          <option
                                            key={batch.id}
                                            value={batch.id}
                                          >
                                            {batch.batch_name}
                                          </option>
                                        ))}
                                      </select>
                                      {/* {Change validation logic} */}
                                      {enrollmentError.batchId && (
                                        <span
                                          htmlFor="batchId"
                                          className="error"
                                        >
                                          * {enrollmentError.batchId}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Enrollment Date</label>
                                      <input
                                        disabled={enrollmentDisable}
                                        type="date"
                                        className="form_style"
                                        placeholder="Enrollment Date"
                                        name="enrollmentDate"
                                        id="enrollmentDate"
                                        value={enrollment.enrollmentDate}
                                        onChange={enrollmentOnChange}
                                      />
                                      {enrollmentError.enrollmentDate && (
                                        <span
                                          htmlFor="enrollmentDate"
                                          className="error"
                                        >
                                          * {enrollmentError.enrollmentDate}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group courses">
                                      <label>Mode of transaction</label>
                                      <select
                                        className="form-control"
                                        name="modeOfTransaction"
                                        id="modeOfTransaction"
                                        value={payment.modeOfTransaction}
                                        onChange={paymentOnChange}
                                      >
                                        <option>Select mode</option>
                                        {modeOfTransaction.map(
                                          (modeOfTransaction) => (
                                            <option
                                              key={modeOfTransaction.id}
                                              value={modeOfTransaction.mode}
                                            >
                                              {modeOfTransaction.mode}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {enrollmentError.courseId && (
                                        <span
                                          htmlFor="courseId"
                                          className="error"
                                        >
                                          * {enrollmentError.courseId}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {payment.modeOfTransaction === "QR Code" && (
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>Transaction ID</label>
                                        <input
                                          type="text"
                                          className="form_style"
                                          placeholder="Transaction ID"
                                          name="transactionID"
                                          value={payment.referenceNumber}
                                          maxLength={12}
                                          onChange={paymentOnChange}
                                          id="transactionID"
                                        />
                                        {enrollmentError.registrationFees && (
                                          <span
                                            htmlFor="registrationFees"
                                            className="error"
                                          >
                                            * {enrollmentError.registrationFees}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {(payment.modeOfTransaction === "QR Code" ||
                                    payment.modeOfTransaction === "Cash") && (
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>Registration Fees</label>
                                        <input
                                          type="text"
                                          className="form_style"
                                          placeholder="Registration Fees"
                                          name="registrationFees"
                                          value={payment.paymentAmount}
                                          onChange={paymentOnChange}
                                          id="registrationFees"
                                        />
                                        {enrollmentError.registrationFees && (
                                          <span
                                            htmlFor="registrationFees"
                                            className="error"
                                          >
                                            * {enrollmentError.registrationFees}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="register_button">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  <p className="text-size-14 mb-0">
                                    <span>*</span>All fields are mandatory.
                                  </p>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-12"
                                  style={{
                                    display: "flex",
                                    flexFlow: "row-reverse",
                                    gap: "20px",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    id="submit"
                                    className="register_now text-white text-decoration-none"
                                    onClick={(e) => {
                                      submitEnrollment(e);
                                    }}
                                  >
                                    Save
                                  </button>
                                  {id && (
                                    <button
                                      type="submit"
                                      id="submit"
                                      className="register_now text-white text-decoration-none"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        nextPage("pagefour");
                                      }}
                                    >
                                      Skip
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          ),
                          pagefour: (
                            <>
                              <div className="col-12 d-flex justify-content-between m-4 ">
                                <p className="">Education</p>
                                <button
                                  className="btn btn-warning"
                                  style={{
                                    fontWeight: "bolder",
                                    color: "#fff",
                                    background: "#ff822d",
                                  }}
                                  onClick={addEducationRow}
                                >
                                  Add more Qualification
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col">Qualification</th>
                                      <th scope="col">Board/University</th>
                                      <th scope="col">Passing Year</th>
                                      <th scope="col">Percentage</th>
                                      <th scope="col">Document</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {educations.map((education, index) => (
                                      <tr key={index}>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="qualification"
                                            id="qualification"
                                            value={education.type}
                                            onChange={(e) =>
                                              handleTableDataChange(e, index)
                                            }
                                          >
                                            <option>
                                              Select Qualification
                                            </option>
                                            {types.map((type) => (
                                              <option
                                                key={type.id}
                                                value={type.type}
                                              >
                                                {type.title}
                                              </option>
                                            ))}
                                          </select>
                                          {validationErrors[index]
                                            ?.qualification && (
                                            <div className="text-danger">
                                              {
                                                validationErrors[index]
                                                  .qualification
                                              }
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            className="form_style"
                                            placeholder="Board/University"
                                            name="boardUniversity"
                                            value={education.boardUniversity}
                                            onChange={(e) =>
                                              handleTableDataChange(e, index)
                                            }
                                          />
                                          {validationErrors[index]
                                            ?.boardUniversity && (
                                            <div className="text-danger">
                                              {
                                                validationErrors[index]
                                                  .boardUniversity
                                              }
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            className="form_style"
                                            placeholder="Year"
                                            name="year"
                                            value={education.year}
                                            onChange={(e) =>
                                              handleTableDataChange(e, index)
                                            }
                                          />
                                          {validationErrors[index]?.year && (
                                            <div className="text-danger">
                                              {validationErrors[index].year}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            className="form_style"
                                            placeholder="Percentage"
                                            name="percentage"
                                            value={education.percentage}
                                            onChange={(e) =>
                                              handleTableDataChange(e, index)
                                            }
                                          />
                                          {validationErrors[index]
                                            ?.percentage && (
                                            <div className="text-danger">
                                              {
                                                validationErrors[index]
                                                  .percentage
                                              }
                                            </div>
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <input
                                            ref={
                                              documentFileInputRefs.current[
                                                index
                                              ]
                                            }
                                            type="file"
                                            className="form_style"
                                            name="document"
                                            id="photo"
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                              handleDocument(e, index)
                                            }
                                          />
                                          <FontAwesomeIcon
                                            icon={faUpload}
                                            onClick={() => {
                                              triggerQualificationFileInput(
                                                index
                                              );
                                            }}
                                          />
                                          {id && (
                                            <FontAwesomeIcon
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                              }}
                                              icon={faEye}
                                              onClick={() => {
                                                // education?.document?.type?.startsWith(
                                                //   "image/"
                                                // )
                                                //   ? showSelectedFile(
                                                //       education.document
                                                //     )
                                                //   : setImageURL(
                                                //       education.document
                                                //     );
                                                setImageURL(education.document);
                                                setImageModal(true);
                                              }}
                                            />
                                          )}
                                          {validationErrors[index]
                                            ?.document && (
                                            <div className="text-danger">
                                              {validationErrors[index].document}
                                            </div>
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                              deleteEducationRow(e, index)
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="register_button">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  <p className="text-size-14 mb-0">
                                    <span>*</span>All fields are mandatory.
                                  </p>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-12"
                                  style={{
                                    display: "flex",
                                    flexFlow: "row-reverse",
                                    gap: "20px",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    id="submit"
                                    className="register_now text-white text-decoration-none"
                                    onClick={(e) => {
                                      qualificationUpdate
                                        ? submitQualification(e)
                                        : updateQualification(e);
                                    }}
                                  >
                                    Save
                                  </button>
                                  {id && (
                                    <button
                                      style={{ padding: "11px 58px" }}
                                      type="submit"
                                      id="submit"
                                      className="register_now text-white text-decoration-none"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/admin/studentsDetails");
                                      }}
                                    >
                                      Skip & Exit
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          ),
                        }[page]
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddStudent;
