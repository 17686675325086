import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/errorPage.css";

export default function ErrorPage() {
  return (
    <div className="error_page position-relative">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="error_content position-relative text-center">
              <h1>404</h1>
              <p className="text">Sorry! The Page Not Found :(</p>
              {/* <p className="col-xl-8 col-lg-10 mx-auto text2">
                Lorem ipsum dolor sit amet, consec tse tur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore
              </p> */}
              <Link to="/" className="back_home text-decoration-none">
                Back To Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
