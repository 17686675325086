/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bannerTopdotedimage from "../../assets/images/banner-topdotedimage.png";
import logo from "../../assets/images/CSDC_Logo-removebg.png";
import { Link } from "react-router-dom";
import { faLifeRing, faUserPen } from "@fortawesome/free-solid-svg-icons";

export default function NavBar({
  getClassName,
  pageState,
  setPageState,
  activeNav,
}) {
  return (
    <div className="banner_outer position-relative">
      <figure className="banner-topdotedimage mb-0">
        <img src={bannerTopdotedimage} alt="" className="img-fluid"></img>
      </figure>
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <Link
              className="navbar-brand"
              to="/"
              onClick={() => {
                activeNav("home");
                setPageState({
                  pageActive: false,
                  coursePageActive: false,
                });
              }}
              aria-label="logo"
            >
              <figure className="logo mb-0">
                <img src={logo} alt="" className="img-fluid img_size"></img>
              </figure>
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li
                  className={getClassName("home")}
                  onClick={() => {
                    activeNav("home");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li
                  className={
                    pageState.coursePageActive
                      ? "nav-item dropdown active show"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle dropdown-color navbar-text-color"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Courses
                  </a>
                  <div className="dropdown-menu drop-down-content">
                    <ul className="list-unstyled drop-down-pages">
                      <li
                        className={getClassName("courses")}
                        onClick={() => {
                          activeNav("courses");
                          setPageState({
                            pageActive: false,
                            coursePageActive: true,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link" to="/course">
                          Courses
                        </Link>
                      </li>
                      {/* <li
                        className={getClassName("singleCourses")}
                        onClick={() => {
                          activeNav("singleCourses");
                          setPageState({
                            pageActive: false,
                            coursePageActive: true,
                          });
                        }}
                      >
                        <Link
                          className="dropdown-item nav-link"
                          to="/singleCourse"
                        >
                          Single Course
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li
                  className={
                    pageState.pageActive
                      ? "nav-item dropdown active show"
                      : "nav-item dropdown"
                  }
                >
                  <a
                    className="nav-link dropdown-toggle dropdown-color navbar-text-color"
                    href="#"
                    id="navbarDropdown2"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Instructor & Event
                  </a>
                  <div className="dropdown-menu drop-down-content">
                    <ul className="list-unstyled drop-down-pages">
                      <li
                        className={getClassName("instructors")}
                        onClick={() => {
                          activeNav("instructors");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link
                          className="dropdown-item nav-link"
                          to="/instructors"
                        >
                          Instructors
                        </Link>
                      </li>
                      <li
                        className={getClassName("instructorSpotlight")}
                        onClick={() => {
                          activeNav("instructorSpotlight");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link
                          className="dropdown-item nav-link"
                          to="/instructorSpotlight"
                        >
                          Instructor Spotlight
                        </Link>
                      </li>
                      <li
                        className={getClassName("event")}
                        onClick={() => {
                          activeNav("event");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link" to="/events">
                          Events
                        </Link>
                      </li>
                      {/* <li
                        className={getClassName("eventDetail")}
                        onClick={() => {
                          activeNav("eventDetail");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link
                          className="dropdown-item nav-link"
                          to="/eventDetail"
                        >
                          Events Detail
                        </Link>
                      </li> */}
                      {/* <li
                        className={getClassName("testimonial")}
                        onClick={() => {
                          activeNav("testimonial");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link
                          className="dropdown-item nav-link"
                          to="/testimonial"
                        >
                          Testimonials
                        </Link>
                      </li> */}
                      {/* <li
                        className={getClassName("pricing")}
                        onClick={() => {
                          activeNav("pricing");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link" to="/pricing">
                          Pricing
                        </Link>
                      </li> */}
                      {/* <li
                        className={getClassName("faq")}
                        onClick={() => {
                          activeNav("faq");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link" to="/faq">
                          FAQ
                        </Link>
                      </li> */}
                      {/* <li
                        className={getClassName("financialAid")}
                        onClick={() => {
                          activeNav("financialAid");
                          setPageState({
                            pageActive: true,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link
                          className="dropdown-item nav-link"
                          to="/financialAid"
                        >
                          Financial Aid
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle dropdown-color navbar-text-color"
                    href="#"
                    id="navbarDropdown3"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Blog
                  </a>
                  <div className="dropdown-menu drop-down-content">
                    <ul className="list-unstyled drop-down-pages">
                      <li
                        className="nav-item"
                        onClick={() => {
                          activeNav("home");
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">Blog</Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          Single Blog
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          Load More
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          One Column
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          Two Column
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          Three Column
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          Three Column Sidebar
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          Four Column
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setPageState({
                            pageActive: false,
                            coursePageActive: false,
                          });
                        }}
                      >
                        <Link className="dropdown-item nav-link">
                          Six Column
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li
                  className={getClassName("jobList")}
                  onClick={() => {
                    activeNav("jobList");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link to="/jobList" className="nav-link ">
                    Jobs
                  </Link>
                </li>
                <li
                  className={getClassName("about")}
                  onClick={() => {
                    activeNav("about");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link to="/about" className="nav-link ">
                    About Us
                  </Link>
                </li>
                <li
                  className="nav-item icon-box"
                  onClick={() => {
                    activeNav("support");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link
                    className="nav-link icon"
                    to="/contact"
                    aria-label="Contact Us"
                  >
                    <i>
                      <FontAwesomeIcon icon={faUserPen} />
                    </i>
                  </Link>
                </li>
                <li
                  className={getClassName("support")}
                  onClick={() => {
                    activeNav("support");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link className="nav-link contact_us" to="/contact">
                    <i>
                      <FontAwesomeIcon icon={faLifeRing} />
                    </i>
                    Support
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
