import React from "react";
import "../../assets/css/article.css";
import ArticleCard from "../Cards/ArticleCard";
import { articalCardData } from "../../content/content";

export default function Article({ aboutpageArticle = "" }) {
  return (
    <section className={`article-con ${aboutpageArticle}`}>
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-12">
            <div className="article_content text-center">
              <h6>News and Offers</h6>
              <h2 className="mb-0">Latest News and Articles</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {articalCardData.map((article, index) => (
            <ArticleCard
              key={index}
              image={article.image}
              title={article.title}
              date={article.date}
              comments={article.comments}
              heading={article.heading}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
