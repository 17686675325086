import React from "react";

function AboutCard({ icon, heading, subHeading }) {
  return (
    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
      <div className="about-box">
        <figure className="icon mb-0 hover-effect">
          <img src={icon} alt="" className="img-fluid"></img>
        </figure>
        <div className="content">
          <h4 className="mb-0">{heading}</h4>
          <span className="text-size-14">{subHeading}</span>
        </div>
      </div>
    </div>
  );
}

export default AboutCard;
