import React from "react";
import "../../assets/css/sponsor.css";
import SponsorCard from "../Cards/SponsorCard";
import { sponsorCardData } from "../../content/content";

export default function Sponsor({ pageSponsor }) {
  return (
    <section className={`sponsor-con ${pageSponsor}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="sponsor_content text-center" data-aos="fade-up">
              <h3>Earn Certifications and ShowcaseYour Achievements</h3>
              <p className="mb-0 text-size-18">
                Celebrate Your Progress and Add Certifications to Your Portfolio
              </p>
            </div>
          </div>
          <ul className="list-unstyled mb-0">
            {sponsorCardData.slice(0, 4).map((sponsor, index) => (
              <SponsorCard key={index} image={sponsor.image} />
            ))}
          </ul>
          <ul className="list-unstyled second mb-0">
            {sponsorCardData.slice(4).map((sponsor, index) => (
              <SponsorCard key={index} image={sponsor.image} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
