import assistantBeautyTherapist from "../assets/images/Assistant Beauty Therapist.jpg";
import swingMachineOperator from "../assets/images/Swing Machine Operator.jpg";
import generalDutyAssistant from "../assets/images/General Duty Assistant.jpg";
import aboutIcon1 from "../assets/images/about-icon1.png";
import aboutIcon2 from "../assets/images/about-icon2.png";
import aboutIcon3 from "../assets/images/about-icon3.png";
import bannerImg from "../assets/images/teacher.png";
import expertImg3 from "../assets/images/expert.webp";
import expertImg4 from "../assets/images/expert.webp";
import expertImg5 from "../assets/images/expert.webp";
import expertImg6 from "../assets/images/expert.webp";
// import learningImage4 from "../assets/images/learning-image4.jpg";
import featureIcon1 from "../assets/images/feature-icon1.png";
import featureIcon2 from "../assets/images/feature-icon2.png";
import featureIcon3 from "../assets/images/feature-icon3.png";
// import teacherImg1 from "../assets/images/expert.webp";
// import teacherImg2 from "../assets/images/instructorexpert-image2.jpg";
// import teacherImg3 from "../assets/images/instructorexpert-image3.jpg";
// import teacherImg4 from "../assets/images/instructorexpert-image4.jpg";
// import teacherImg5 from "../assets/images/instructorexpert-image5.jpg";
// import teacherImg6 from "../assets/images/instructorexpert-image6.jpg";
// import teacherImg7 from "../assets/images/instructorexpert-image7.jpg";
// import teacherImg8 from "../assets/images/instructorexpert-image8.jpg";
// import teacherImg9 from "../assets/images/instructorexpert-image9.jpg";
import articalImg3 from "../assets/images/skill training-article.jpg";
import articalImg2 from "../assets/images/time-management-article.jpg";
import articalImg1 from "../assets/images/Swing Machine Operator-article.jpg";
import sponsor1 from "../assets/images/sponsor1.png";
import sponsor2 from "../assets/images/sponsor2.png";
import sponsor3 from "../assets/images/sponsor3.png";
import sponsor4 from "../assets/images/sponsor4.png";
import sponsor5 from "../assets/images/sponsor5.png";
import sponsor6 from "../assets/images/sponsor6.png";
import sponsor7 from "../assets/images/sponsor7.png";
import sponsor8 from "../assets/images/sponsor8.png";
import eventImg from "../assets/images/Baada Event.jpeg";
import eventImg2 from "../assets/images/event-image2.jpg";
import eventImg3 from "../assets/images/event-image3.jpg";

export const coursesData = [
  {
    id: 1,
    heading: "General Duty Assistant",
    paragraph: [
      "A General Duty Assistant (GDA) plays a vital role in the healthcare sector, providing essential support to both patients and medical staff. Their responsibilities typically include assisting with patient care, maintaining hygiene standards, and ensuring the smooth functioning of healthcare facilities. ",

      "GDAs are often found in hospitals, clinics, nursing homes, and other medical settings where their versatile skills are highly valued. They may help with tasks such as monitoring vital signs, assisting with daily activities, and providing comfort and support to patients in need. With their compassionate nature and attention to detail, GDAs contribute significantly to the overall well-being and comfort of patients under their care.",
    ],
    keyPoints: [
      " Providing empathetic support to patients",
      "Assisting with various tasks, from basic procedures to hygiene",
      "Ensuring strict cleanliness for infection control",
      "Working closely with healthcare professionals for optimal care",
    ],
    image: generalDutyAssistant,
    facebook: "https://www.facebook.com/profile.php?id=61557014431779",
    twitter: "https://twitter.com/CirculateSkill",
    linkedIn:
      "https://www.linkedin.com/company/circulate-skill-development-corporation/",
    instagram: "https://www.instagram.com/circulateskill/",
  },
  {
    id: 2,
    heading: "Sewing Machine Operator",
    paragraph: [
      "A Sewing Machine Operator is a skilled professional in the textile industry responsible for operating Sewing machines, which are used in garment manufacturing to stitch fabrics together. Their expertise lies in efficiently operating and maintaining these machines to ensure the production of high-quality garments. ",
      "These operators are adept at interpreting design specifications and adjusting machine settings accordingly to achieve precise stitching patterns and seam finishes. With a keen eye for detail and proficiency in machine operation, Sewing Machine Operators play a crucial role in meeting production deadlines while maintaining the desired level of craftsmanship.",
    ],
    keyPoints: [
      " Proficiently operate Sewing machines to stitch fabric pieces together",
      "Ensure the quality of stitched seams by conducting regular inspections",
      "Perform routine maintenance tasks to keep optimal working condition",
      "Work efficiently to meet production targets and deadlines.",
    ],
    image: swingMachineOperator,
  },
  {
    id: 3,
    heading: "Assistant Beauty Therapist",
    paragraph: [
      "An Assistant Beauty Therapist plays a vital role in the beauty and wellness industry, providing essential support to licensed beauty therapists and estheticians in delivering a wide range of skincare and beauty treatments. Their responsibilities often include preparing treatment rooms, sterilizing equipment, and assisting clients with basic skincare routines.",
      "Additionally, they may perform tasks such as applying facials, manicures, pedicures, and hair removal treatments under the supervision of a qualified professional. With a passion for beauty and a commitment to client satisfaction, Assistant Beauty Therapists contribute to creating a relaxing and rejuvenating experience for clients seeking skincare and beauty services.",
    ],
    keyPoints: [
      "Assist with skincare routines and during treatments.",
      "Set up rooms and sterilize equipment",
      "Apply facials, manicures, pedicures, and hair removal treatments",
      "Provide excellent service and address client concerns",
    ],
    image: assistantBeautyTherapist,
  },
];

export const coursesCardData = [
  {
    image: generalDutyAssistant,
    id: 1,
    price: 1999,
    heading: "General Duty Assistant",
    content:
      "A General Duty Assistant (GDA) plays a vital role in the healthcare sector, providing essential support to both patients and medical staff. ",
    link: "/singleCourse/1",
  },
  {
    image: swingMachineOperator,
    id: 2,
    price: 2999,
    heading: "Sewing Machine Operator",
    content:
      "A Sewing Machine Operator is a skilled professional in the textile industry responsible for operating swing machines, which are used in garment manufacturing to stitch fabrics together. ",
    link: "/singleCourse/2",
  },
  {
    image: assistantBeautyTherapist,
    id: 3,
    price: 3999,
    heading: "Assistant Beauty Therapist",
    content:
      "An Assistant Beauty Therapist plays a vital role in the beauty and wellness industry, providing essential support to licensed beauty therapists and estheticians in delivering a wide range of skincare and beauty treatments.",
    link: "/singleCourse/3",
  },
];

export const aboutCardData = [
  {
    icon: aboutIcon1,
    heading: "Video Traning",
    subHeading: "With Unlimited Courses",
  },
  {
    icon: aboutIcon2,
    heading: "Expert Teacher",
    subHeading: "With Unlimited Courses",
  },
  {
    icon: aboutIcon3,
    heading: "Recorded Sessions",
    subHeading: "With Unlimited Courses",
  },
];

export const bannerCarouselData = [
  {
    id: 1,
    bannerImg: bannerImg,
    instructorName: "Rashmi Nigam",
    instructorRole: "Program Head",
    heading: "Welcome to Circulate Skill Development.",
    subheading: "Your Gateway to Excellence in Circulate Skill Development.",
    buttonText: "Explore Courses",
    summary:
      "Embark on a journey of knowledge and skill enhancement with Circulate Skill Development.",
  },
  // {
  //   id: 2,
  //   bannerImg: bannerImg,
  //   instructorName: "Jane Smith",
  //   instructorRole: "Online instructor",
  //   heading: "Welcome to SkillBoost.",
  //   subheading: "Unlock Your Potential with Our Online Courses",
  //   buttonText: "Get Started",
  //   summary:'Embark on a journey of knowledge and skill enhancement with Circulate Skill Development.'
  // },
  // {
  //   id: 3,
  //   bannerImg: bannerImg,
  //   instructorName: "Max",
  //   instructorRole: "offline instructor",
  //   heading: "Welcome to SkillBoost.",
  //   subheading: "Unlock Your Potential with Our Online Courses",
  //   buttonText: "Get Started",
  //   summary:'Embark on a journey of knowledge and skill enhancement with Circulate Skill Development.'
  // },
];

export const expertData = [
  {
    image: expertImg3,
    facebook: "https://www.facebook.com/login/",
    linkedin: "https://www.linkedin.com/login",
    youtube: "https://www.youtube.com/",
    instagram:
      "https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Faccounts%2Fonetap%2F%3Fnext%3D%252F%26__coig_login%3D1",
    name: "Declan Heyes",
    position: "CDME",
  },
  {
    image: expertImg4,
    facebook: "https://www.facebook.com/login/",
    linkedin: "https://www.linkedin.com/login",
    youtube: "https://www.youtube.com/",
    instagram:
      "https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Faccounts%2Fonetap%2F%3Fnext%3D%252F%26__coig_login%3D1",
    name: "Declan Heyes",
    position: "CDME",
  },
  {
    image: expertImg5,
    facebook: "https://www.facebook.com/login/",
    linkedin: "https://www.linkedin.com/login",
    youtube: "https://www.youtube.com/",
    instagram:
      "https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Faccounts%2Fonetap%2F%3Fnext%3D%252F%26__coig_login%3D1",
    name: "Declan Heyes",
    position: "CDME",
  },
  {
    image: expertImg6,
    facebook: "https://www.facebook.com/login/",
    linkedin: "https://www.linkedin.com/login",
    youtube: "https://www.youtube.com/",
    instagram:
      "https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Faccounts%2Fonetap%2F%3Fnext%3D%252F%26__coig_login%3D1",
    name: "Declan Heyes",
    position: "CDME",
  },
];

export const learningPartnerData = [
  {
    image: generalDutyAssistant,
    heading: "General Duty Assistant",
    summary:
      "A General Duty Assistant (GDA) plays a vital role in the healthcare sector, providing essential support to both patients and medical staff. ",
    link: "/#register",
  },
  {
    image: swingMachineOperator,
    heading: "Sewing Machine Operator",
    summary:
      "A Sewing Machine Operator is a skilled professional in the textile industry responsible for operating swing machines, which are used in garment manufacturing to stitch fabrics together. ",
    link: "/#register",
  },
  {
    image: assistantBeautyTherapist,
    heading: "Assistant Beauty Therapist",
    summary:
      "An Assistant Beauty Therapist plays a vital role in the beauty and wellness industry, providing essential support to licensed beauty therapists and estheticians in delivering a wide range of skincare and beauty treatments.",
    link: "/#register",
  },
];

export const featureCardData = [
  {
    icon: featureIcon1,
    heading: "Personalized Learning Paths",
    content:
      "Tailor your learning journey based on your goals and preferences.",
  },
  {
    icon: featureIcon2,
    heading: "Interactive Assignments",
    content: "Engage with hands-on projects to apply theoretical knowledge.",
  },
  {
    icon: featureIcon3,
    heading: "Community Support",
    content:
      "Connect with fellow learners, share ideas,and collaborate for success.",
  },
];

export const teacherData = [
  {
    image: bannerImg,
    name: "Rashmi Nigam",
    position: "Program Head",
    facebook: "",
    linkedin: "",
    youtube: "",
    instagram: "",
  },
  // {
  //   image: teacherImg1,
  //   name: "Ryan Stokes",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
  // {
  //   image: teacherImg1,
  //   name: "Abigail Noble",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
  // {
  //   image: teacherImg1,
  //   name: "Jack Sullivan",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
  // {
  //   image: teacherImg1,
  //   name: "Zara Saunders",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
  // {
  //   image: teacherImg1,
  //   name: "Christopher Kelly",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
  // {
  //   image: teacherImg1,
  //   name: "Alexandra Vaughan",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
  // {
  //   image: teacherImg1,
  //   name: "Jordan Cooper",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
  // {
  //   image: teacherImg1,
  //   name: "Olivia Shaw",
  //   position: "CEO",
  //   facebook: "",
  //   linkedin: "",
  //   youtube: "",
  //   instagram: "",
  // },
];

export const articalCardData = [
  {
    image: articalImg1,
    title: "Webinars & Events",
    date: "Apr 22, 2020",
    comments: "2",
    heading: "Transforming Your Future with Circulate Skill Development",
  },
  {
    image: articalImg2,
    title: "Personal Growth",
    date: "Apr 22, 2020",
    comments: "5",
    heading: "Conquer Your Time Management in Online Class",
  },
  {
    image: articalImg3,
    title: "Community & Networking",
    date: "Apr 22, 2020",
    comments: "7",
    heading: "Unconventional Tips for Boosting Your Learning",
  },
];

export const sponsorCardData = [
  {
    image: sponsor1,
  },
  {
    image: sponsor2,
  },
  {
    image: sponsor3,
  },
  {
    image: sponsor4,
  },
  {
    image: sponsor5,
  },
  {
    image: sponsor6,
  },
  {
    image: sponsor7,
  },
  {
    image: sponsor8,
  },
];

export const instructorCoursesData = [
  {
    id: "1",
    courseImage: generalDutyAssistant,
    courseName: "General Duty Assistant",
    purchasedNum: "124",
    price: "",
    numStars: "5",
  },
  // {
  //   id: "2",
  //   courseImage: swingMachineOperator,
  //   courseName: "Sewing Machine Operator",
  //   purchasedNum: "1024",
  //   price: "",
  //   numStars: "5",
  // },
  // {
  //   id: "3",
  //   courseImage: assistantBeautyTherapist,
  //   courseName: "Assistant Beauty Therapist",
  //   purchasedNum: "1024",
  //   price: "",
  //   numStars: "5",
  // },
];

export const eventData = [
  {
    id: "1",
    eventImg: eventImg,
    heading: "Meet Up With Students",
    date: "08-04-2024",
    startTime: "11:30 AM",
    endTime: "01:30 PM",
    eventPlace: "Baada , Uttar Pradesh",
  },
  // {
  //   id: "2",
  //   eventImg: eventImg2,
  //   heading: "Practical Workshop for GDA",
  //   date: "23-04-2024",
  //   startTime: "12 PM",
  //   endTime: "03 PM",
  //   eventPlace: "Lucknow, Uttar Pradesh",
  // },
  // {
  //   id: "3",
  //   eventImg: eventImg3,
  //   heading: "Provide Sewing Skills Training",
  //   date: "24-04-2024",
  //   startTime: "03 PM",
  //   endTime: "06 PM",
  //   eventPlace: "Lucknow, Uttar Pradesh",
  // },
];

export const eventDetails = [
  { id: "1", image: eventImg },
  { id: "2", image: eventImg2 },
  { id: "3", image: eventImg3 },
];

export const traineeDetails = [
  {
    name: "Rohit Sen",
    date: "06-05-2024",
    location: "lko",
    receiptNo: "RC123",
    batchNo: "B001",
    courseNo: "C001",
    depositeFees: "2500",
    totalFees: "2500",
    amountWords: "Twenty five hundred",
    amountRupees: "2500",
    modeOfTranscation: "Online",
    chequeDate: "2024-04-30",
    chequeNo: "CH001",
    transactionNo: "TN001",
    receiverName: "Amit Singh",
    receiverSignature: "signature1.jpg",
    giverSignature: "signature2.jpg",
  },
  {
    name: "Mohit Rai",
    date: "09-05-2024",
    location: "gkp",
    receiptNo: "RC124",
    batchNo: "B002",
    courseNo: "C002",
    depositeFees: "0",
    totalFees: "2500",
    amountWords: "Twenty five hundred",
    amountRupees: "2500",
    modeOfTranscation: "cash",
    chequeDate: "2024-04-20",
    chequeNo: "CH002",
    transactionNo: "TN002",
    receiverName: "Ranjan",
    receiverSignature: "signature1.jpg",
    giverSignature: "signature2.jpg",
  },
  {
    name: "Jay Shah",
    date: "01-05-2024",
    location: "Gomti",
    receiptNo: "RC124",
    batchNo: "B003",
    courseNo: "C003",
    depositeFees: "1800",
    totalFees: "2500",
    amountWords: "Twenty five hundred",
    amountRupees: "2500",
    modeOfTranscation: "Online",
    chequeDate: "2024-04-10",
    chequeNo: "CH003",
    transactionNo: "TN003",
    receiverName: "Arun jain",
    receiverSignature: "signature1.jpg",
    giverSignature: "signature2.jpg",
  },
  {
    name: "Zain Khan",
    date: "29-05-2024",
    location: "Gorakhpur",
    receiptNo: "RC126",
    batchNo: "B007",
    courseNo: "C001",
    depositeFees: "1900",
    totalFees: "2500",
    amountWords: "Twenty five hundred",
    amountRupees: "2500",
    modeOfTranscation: "Cash",
    chequeDate: "2024-04-10",
    chequeNo: "CH009",
    transactionNo: "TN008",
    receiverName: "Anuj Rawat",
    receiverSignature: "signature1.jpg",
    giverSignature: "signature2.jpg",
  },
];
