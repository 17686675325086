import React, { useContext } from "react";
import "../../assets/css/faqAssistance.css";
import faqImg from "../../assets/images/faq-image.gif";
import { Link } from "react-router-dom";
import ActiveNavContext from "../../context/ActiveNavContext";

export default function FaqAssistance() {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  return (
    <section className="faqassistance-con position-relative">
      <div className="container">
        <div className="row align-items-center" data-aos="fade-up">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <figure className="faq-image mb-0">
              <img
                src={faqImg}
                alt=""
                className="img-fluid"
                style={{ width: "540px", height: "350px" }}
              ></img>
            </figure>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="faqassistance_content">
              <h3>Need More Assistance</h3>
              <p className="text-size-16">
                We hope this FAQ section has addressed your queries. If you need
                further assistance, please don't hesitate to reach out to us.
                Happy learning!
              </p>
              <Link
                to="/contact"
                className="contact_us text-decoration-none"
                onClick={() => {
                  activeNav("support");
                  setPageState({
                    pageActive: false,
                    coursePageActive: false,
                  });
                }}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
