import React from "react";
import "../../assets/css/potential.css";
import potentialImg from "../../assets/images/potential-image.jpg";
import potentialIcon from "../../assets/images/potential-icon.png";
import { Link } from "react-router-dom";

export default function Potential() {
  return (
    <section className="potential-con position-relative">
      <div className="container">
        <div className="potential_all_content" data-aos="fade-up">
          <div className="row">
            <div className="col-12">
              <div className="potential_content text-center">
                <h2>Unlock Your Learning Potential</h2>
                <p className="col-lg-11 mx-auto text-size-18 mb-0">
                  Welcome to <span>SkillBoost</span>, where quality education
                  meets affordability. Choose the pricing plan that best suits
                  your learning needs and embark on a journey of knowledge and
                  growth.
                </p>
              </div>
            </div>
          </div>
          <div
            id="potentialcarouselExample"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="potential_content_box">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="box_content">
                    <div className="content">
                      <h6>Basic Plan</h6>
                      <h2>Entry-Level Learning Experience</h2>
                      <p className="text-size-18">
                        Choose the pricing plan that best suits your learning
                        needs and embark on a journey of knowledge and growth
                      </p>
                      <Link
                        to="/contact"
                        className="default-btn text-decoration-none"
                      >
                        Enroll Now
                      </Link>
                    </div>
                    <div className="image text-md-left text-center">
                      <figure className="potential-image mb-0">
                        <img
                          src={potentialImg}
                          alt=""
                          className="img-fluid"
                        ></img>
                      </figure>
                      <figure className="potential-icon mb-0">
                        <img
                          src={potentialIcon}
                          alt=""
                          className="img-fluid"
                        ></img>
                      </figure>
                      <div className="box">
                        <span>
                          <sup className="mb-0">$</sup>49
                          <span className="month mb-0">/Mo</span>
                        </span>
                        <ul className="list-unstyled mb-0">
                          <li className="text-size-16">
                            <i className="fa-solid fa-check"></i>Individual
                            Course
                          </li>
                          <li className="text-size-16">
                            <i className="fa-solid fa-check"></i>Course Learning
                            Checks
                          </li>
                          <li className="text-size-16">
                            <i className="fa-solid fa-check"></i>Offline
                            Learning
                          </li>
                          <li className="text-size-16">
                            <i className="cross fa-solid fa-xmark"></i>Course
                            Discussions
                          </li>
                          <li className="text-size-16">
                            <i className="cross fa-solid fa-xmark"></i>One to
                            One Guidance
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item ">
                  <div className="box_content">
                    <div className="content">
                      <h6>Basic Plan</h6>
                      <h2>Entry-Level Learning Experience</h2>
                      <p className="text-size-18">
                        Choose the pricing plan that best suits your learning
                        needs and embark on a journey of knowledge and growth
                      </p>
                      <Link
                        to="/contact"
                        className="default-btn text-decoration-none"
                      >
                        Enroll Now
                      </Link>
                    </div>
                    <div className="image text-md-left text-center">
                      <figure className="potential-image mb-0">
                        <img
                          src={potentialImg}
                          alt=""
                          className="img-fluid"
                        ></img>
                      </figure>
                      <figure className="potential-icon mb-0">
                        <img
                          src={potentialIcon}
                          alt=""
                          className="img-fluid"
                        ></img>
                      </figure>
                      <div className="box">
                        <span>
                          <sup className="mb-0">$</sup>49
                          <span className="month mb-0">/Mo</span>
                        </span>
                        <ul className="list-unstyled mb-0">
                          <li className="text-size-16">
                            <i className="fa-solid fa-check"></i>Individual
                            Course
                          </li>
                          <li className="text-size-16">
                            <i className="fa-solid fa-check"></i>Course Learning
                            Checks
                          </li>
                          <li className="text-size-16">
                            <i className="fa-solid fa-check"></i>Offline
                            Learning
                          </li>
                          <li className="text-size-16">
                            <i className="cross fa-solid fa-xmark"></i>Course
                            Discussions
                          </li>
                          <li className="text-size-16">
                            <i className="cross fa-solid fa-xmark"></i>One to
                            One Guidance
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-outer">
              <a
                className="carousel-control-next"
                href="#potentialcarouselExample"
                role="button"
                data-slide="next"
              >
                <i className="fa-solid fa-arrow-right"></i>
                <span className="sr-only">Next</span>
              </a>
              <a
                className="carousel-control-prev"
                href="#potentialcarouselExample"
                role="button"
                data-slide="prev"
              >
                <i className="fa-solid fa-arrow-left"></i>
                <span className="sr-only">Previous</span>
              </a>
            </div>
          </div>
          <p className="text text-center mb-0">
            You have a large team?{" "}
            <Link href="/contact" className="text-decoration-none">
              Contact us
            </Link>
            for information about more enterprise options
          </p>
        </div>
      </div>
    </section>
  );
}
