import React from "react";
import SubBanner from "../components/subBanner/SubBanner";
import CoursesSection from "../components/home/CoursesSection";
import "../assets/css/courses.css";
// import { Link } from "react-router-dom";
import Skill from "../components/home/Skill";
import Learning from "../components/home/Learning";
import Article from "../components/home/Article";
// import Sponsor from "../components/home/Sponsor";
import useScrollToTop from "../customHook/useScrollToTop";

export default function Courses() {
  useScrollToTop();

  return (
    <>
      <SubBanner title={"Our Courses"} page={"Courses"} />
      <CoursesSection coursePage={"coursepage-course"}>
        <div className="course_button">
          {/* <Link
            to="/singleCourse"
            className="default-btn text-decoration-none view_all"
            onClick={(e) => e.preventDefault()}
          >
            View All Courses
          </Link> */}
        </div>
      </CoursesSection>
      <Skill aboutpageSkill={"aboutpage-skill"} />
      <Learning />
      <Article aboutpageArticle={"aboutpage-article"} />
      {/* <Sponsor /> */}
    </>
  );
}
