import React from "react";
import "../../assets/css/community.css";
import quoteimage from "../../assets/images/community-quoteimage.png";
import communityImage1 from "../../assets/images/community-image1.jpg";
import personImg from "../../assets/images/community-personimage1.jpg";

export default function Community({
  children,
  testimonials,
  heading,
  content,
  name,
  componentAppearance,
  nameOrder,
  contentOrder,
}) {
  return (
    <section className={`community-con ${componentAppearance} `}>
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-12">
            {children}
            <div className="community-box position-relative">
              <figure className="mb-0 community-quoteimage">
                <img className="img-fluid" src={quoteimage} alt=""></img>
              </figure>
              <div className="row">
                <div
                  className={`col-lg-6 col-md-6 col-sm-12 col-12 ${nameOrder}`}
                >
                  <div className="community_box_wrapper">
                    <figure className="mb-0 community-image">
                      <img
                        className="img-fluid"
                        src={communityImage1}
                        alt=""
                      ></img>
                    </figure>
                    <h3 className="mb-0"> {testimonials}:</h3>
                  </div>
                </div>
                <div
                  className={`col-lg-6 col-md-6 col-sm-12 col-12 ${contentOrder}`}
                >
                  <div className="community_box_content">
                    <figure className="mb-0 community-person">
                      <img className="img-fluid" src={personImg} alt=""></img>
                    </figure>
                    <h3>{heading}</h3>
                    <p className="text-size-18">"{content}"</p>
                    <h4>{name}</h4>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
