import React from "react";
import partner1 from "../../assets/images/apollohospitals.png";
import partner4 from "../../assets/images/DHMH.png";
import partner5 from "../../assets/images/TSM logo.png";
import partner2 from "../../assets/images/Medanta.jpeg";
import partner3 from "../../assets/images/chandan.png";
import "../../assets/css/partner.css";

export default function Partner() {
  return (
    <section className="partner-con">
      <div className="container">
        <div
          className="partner_box text-center position-relative"
          data-aos="fade-up"
        >
          <h3>
            We Collaborate With <span>100+</span> Leading Hospitals And Health
            Care Services
          </h3>
          <ul className="mb-0 list-unstyled">
            <li>
              <figure className="mb-0 partner1">
                <img
                  className="img-fluid"
                  src={partner1}
                  alt=""
                  style={{ width: "80px", height: "80px" }}
                ></img>
              </figure>
            </li>
            <li>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src={partner2}
                  alt=""
                  style={{ width: "80px", height: "80px" }}
                ></img>
              </figure>
            </li>
            <li>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src={partner3}
                  alt=""
                  style={{ width: "80px", height: "80px" }}
                ></img>
              </figure>
            </li>
            <li>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src={partner4}
                  alt=""
                  style={{ width: "80px", height: "80px" }}
                ></img>
              </figure>
            </li>
            <li>
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src={partner5}
                  alt=""
                  style={{ width: "80px", height: "80px" }}
                ></img>
              </figure>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
