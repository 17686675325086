import React from "react";
import SubBanner from "../components/subBanner/SubBanner";
import Register from "../components/home/Register";
// import Sponsor from "../components/home/Sponsor";
import FaqSection from "../components/faq/FaqSection";
import FaqAssistance from "../components/faq/FaqAssistance";

export default function Faq() {
  return (
    <>
      <SubBanner title={"FAQ"} page={"FAQ"} />
      <FaqSection />
      <FaqAssistance />
      <Register
        pageClassName={"contactpage-register"}
        title={"Ask Question"}
        subHeading={`Got Questions? We've Got Answers!`}
        // pageClassName={"faqregister-con"}
        option={false}
        buttonName={"Ask Question"}
        url={"ask_questions"}
      >
        <p className="col-lg-10 mx-auto text-size-18 mb-0">
          Explore our frequently asked questions to find answers to common
          queries about our gym. If you can't find the information you're
          looking for, feel free to reach out to our team for assistance.
        </p>
      </Register>
      {/* <Sponsor pageSponsor={"instructorpage-sponsor"} /> */}
    </>
  );
}
