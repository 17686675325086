import React, { useEffect, useRef, useState } from "react";
import "./ChatBot.css";
import notificationSound from "../../assets/video/notificationsound.mp3";
import chatbot from "../../assets/images/CHATBOT.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPlus, faRobot, faWindowMinimize, faXmark } from "@fortawesome/free-solid-svg-icons";
import typingImage from "../../assets/images/typingImage.gif";
import { coursesData } from "../../content/content";
import axios from 'axios';
import { SERVER_URL } from "../../utils/database_Config";

const staticData = [
  {
    title: "Know About Our Courses",
  },
  {
    title: "Register For Our Courses",
  }
];

const qualification = [
  {
    title :"High School"
  },
  {
    title :"Intermediate"
  },
  {
    title :"Graduate"
  },

]

const ChatBot = ({visible}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  // const [course, setCourse] = useState("");
  const [msg, setMsg] = useState("");
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [isDobEntered, setIsDobEntered] = useState(false);
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const chatBotRef = useRef(null);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [phoneValidation  , setPhoneValidation]  = useState(false);
  const [errorMessage , setErrorMessage] = useState(false);
  const [phoneErrorMessage , setphoneErrorMessage] = useState(false);
  const [indicatorVisible , setIndicatorVisible]  = useState(false);
  const [isQualification , setIsQualification] = useState("");
  const [courses , setCourses]  =   useState("");
  const [minimize  ,setMinimize]  = useState(false);
  // const [qualification  , setQualification] = useState(false);


  useEffect(() => {
    const interval = setInterval(() => {
      setIndicatorVisible(true);
      setTimeout(() => setIndicatorVisible(false), 2000); // Hide after 2 seconds
    }, 3000); // 5 seconds in milliseconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const chatBotVisibilityHandler = () => {
    setIsVisible(!isVisible);
    setMinimize(false);
  };

  const scrollToBottomAnimated = () => {
    if (chatBotRef.current) {
      chatBotRef.current.scrollTo({
        top: chatBotRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    let audio;
    if (isVisible) {
      setIsLoading(true);
      audio = new Audio(notificationSound);
      audio.play();

      // Simulate a loading time
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); // 2 seconds loading time
    }
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [isVisible]);

  const createMessageElement = (className, textContent) => {
    const newElement = document.createElement("div");
    newElement.classList.add(className);
    newElement.textContent = textContent;
    return newElement;
  };

  const servicehandler = (title) => {
    if (title === "Know About Our Courses") {
      const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("user-message", title);
      selectElement.appendChild(newElement);
      const loadingElement = createMessageElement("loading-message", "Typing...");
      selectElement.appendChild(loadingElement);
      scrollToBottomAnimated();
      setTimeout(() => {
        loadingElement.remove();
        fetchdata();
        scrollToBottomAnimated();
        setNewMessage(true);
        setMessage(title);
      }, 2000);
    }
    if (title === "Register For Our Courses") {
      const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("user-message", title);
      selectElement.appendChild(newElement);
      const loadingMessage = createMessageElement("loading-message", "Typing...");
      selectElement.appendChild(loadingMessage);
      setTimeout(()=>{
        loadingMessage.remove();
        const nameInputElement = createInputElement("name");
        selectElement.appendChild(nameInputElement);
        scrollToBottomAnimated();
      } , 2000)
     
      scrollToBottomAnimated();
    }
  };

  const fetchdata = () => {
    const selectElement = document.querySelector(".inner-content-message");
    coursesData.forEach((item) => {
      const newElement = createMessageElement("round-pill", item.heading);
      newElement.addEventListener("click", async () => {
        const selectElement = document.querySelector(".inner-content-message");
        const newElement = createMessageElement("user-message", item.heading);
        selectElement.appendChild(newElement);
        scrollToBottomAnimated();

        const loadingElement = createMessageElement("loading-message", "Typing...");
        selectElement.appendChild(loadingElement);
        scrollToBottomAnimated();

        setTimeout(() => {
          loadingElement.remove();
          const selectElement = document.querySelector(".inner-content-message");
          const newElement = createMessageElement("bot-message", item.paragraph);
          selectElement.appendChild(newElement);
          const loadingElement2 = createMessageElement("loading-message", "Typing...");
          selectElement.appendChild(loadingElement2);
          scrollToBottomAnimated();
            setTimeout(()=>{
              loadingElement2.remove();
              fetchdata();
              scrollToBottomAnimated();
            } , 2000)
          scrollToBottomAnimated();
        }, 2000);
        // setTimeout(() => {
        //   loadingElement.remove();
        
        //   const selectElement = document.querySelector(".inner-content-message");
        //   const paragraphText = item.paragraph;
        //   console.log("whote rext " , paragraphText);
        
        //   if (typeof paragraphText === 'string') {
        //     const wordLimit = 100;
        //     const words = paragraphText.split(" ");
        
        //     // Check if the paragraph exceeds the word limit
        //     if (words.length > wordLimit) {
        //       const visibleText = words.slice(0, wordLimit).join(" ");
        //       console.log("visible text" , visibleText)
        //       const hiddenText = words.slice(wordLimit).join(" ");
        
        //       // Create the message element with limited text and a "Read more" link
        //       const newElement = createMessageElement("bot-message", `${visibleText}... `);
        //       const readMoreLink = document.createElement("a");
        //       readMoreLink.href = "#";
        //       readMoreLink.textContent = "Read more";
        //       readMoreLink.className = "read-more-link";
        //       readMoreLink.addEventListener("click", (event) => {
        //         event.preventDefault();
        //         newElement.innerHTML = `${paragraphText}`; // Replace the content with full text
        //       });
        
        //       newElement.appendChild(readMoreLink);
        //       selectElement.appendChild(newElement);
        //     } else {
        //       const newElement = createMessageElement("bot-message", paragraphText);
        //       selectElement.appendChild(newElement);
        //     }
        //   } else {
        //     console.error("Error: paragraphText is not a string");
        //   }
        
        //   const loadingElement2 = createMessageElement("loading-message", "Typing...");
        //   selectElement.appendChild(loadingElement2);
        //   scrollToBottomAnimated();
        
        //   setTimeout(() => {
        //     loadingElement2.remove();
        //     fetchdata();
        //     scrollToBottomAnimated();
        //   }, 2000);
        
        //   scrollToBottomAnimated();
        // }, 2000);
        
      });
      scrollToBottomAnimated();
      selectElement.appendChild(newElement);
    });
    const newElement = createMessageElement("round-pill", "Go To Previous");
    newElement.addEventListener("click", () => {
      fetchPreviousContent();
    });
    selectElement.appendChild(newElement);
  };

  const handlePreviousbutton = () => {
    const selectElement = document.querySelector(".inner-content-message");
    const newElement = createMessageElement("user-message", "Go To Previous");
    selectElement.appendChild(newElement);
    const loadingElement = createMessageElement("loading-message", "Typing...");
    selectElement.appendChild(loadingElement);
    scrollToBottomAnimated();
    setTimeout(() => {
      loadingElement.remove();
      fetchdata();
      scrollToBottomAnimated();
    }, 2000);
  };

  const fetchPreviousContent = async () => {
    const selectElement = document.querySelector(".inner-content-message");
    const newElement = createMessageElement("user-message", "Go To Previous");
    selectElement.appendChild(newElement);
     setTimeout(()=>{
      staticData.forEach((item) => {
        const newElement = createMessageElement("round-pill", item.title);
        newElement.addEventListener("click", () => {
          servicehandler(item.title);
          scrollToBottomAnimated();
        });
        selectElement.appendChild(newElement);
      });
      scrollToBottomAnimated();
     } , 2000);
     scrollToBottomAnimated();
  };

  const createInputElement = (type) => {
    const inputElement = document.createElement("input");
    inputElement.setAttribute("type", type === "dob" ? "date" : "text");
    inputElement.classList.add("user-input", "chatbot-input-field");

    if (type === "name") {
      inputElement.setAttribute("placeholder", " John Doe");
      inputElement.classList.add("name-input-style");
      inputElement.addEventListener("change", handleNameChange);
      inputElement.addEventListener("keypress", handleNameKeyPress);
    } else if (type === "email") {
      inputElement.setAttribute("placeholder", "John@gmail.com");
      inputElement.addEventListener("change", handleEmailChange);
      // inputElement.addEventListener("blur", handleEmailBlur);
    } else if (type === "dob") {
      inputElement.setAttribute("placeholder", "Enter your date of birth");
      inputElement.addEventListener("input", handleDobChange);
      // inputElement.addEventListener("blur", handleDobBlur);
    } else if (type === "phone") {
      inputElement.setAttribute("placeholder", "Enter your phone number");
      inputElement.setAttribute("maxLength", "10");
      inputElement.setAttribute("minLength", "10");
      inputElement.addEventListener("change", handlePhoneChange);
      // inputElement.addEventListener("keypress", handlePhoneKeyPress);
    }
    return inputElement;
  };

  const handleNameKeyPress = (event) => {
    const charCode = event.charCode;
    if (!/^[A-Za-z\s]+$/.test(String.fromCharCode(charCode))) {
      event.preventDefault();
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setIsNameEntered(true);
    event.target.remove();
    
  };

  useEffect(() => {
    if (isNameEntered) {
      const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("user-message", name);
      selectElement.appendChild(newElement);
      const loadingmessage = createMessageElement("loading-message", name);
      selectElement.appendChild(loadingmessage);
      setTimeout(()=>{
      loadingmessage.remove();
      const emailInputElement = createInputElement("email");
      selectElement.appendChild(emailInputElement);
      })
      
      scrollToBottomAnimated();
    }
  }, [name, isNameEntered]);

  const ErrorMessage = () =>{
   const selectElement = document.querySelector(".inner-content-message");
    const newElement = createMessageElement("loading-message", "please enter correct email..");
    if(errorMessage){
      selectElement.appendChild(newElement);
    }
    else{
      newElement.remove();
    }
  }

  const handleEmailChange = (event) => {
    const value = event.target.value;
    // const selectElement = document.querySelector(".inner-content-message");
    // const newElement = createMessageElement("loading-message", "please enter correct email..");
    if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
      setErrorMessage(true);
      ErrorMessage();
    } else {
      setErrorMessage(false);
      ErrorMessage();
      setIsEmailEntered(true);
      setEmail(event.target.value);
      setIsEmailEntered(true);
      event.target.remove();
    }

  };

  // const handleEmailBlur = (event) => {
  //   const value = event.target.value;
  //   if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
  //     const selectElement = document.querySelector(".inner-content-message");
  //     const newElement = createMessageElement("bot-message", "Enter the correct Email..");
  //     selectElement.appendChild(newElement);
  //   } else {
  //     // setIsEmailEntered(true);
  //     // setEmailError("");
  //     event.target.remove();
  //   }
  // }; 

  useEffect(() => {
    if (isEmailEntered ) {
      const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("user-message", email);
      selectElement.appendChild(newElement);
      const loadingmessage = createMessageElement("loading-message", "Typing...");
      selectElement.appendChild(loadingmessage);
      setTimeout(()=>{
        loadingmessage.remove();
        const dobInputElement = createInputElement("dob");
      selectElement.appendChild(dobInputElement);
      scrollToBottomAnimated();
      } , 2000)
      scrollToBottomAnimated();
      
    }
  }, [email, isEmailEntered]);

  const handleDobChange = (event) => {
    const dobValue = event.target.value;
  
    // Regular expression to check if the date is in yyyy-mm-dd format
    const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
  
    if (datePattern.test(dobValue)) {
      const [, year, month, day] = dobValue.match(datePattern);
      const yearInt = parseInt(year, 10);
      const date = new Date(`${year}-${month}-${day}`);
      

      if (
        date &&
        date.getFullYear() == yearInt &&
        (date.getMonth() + 1) == parseInt(month, 10) &&
        date.getDate() == parseInt(day, 10) &&
        yearInt >= 1900 &&
        yearInt <= 3000
      ) {
        setDob(dobValue);
        setIsDobEntered(true);
        event.target.remove();
      } else {
        return;
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    if (isDobEntered) {
      const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("user-message", dob);
      selectElement.appendChild(newElement);
      scrollToBottomAnimated();
      const age = calculateAge(new Date(dob));
      if (age < 18) {
        const loadingElement = createMessageElement("loading-message", "Typing...");
        selectElement.appendChild(loadingElement);
        scrollToBottomAnimated();
        setTimeout(() => {
          loadingElement.remove();
          const newElement = createMessageElement("bot-message", "You are not eligible to register for this course.");
          selectElement.appendChild(newElement);
          const newElement2 = createMessageElement("round-pill", "Go To Previous");
          newElement2.addEventListener("click", handlePreviousbutton);
          selectElement.appendChild(newElement2);
          scrollToBottomAnimated();
        }, 2000);
      } else {
        const loadingElement = createMessageElement("loading-message", "Typing...");
        selectElement.appendChild(loadingElement);
        setTimeout(()=>{
          loadingElement.remove();
          const phoneInputElement = createInputElement("phone");
          selectElement.appendChild(phoneInputElement);
          scrollToBottomAnimated();
        } , 2000)
      }
    }
  }, [dob, isDobEntered]);

  const handlePhoneChange = (event) => {
    const val  = event.target.value;
    if(val.length < 10){
      return;
    }
    setPhone(event.target.value);
    setIsPhoneEntered(true);
    event.target.remove();
    // setMessageChange(true);
  };
  const ErrorPhoneMessage = () =>{
    const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("loading-message", "max limit is 10");
      if(phoneErrorMessage){
        selectElement.appendChild(newElement);
      }
      else{
      newElement.remove();
      }
  }
 
  const handlePhoneKeyPress = (event) => {
    const charCode = event.charCode;
    if (!/^\d$/.test(String.fromCharCode(charCode))) {
      event.preventDefault();
      console.log("prevented");
    }
    if (event.key === "Enter") {
      if (phone.length !== 10) {
        setphoneErrorMessage(true);
        ErrorPhoneMessage();
        setPhoneValidation(false);
      }
      else{
        setphoneErrorMessage(false);
        ErrorPhoneMessage();
        setPhoneValidation(true);
        setIsPhoneEntered(true);
        event.target.remove();
      }
      
    }
  };

  useEffect(() => {
    if (isPhoneEntered) {
      const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("user-message", phone);
      selectElement.appendChild(newElement);
      const loadingMessage = createMessageElement("loading-message", "Typing...");
      selectElement.appendChild(loadingMessage);
      setTimeout(()=>{
        loadingMessage.remove();
        const selectedField = createMessageElement("bot-message", "Select Courses");
        selectElement.appendChild(selectedField);
        coursesData.forEach((item) => {
          const newElement = createMessageElement("round-pill", item.heading);
          newElement.addEventListener("click", () => {
            handleCourses(item.heading);
            setCourses(item.heading);
          });
          selectElement.appendChild(newElement);
          scrollToBottomAnimated();
        });
      } , 2000)
      
      scrollToBottomAnimated();
    }
  }, [phone, isPhoneEntered]);

  const handleCourses = (title) => {
    setCourses(title);
    const selectElement = document.querySelector(".inner-content-message");
    const newElement = createMessageElement("user-message", title);
    selectElement.appendChild(newElement);
    scrollToBottomAnimated();
    const newElement1 = createMessageElement("loading-message", "Typing....");
    selectElement.appendChild(newElement1);
    // handleSubmit(title);
    setTimeout(()=>{
      newElement1.remove();
      const newElement = createMessageElement("bot-message", "Select Qualification" );
      selectElement.appendChild(newElement);
      qualification.forEach((item) => {
        const newElement = createMessageElement("round-pill", item.title);
        newElement.addEventListener("click", () => {
          qualificationHandler(item.title , title);
          scrollToBottomAnimated();
        });
        selectElement.appendChild(newElement);
      });
      scrollToBottomAnimated();
    } , 2000)
  
  };

  const qualificationHandler = (qualify , course) =>{
    setIsQualification(qualify);
    const selectElement = document.querySelector(".inner-content-message");
    const newElement = createMessageElement("user-message", qualify );
    selectElement.appendChild(newElement);
    const loadingElement = createMessageElement("loading-message", "Typing..." );
    selectElement.appendChild(loadingElement);
    setTimeout(()=>{
       loadingElement.remove();
       handleSubmit(qualify , course )
    } , 2000)

  }

  const calculateAge = (dob) => {
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };



  

  const handleSubmit = async (qualify , course) => {
    const userData = { name, email, dob, phone, option: course, qualification :qualify };
    console.log(userData);
    const selectElement = document.querySelector(".inner-content-message");
    const loadingElement = createMessageElement("loading-message", "Typing...");
    selectElement.appendChild(loadingElement);
    const { data } = await axios.post(`${SERVER_URL}registered_users`, userData);
    scrollToBottomAnimated();

    if (data.success === true) {
     setTimeout(()=>{
      loadingElement.remove();
      const selectElement = document.querySelector(".inner-content-message");
      const newElement = createMessageElement("bot-message", "Thank you for inquiry. We will contact you soon.");
      selectElement.appendChild(newElement);
      const newElement1 = createMessageElement("loading-message", "Typing...");
      selectElement.appendChild(newElement1);
      setTimeout(()=>{
      newElement1.remove();
      const newElement2 = createMessageElement("bot-message", "How can i help you ?");
      selectElement.appendChild(newElement2);
      scrollToBottomAnimated();
      staticData.forEach((item) => {
        const newElement = createMessageElement("round-pill", item.title);
        newElement.addEventListener("click", () => {
          servicehandler(item.title);
          scrollToBottomAnimated();
        });
        selectElement.appendChild(newElement);
        scrollToBottomAnimated();
      });
      } , 2000)

      scrollToBottomAnimated();
     }, 2000)
    }
  };

  // useEffect(()=>{
  //   if(qualification){
  //     const selectElement = document.querySelector(".inner-content-message");
  //     const  loadingMessage = createMessageElement("loading-message" ,"Typing...");
  //     selectElement.appendChild(loadingMessage);
  //     scrollToBottomAnimated();
  //     setTimeout(()=>{
  //           loadingMessage.remove(); 
  //           qualification.forEach((item)=>{
  //             const element = createMessageElement("bot-message" , item.title);
  //             selectElement.appendChild(element);
  //           })
               
  //     } , 2000)
  //   }
  // } ,[])

  const minimizeHandler = () =>{
    setMinimize( true);
    console.log(minimize);
  }

  //  const minimizeHandlerPLus = (false) =>{
  //    setMinimize(false);
  //    console.log(minimize)
  //  }


  return (
    <>
    
      <div className={` ${(minimize) ? "chatbot_section_scale" : "chatbot_section"}  ${isVisible ? "visible" : ""}`}>
        <div className="logo-Section">
          <div className="logo-chatbot">
            <div className="chatbox-logo">
              <FontAwesomeIcon icon={faRobot} />
              <FontAwesomeIcon icon={faCircle} className="chatbot-online-show" />
            </div>
          </div>
          <div className="chatbot-online">Online</div>
          <div className="chatbot-cross">
            <FontAwesomeIcon icon={ minimize ? faPlus : faWindowMinimize}  className="chatbot-minimize" onClick={()=> setMinimize(!minimize)}/>
            <FontAwesomeIcon icon={faXmark} onClick={chatBotVisibilityHandler} />
          </div>
        </div>

        <div className="chatbot-chat-section" ref={chatBotRef}>
          {isLoading ? (
            <div className="loading-message"><img src={typingImage} alt="typing image" style={{ width: "20%" }} /></div>
          ) : (
            <div className="chat-bot-content">
              <div className="logo-chatbot-section">
                <FontAwesomeIcon icon={faRobot} />
                <span className="chatbot-content-style">ChatBot</span>
              </div>
              <div className="bot-message">Hello! How can I help you?</div>
              <div className="inner-content-message">
                <div className="round-pill" onClick={() => servicehandler("Know About Our Courses")}>Know About Our Courses</div>
                <div className="round-pill" onClick={() => servicehandler("Register For Our Courses")}>Register For Our Courses</div>
              </div>
            </div>
          )}
        </div>
      </div>
     {
      !isVisible && (
         <>
         <div className={` chatbot_icon ${visible ? "shift_up" :"shift_down"}`} onClick={chatBotVisibilityHandler} >
          <img src={chatbot} className="bot-image" onClick={chatBotVisibilityHandler} />
         </div>
         {
          indicatorVisible && (
          <div className= {`chatbot_indication  ${visible ? "shift_up_indication" :"shift_down_indication"}`} >
            How can i help you ?
          </div>
          )
         }

        {/* <div className="chatbot_mini" style={ !minimize ? {display:"block"} :{display:"none"}}>
         <div className="logo-Section">
          <div className="logo-chatbot">
            <div className="chatbox-logo">
              <FontAwesomeIcon icon={faRobot} />
              <FontAwesomeIcon icon={faCircle} className="chatbot-online-show" />
            </div>
          </div>
          <div className="chatbot-online">Online</div>
          <div className="chatbot-cross">
          <FontAwesomeIcon icon={faPlus}   className="chatbot-minimize" onClick={()=>setMinimize(false)}/>
            <FontAwesomeIcon icon={faXmark} onClick={chatBotVisibilityHandler} />
          </div>
        </div>
         </div> */}

         </>

      )
}

    </>
  );
};

export default ChatBot;
