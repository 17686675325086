import React from "react";
import "../../assets/css/highlight.css";
import logo from "../../assets/images/experiencelogo-image.png";

export default function Highlight({ event }) {
  return (
    <section className="event_highlight-con position-relative">
      <figure className="eventlogo-image mb-0">
        <img src={logo} alt="" className="img-fluid"></img>
      </figure>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="event_highlight_content" data-aos="fade-up">
              <h3>
                Unlock the Future of Learning at{" "}
                <span>Circulate Skill Development</span>
              </h3>
              <p className="text-size-16 mb-0">
                Welcome to <span>Circulate Skill Development</span>, a
                groundbreaking skill development event that brings together
                educators, innovators, and thought leaders to shape the future
                of learning. Join us for an inspiring and immersive experience
                designed to elevate education through collaboration,
                knowledge-shar-ing, and exploration.
              </p>
            </div>
          </div>
        </div>
        <div className="event_highlight_wrapper" data-aos="fade-up">
          <h3>Event Highlights:</h3>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="detail">
                <h4>Keynote Speakers:</h4>
                <p className="text-size-16 mb-0">
                  Engage with renowned experts and visionaries who will share
                  insights on the latest trends, challenges, and opportunities
                  in education.
                </p>
              </div>
              <div className="detail">
                <h4>Interactive Workshops:</h4>
                <p className="text-size-16 mb-0">
                  Participate in hands-on workshops led by industry
                  professionals, providing actionable strategies and practical
                  tools for enhancing training and learning.
                </p>
              </div>
              <div className="detail">
                <h4>Panel Discussions:</h4>
                <p className="text-size-16 mb-0">
                  Join thought-provoking panel discussions on crucial topics,
                  fostering meaningful conversations and offering diverse
                  perspectives on the current state and future direction of
                  training.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="detail">
                <h4>Networking Opportunities:</h4>
                <p className="text-size-16 mb-0">
                  Connect with fellow educators, administrators, and
                  professionals in intrested sector. Build lasting relationships
                  and exchange ideas to enrich your professional network.
                </p>
              </div>
              <div className="detail">
                <h4>Innovative Exhibits:</h4>
                <p className="text-size-16 mb-0">
                  Explore the latest training technologies, resources, and
                  solutions showcased by leading companies and organizations in
                  the training space.
                </p>
              </div>
              <div className="detail">
                <h4>Collaborative Learning Spaces:</h4>
                <p className="text-size-16 mb-0">
                  Immerse yourself in collaborative learning environments, where
                  instructor can exchange ideas, share best practices, and
                  collaborate on practical to implement transformative changes
                  in training.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="event-box" data-aos="fade-up">
          <h3 className="text-white mb-0">Event Details:</h3>
          <div className="detail">
            <span className="date text-white">
              <strong>Date: </strong>
              {event.date}
            </span>
            <span className="time text-white">
              <strong>Time: </strong>
              {event.startTime} to {event.endTime}
            </span>
            <span className="location text-white p-0">
              <strong>Location: </strong>
              {event.eventPlace}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
