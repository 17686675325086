import React from "react";
import { Navigate } from "react-router-dom";
import { isAdminAuthenticated } from "../../utils/auth";

const AdminRoute = ({ element }) => {
  const isAuthenticated = isAdminAuthenticated();
  return isAuthenticated ? element : <Navigate to="/admin" />;
};

export default AdminRoute;
