import React from "react";
import "../../assets/css/map.css";

export default function Map() {
  return (
    <div className="contact_map_con position-relative" data-aos="fade-up">
      <div className="container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.270531377351!2d80.99876417421926!3d26.863144662221245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be311286de463%3A0x18268debe3a876f4!2sINOX%20Software%20Solutions%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1713769124800!5m2!1sen!2sin"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </div>
    </div>
  );
}
