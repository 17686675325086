import React from "react";
import "../../assets/css/learning.css";
import { learningPartnerData } from "../../content/content";
import LearningPartnerCard from "../Cards/LearningPartnerCard";

export default function Learning() {
  return (
    <section className="learning-con">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-12">
            <div className="learning_content text-center">
              <h2>Your Lifelong Learning Partner</h2>
              <p className="text-size-18 mb-0">
                Why We Are Your Ideal Skill Developing Destination
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {learningPartnerData.map((item, index) => (
            <LearningPartnerCard
              key={index}
              image={item.image}
              heading={item.heading}
              summary={item.summary}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
