import React from "react";
import SubBanner from "../components/subBanner/SubBanner";
import Blog from "../components/course/Blog";
import { useParams } from "react-router-dom";
import { coursesData } from "../content/content";
import useScrollToTop from "../customHook/useScrollToTop";
import Courses from "./Courses";

export default function SingleCourse() {
  useScrollToTop();

  const { id } = useParams();

  if (!id) return <Courses />;

  const course = coursesData.find((course) => String(course.id) === id);

  return (
    <>
      <SubBanner title={course.heading} page={course.heading} />
      <Blog
        title={course.heading}
        blogContent={course.paragraph}
        keyFeature={course.keyPoints}
        image={course.image}
        facebook={course?.facebook}
        twitter={course?.twitter}
        linkedIn={course?.linkedIn}
        instagram={course?.instagram}
      />
    </>
  );
}
