import "./assets/css/app.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ActiveNavProvider from "./context/ActiveNavProvider";
import "aos/dist/aos.css";
import { AuthProvider } from "./context/AuthProvider";
import Routes from "./router/router";

function App() {
  const router = createBrowserRouter(Routes());

  return (
    <>
      <ActiveNavProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ActiveNavProvider>
    </>
  );
}

export default App;
