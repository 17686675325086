import React from "react";
import "../../assets/css/eventSection.css";
import experiencelogo from "../../assets/images/experiencelogo-image.png";
import EventCard from "../Cards/EventCard";
import { eventData } from "../../content/content";

export default function EventSection() {
  return (
    <section className="event-con position-relative">
      <figure className="eventlogo-image mb-0">
        <img src={experiencelogo} alt="" className="img-fluid"></img>
      </figure>
      <div className="container">
        <div className="experience_wrapper" data-aos="fade-up">
          <div className="row">
            {eventData.map((event) => (
              <EventCard
                key={event.id}
                id={event.id}
                eventImg={event.eventImg}
                heading={event.heading}
                date={event.date}
                startTime={event.startTime}
                endTime={event.endTime}
                eventPlace={event.eventPlace}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
