import React from "react";
import "../../assets/css/teacher.css";
import "../../assets/css/expert.css";
import SocialMedia from "../socialMedia/SocialMedia";
import { teacherData } from "../../content/content";

export default function Teacher() {
  return (
    <section className="expert-con instructorpage-expert">
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-12">
            <div className="expert_content text-center">
              <h6>Expert Teachers</h6>
              <h2 className="col-xl-8 col-lg-10 mx-auto mb-0">
                Discover the Experts Behind Your Learning Experience.
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center" data-aos="fade-up">
          {teacherData.map((teacher, index) => (
            <div key={index} className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="expert-box">
                <figure className="expert-image mb-0">
                  <img src={teacher.image} alt="" className="img-fluid"></img>
                </figure>
                <div className="content">
                  {/* <ul className="list-unstyled">
                    <SocialMedia
                      facebook={teacher.facebook}
                      linkedin={teacher.linkedin}
                      youtube={teacher.youtube}
                      instagram={teacher.instagram}
                    />
                  </ul> */}
                  <h3 className="mb-0" style={{ marginTop: "30px" }}>
                    {teacher.name}
                  </h3>
                  <span className="text-size-16">{teacher.position}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
