import React from "react";

function SponsorCard({ image }) {
  return (
    <li>
      <figure className="mb-0">
        <img src={image} alt="" className="img-fluid"></img>
      </figure>
    </li>
  );
}

export default SponsorCard;
