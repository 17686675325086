import React from "react";
import aboutImg from "../../assets/images/faculty-students.jpg";
import "../../assets/css/aboutSection.css";
import { Link } from "react-router-dom";
import { aboutCardData } from "../../content/content";
import AboutCard from "../Cards/AboutCard";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink } from "react-router-hash-link";

export default function AboutSection({ setPageState, activeNav }) {
  return (
    <section className="about-con">
      <div className="container">
        <div className="upper_content">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-md-1 order-2">
              <div
                className="about_content position-relative"
                data-aos="fade-up"
              >
                <h6>About Us</h6>
                <h2>Welcome to Our Circulate Skill Development Corporation.</h2>
                <p className="text-size-18">
                  Unlock Your Potential with Skill Development Programs Embark
                  on a journey of intellectual growth with our meticulously
                  designed skill development courses
                </p>
                <ul className="list-unstyled">
                  <li className="text-size-18">
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    Skill Development Programs
                  </li>
                  <li className="text-size-18">
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    Our course offers in-depth training
                  </li>
                  <li className="text-size-18">
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    Expert Guidance, Anytime You Need
                  </li>
                  <li className="text-size-18">
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    With personalized guidance from industry experts
                  </li>
                </ul>
                <HashLink
                  to="/course/#courses"
                  className="default-btn text-decoration-none"
                  onClick={() => {
                    activeNav("courses");
                    setPageState({
                      pageActive: false,
                      coursePageActive: true,
                    });
                  }}
                >
                  Explore Skill Programs
                </HashLink>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-md-2 order-1 text-md-left text-center">
              <div className="about_wrapper position-relative">
                <figure className="about-image mb-0" data-aos="zoom-in">
                  <img src={aboutImg} alt="" className="img-fluid"></img>
                </figure>
                <div className="circle">
                  <div className="content">
                    <span className="number text-white">500+</span>
                    <span className="text text-white">
                      Total Active Students
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" data-aos="fade-up">
          {aboutCardData.map((item, index) => (
            <AboutCard
              key={index}
              icon={item.icon}
              heading={item.heading}
              subHeading={item.subHeading}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
