import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./AdminNavbar.css";
import "./Adminstyle.css";
import logo from "../../src/assets/images/CSDC_Logo-removebg.png";

const AdminNavbar = () => {
  const [menuBtnExt, setMenuBtnExt] = useState(false);
  const [active, setActive] = useState(true);

  // ------------------handle site header--------------

  const navigate = useNavigate();

  const handleScroll = () => {
    const scrollY = window.scrollY;
    let siteHeader = document.querySelector(".tm-site-header");
    scrollY >= 10
      ? siteHeader.classList.add("small-height")
      : siteHeader.classList.remove("small-height");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const toggleMenuBtn = () => {
    setMenuBtnExt(!menuBtnExt);
    const primaryNavList = document.querySelector(".tm-primary-nav-list");
    if (menuBtnExt) {
      primaryNavList.style.display = "block";
    } else {
      primaryNavList.style.display = "none";
    }
  };

  // ------for mobile menu--------
  const [isMobile, setIsMobile] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    handleResize(); // Call handleResize once initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isnotPlus, setIsNotPlus] = useState(false);
  const DropdownHandler = () => {
    setIsNotPlus(!isnotPlus);
    const item = document.querySelector(".mobile-menu-services");
    isnotPlus ? (item.style.display = "block") : (item.style.display = "none");
  };

  const logoutHandler = () => {
    localStorage.removeItem("admin-token");
    navigate("/admin");
  };

  return (
    <header className="tm-header3">
      <div className="tm-site-header ">
        <div className="tm-header-info-wrap">
          <div className="container tm-header-info">
            <Link className="a" to="tel:917565998188">
              <i className="fa-solid fa-phone"></i> +91 756 5998 188
            </Link>
            <Link
              className="a"
              to="mailto:info@​circulate​skilldevelopment​.com"
            >
              <i className="fa fa-envelope"></i>{" "}
              info@​circulate​skilldevelopment​.com
            </Link>
          </div>
        </div>
        <div className="tm-header-menu ">
          <div className="container tm-header-menu-container">
            <div className="tm-site-branding">
              <Link to="/admin/home" className="tm-logo-link a">
                <img
                  src={logo}
                  alt=""
                  className="tm-logo "
                  style={{ width: "90px" }}
                />
              </Link>
            </div>
            <nav
              className={`${
                isMobile ? "m-menu" : "tm-primary-nav"
              } tm-onepage-nav`}
            >
              <div
                className={`m-menu-btn ${menuBtnExt ? "m-menu-btn-ext" : ""}`}
                onClick={toggleMenuBtn}
              >
                <span></span>
              </div>
              <ul
                style={menuBtnExt ? { display: "block" } : { display: "none" }}
                className={`tm-primary-nav-list ${menuBtnExt ? "show" : ""}`}
              >
                <li className="menu-item">
                  <Link
                    to="/admin/home"
                    className={
                      active
                        ? "nav-link tm-smooth-move a active"
                        : "nav-link tm-smooth-move a"
                    }
                    onClick={() => setActive(true)}
                  >
                    Home
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to="/admin/studentsDetails"
                    className={
                      !active
                        ? "nav-link tm-smooth-move a active"
                        : "nav-link tm-smooth-move a"
                    }
                    onClick={() => setActive(false)}
                  >
                    Student
                  </Link>
                </li>
                {/* <li className="menu-item">
                  <Link to="/admin-jobs" className="nav-link tm-smooth-move a">
                    Inquiry
                  </Link>
                </li> */}
                <li className="menu-item" onClick={logoutHandler}>
                  <a
                    style={{ cursor: "pointer" }}
                    className="nav-link tm-smooth-move a"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          {/* <!-- .tm-header-menu-container --> */}
        </div>
        {/* <!-- .tm-header-menu --> */}
      </div>
    </header>
  );
};

export default AdminNavbar;
