/* eslint-disable jsx-a11y/anchor-is-valid */
import AdminNavbar from "../../admin/AdminNavbar";
import { Outlet } from "react-router-dom";
import Footer from "../home/Footer";
import { useContext } from "react";
import ActiveNavContext from "../../context/ActiveNavContext";

export default function NavBar() {
  const {
    pageState,
    setPageState,
    activeLink,
    activeNav,
    getClassName,
  } = useContext(ActiveNavContext);

  return (
    <>
      <AdminNavbar />
      <Outlet />
      <Footer
        activeLink={activeLink}
        activeNav={activeNav}
        setPageState={setPageState}
        visible={false}
      />
    </>
  );
}
