import React, { useEffect } from "react";
import SubBanner from "../components/subBanner/SubBanner";
import EventSection from "../components/event/EventSection";
import Register from "../components/home/Register";
// import Sponsor from "../components/home/Sponsor";

export default function Event() {
  return (
    <>
      <SubBanner title={"Events"} page={"Events"} />
      <EventSection />
      <Register
        pageClassName={"contactpage-register"}
        title={"Get Started"}
        subHeading={
          <>
            Register Your Account Get <span>Access</span> To Our Courses Online
          </>
        }
        note={
          "Please enter accurate information, including your qualifications and age."
        }
        buttonText={"Register Now"}
        dropDownHeading={"Choose Course"}
        dropDownOptions={[
          "Select Courses:",
          "General Duty Assistant",
          "Sewing Machine Operator",
          "Assistant Beauty Therapist",
        ]}
        url="registered_users"
      />
      {/* <Sponsor pageSponsor={"instructorpage-sponsor"} /> */}
    </>
  );
}
