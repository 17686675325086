import React, { useState } from "react";
import "../../assets/css/register.css";
import axios from "axios";
import NotificationToast from "../toast/NotificationToast";
import { SERVER_URL } from "../../utils/database_Config";
import { isAdult } from "../../utils/isAdult";

const qualificationDropDownHeading = "Qualification";
const qualificationDropDownOptions = [
  "High School",
  "Intermediate ",
  "Graduate",
];

export default function Register({
  pageClassName = "",
  title,
  subHeading,
  children,
  contactPhone,
  option = true,
  buttonName,
  note,
  buttonText,
  dropDownHeading,
  dropDownOptions,
  url,
}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    ...(option ? { dob: "" } : {}),
    ...(option ? { qualification: "High School" } : {}),
    ...(option ? { option: "" } : {}),
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    ...(option ? { dob: false } : {}),
    ...(option ? { qualification: false } : {}),
    ...(option ? { option: false } : {}),
    message: false,
  });

  const [showToast, setShowToast] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "name":
        return value.trim() ? false : "Please enter name";
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return value.trim()
          ? emailRegex.test(value)
            ? false
            : "Please enter a valid email address"
          : "Please enter email";
      case "phone":
        const phoneRegex = /^\d{10}$/;
        return value.trim()
          ? phoneRegex.test(value)
            ? false
            : "Please enter a valid 10-digit phone number"
          : "Please enter phone number";
      case "dob":
        return value.trim()
          ? isAdult(value)
            ? false
            : "Minimum age for registration is 18 years"
          : "Please enter date of birth";
      case "message":
        return value.trim() ? false : "Please enter message";
      case "option":
        return value && value !== dropDownOptions[0]
          ? false
          : "Please select a option";
      default:
        return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) newErrors[field] = error;
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await axios.post(`${SERVER_URL}${url}`, formData);
        if (response.status === 200) {
          setFormData({
            name: "",
            email: "",
            phone: "",
            dob: "",
            ...(option ? { qualification: "High School" } : {}),
            ...(option ? { option: "" } : {}),
            message: "",
          });
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };

  return (
    <section className={`register-con ${pageClassName}`}>
      <div className="container" data-aos="fade-up" id="register">
        <div className="row">
          <div className="col-12">
            <div className="register_content text-center">
              <h6>{title}</h6>
              <h2 className="col-lg-10 mx-auto mb-0">{subHeading}</h2>
              {children}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="register-box">
              {showToast && (
                <NotificationToast
                  show={showToast}
                  message="Form submitted successfully"
                />
              )}
              <form id="contactpage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Name:</label>
                          <input
                            type="text"
                            className="form_style"
                            placeholder="Full Name"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                          ></input>
                          {errors.name && (
                            <span htmlFor="name" className="error">
                              * {errors.name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Email:</label>
                          <input
                            type="email"
                            className="form_style"
                            placeholder="Email Address"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                          ></input>
                          {errors.email && (
                            <span htmlFor="email" className="error">
                              * {errors.email}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Phone:</label>
                          <input
                            type="tel"
                            maxLength={10}
                            className={`${contactPhone} form_style`}
                            placeholder="Enter Phone Number"
                            name="phone"
                            id="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          ></input>
                          {errors.phone && (
                            <span htmlFor="phone" className="error">
                              * {errors.phone}
                            </span>
                          )}
                        </div>
                      </div>
                      {option && (
                        <div className="col-12">
                          <div className="form-group">
                            <label>Date of Birth:</label>
                            <input
                              type="date"
                              maxLength={2}
                              className={`${contactPhone} form_style`}
                              name="dob"
                              id="dob"
                              value={formData.dob}
                              onChange={handleChange}
                            ></input>
                            {errors.dob && (
                              <span htmlFor="dob" className="error">
                                * {errors.dob}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="row">
                      <div className={option ? "col-12" : "d-none"}>
                        <div className="form-group courses">
                          <label htmlFor="qualification">
                            {qualificationDropDownHeading}:
                          </label>
                          <select
                            className="form-control"
                            name="qualification"
                            id="qualification"
                            value={formData.qualification}
                            onChange={handleChange}
                            style={{ lineHeight: "18px" }}
                          >
                            {qualificationDropDownOptions?.map(
                              (item, index) => (
                                <option key={index}>{item}</option>
                              )
                            )}
                          </select>
                          {errors.qualification && (
                            <span htmlFor="qualification" className="error">
                              * {errors.qualification}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={option ? "col-12" : "d-none"}>
                        <div className="form-group courses">
                          <label htmlFor="option">{dropDownHeading}:</label>
                          <select
                            className="form-control"
                            name="option"
                            id="option"
                            value={formData.option}
                            onChange={handleChange}
                          >
                            {dropDownOptions?.map((item, index) => (
                              <option key={index}>{item}</option>
                            ))}
                          </select>
                          {errors.option && (
                            <span htmlFor="option" className="error">
                              * {errors.option}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-0">
                          <label>Message:</label>

                          <textarea
                            className="form_style"
                            placeholder="Message"
                            rows="3"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                          ></textarea>
                          {errors.message && (
                            <span
                              htmlFor="message"
                              className="error"
                              style={{ marginTop: "-17px" }}
                            >
                              * {errors.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {!option && (
                        <div className={"col-12"}>
                          <div className="manage-button">
                            <button
                              type="submit"
                              id="submit"
                              className="register_now text-white text-decoration-none"
                            >
                              {buttonName}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {option && (
                    <div className={"register_button"}>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <p className="text-size-14 mb-0">
                          <span>*</span>
                          {note}
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <button
                          type="submit"
                          id="submit"
                          className="register_now text-white text-decoration-none"
                        >
                          {buttonText}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
