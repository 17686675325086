import {
  faDownload,
  faEye,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import moment from "moment-timezone";

const Receipts = ({
  receiptsModal,
  setReceiptsModal,
  studentPayments,
  createAndDownloadPdf,
}) => {
  const handleClose = () => setReceiptsModal(false);

  return (
    <>
      <Modal
        show={receiptsModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Fee Receipts</Modal.Title>
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={handleClose}
            style={{ fontSize: "30px", color: "red", cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <SelectReceipts
            studentPayments={studentPayments}
            createAndDownloadPdf={createAndDownloadPdf}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Receipts;

export function SelectReceipts({ studentPayments, createAndDownloadPdf }) {
  console.log("studentPayments : ", studentPayments);

  const monthsYear = (timestamp) => {
    const date = moment.unix(timestamp).tz("Asia/Kolkata");
    const formattedDate = date.format("MMMM YYYY");
    return formattedDate;
  };

  return (
    <>
      {studentPayments?.map((receipt, index) => (
        <>
          {!index && (
            <h4 style={{ textAlign: "center" }}>
              {receipt.first_name + " " + receipt.last_name + " - Receipts"}
            </h4>
          )}
          <div
            key={`reverse-${receipt.payment_date}`}
            className="mb-3"
            style={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "black" }}>
              <span style={{ fontSize: "16px", fontWeight: "bolder" }}>
                {index + 1}.
              </span>
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                {" " +
                  receipt.bill_number +
                  `   [${monthsYear(receipt.payment_date)}]`}
              </span>
            </p>
            <div
              style={{
                display: "flex",
                gap: "15px",
              }}
            >
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => {
                  createAndDownloadPdf(receipt, "view");
                }}
                className="icon-hover"
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              />
              <FontAwesomeIcon
                icon={faDownload}
                onClick={() => {
                  createAndDownloadPdf(receipt, "download");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                className="icon-hover"
              />
            </div>
          </div>
        </>
      ))}
    </>
  );
}
