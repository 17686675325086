import React from "react";
import SubBanner from "../components/subBanner/SubBanner";
// import Sponsor from "../components/home/Sponsor";
import Register from "../components/home/Register";

export default function FinancialAid() {
  return (
    <>
      <SubBanner title={"Financial Aid"} page={"Financial Aid"} />
      <Register
        title={"Get Started"}
        subHeading={`Financial Aid Application Form`}
        note={"Thank you for your interest in applying for financial aid"}
        buttonText={"Apply Now"}
        dropDownHeading={"Financial Aid Request"}
        dropDownOptions={[
          "Select Aid Option:",
          "General Duty Assistant",
          "Sewing Machine Operator",
          "Assistant Beauty Therapist",
        ]}
      >
        <p className="col-lg-10 mx-auto text-size-18 mb-0">
          We are committed to supporting learners from diverse backgrounds in
          pursuing their educational goals. Please complete the following form
          to be considered for financial assistance.
        </p>
      </Register>
      {/* <Sponsor pageSponsor={"instructorpage-sponsor"} /> */}
    </>
  );
}
