import React from "react";
import SubBanner from "../components/subBanner/SubBanner";
import Sponsor from "../components/home/Sponsor";
import Community from "../components/testimonial/Community";
import Register from "../components/home/Register";

export default function Testimonial() {
  return (
    <>
      <SubBanner title={"Testimonials"} page={"Testimonials"} />
      <Community
        testimonials={"Student Testimonials"}
        heading={"High Quality Education"}
        content={`I've had an incredible learning experience at Skill-Boost. The courses are engaging, the instructors are knowledgeable, 
                                    and the platform is user-friendly. It's transformed the way I approach learning online.`}
        name={"Thomas Anderson"}
      >
        <div className="community_content text-center">
          <h2>Hear from Our Community</h2>
          <p className="col-lg-12 text-size-18 mb-0">
            At <span>SkillBoost</span>, our commitment to delivering quality
            education is reflected in the experiences and testimonials of our
            valued learners. Discover what students, educators, and industry
            professionals have to say about their journey with us.
          </p>
        </div>
      </Community>
      <Community
        testimonials={"Educator Insights"}
        heading={"Dynamic Teaching Experiences"}
        content={`Teaching online with SkillBoost has been a game-changer. The platform provides the flexibility to design engaging courses and
      interact with students effectively. It's a fantastic resource for educators who are passionate about delivering quality education.`}
        name={"Professor Emily R."}
        componentAppearance={"communitytwo-con communityother-con"}
        nameOrder={"order-md-2 order-1"}
        contentOrder={"order-md-1 order-2"}
      />
      <Community
        testimonials={"Educator Insights"}
        heading={"Dynamic Teaching Experiences"}
        content={`Teaching online with SkillBoost has been a game-changer. The platform provides the flexibility to design engaging courses and
      interact with students effectively. It's a fantastic resource for educators who are passionate about delivering quality education.`}
        name={"Professor Emily R."}
        componentAppearance={"communityother-con"}
      />
      <Register
        title={"Get Started"}
        subHeading={
          <>
            Register Your Account Get <span>Access</span> To Our Courses Online
          </>
        }
        note={
          "Please enter accurate information, including your qualifications and age."
        }
        buttonText={"Register Now"}
        dropDownHeading={"Choose Course"}
        dropDownOptions={[
          "Select Courses:",
          "General Duty Assistant",
          "Sewing Machine Operator",
          "Assistant Beauty Therapist",
        ]}
      />

      <Sponsor pageSponsor={"instructorpage-sponsor"} />
    </>
  );
}
