import React, { useEffect, useState } from "react";
import "./AdminFooter.css";

const AdminHome = () => {
  const [adminName, setAdminName] = useState({ firstName: "", lastName: "" });

  useEffect(() => {
    const firstName = localStorage.getItem("admin-firstName");
    const lastName = localStorage.getItem("admin-lastName");
    setAdminName({ firstName: firstName, lastName: lastName });
  }, []);

  return (
    <section className="tm-relative" id="appointment">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 admin-home">
            <div className="tm-section-heading text-center">
              <h1>Welcome</h1>
              <div className="tm-section-seperator">
                <span></span>
              </div>
              <div style={{ margin: "3% 0px" }}>
                <h5>
                  Hello, {adminName.firstName} {adminName.lastName} ! <br />
                  Welcome to the admin DashBoard.
                </h5>
                <p>
                  Your presence ensures that our systems run smoothly and
                  securely. Your login signifies another day of excellence and
                  dedication to our organization
                  <br /> Thank you for all that you do!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminHome;
