import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import ActiveNavContext from "../../context/ActiveNavContext";

function EventCard({
  eventImg,
  heading,
  date,
  startTime,
  endTime,
  eventPlace,
  id,
}) {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
      <div className="event-box">
        <figure className="event-image mb-0">
          <img
            src={eventImg}
            alt=""
            className="img-fluid"
            style={{ width: "350px", height: "240px" }}
          ></img>
        </figure>
        <div className="content">
          <Link
            to={`/eventDetail/${id}`}
            className="text-decoration-none"
            onClick={() => {
              activeNav("eventDetail");
              setPageState({
                pageActive: true,
                coursePageActive: false,
              });
            }}
          >
            <h4>{heading}</h4>
          </Link>
          <span className="">
            <i>
              <FontAwesomeIcon icon={faClock} />
            </i>
            {date}
            <span className="dash mb-0">-</span>
            {startTime} - {endTime}
          </span>
          <span className="mb-0 country">
            <i>
              <FontAwesomeIcon icon={faLocationPin} />
            </i>
            {eventPlace}
          </span>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
