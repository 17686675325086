import React from "react";
import chooseImg from "../../assets/images/choose-image.jpg";
import "../../assets/css/choose.css";

export default function Choose() {
  return (
    <section className="choose-con position-relative">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-12">
            <div className="choose_content text-center">
              <h2>Why Choose SkillBoost?</h2>
              <p className="col-lg-9 mx-auto text-size-18 mb-0">
                At <span>SkillBoost</span>, our commitment to delivering quality
                education is reflected in the experiences and testimonials of
                our valued learners.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 d-lg-block d-none">
            <figure className="mb-0 choose-image">
              <img src={chooseImg} alt="" className="img-fluid"></img>
            </figure>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="choose_content_wrapper">
              <div className="detail">
                <h4>Affordable Pricing:</h4>
                <p className="text-size-16 mb-0">
                  Enjoy high-quality education at competitive prices.
                </p>
              </div>
              <div className="detail">
                <h4>Comprehensive Curriculum:</h4>
                <p className="text-size-16 mb-0">
                  Gain access to a diverse range of courses covering various
                  subjects.
                </p>
              </div>
              <div className="detail mb-0">
                <h4>Interactive Learning:</h4>
                <p className="text-size-16 mb-0">
                  Engage with interactive content and collaborate with fellow
                  learners.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="choose_content_wrapper">
              <div className="detail">
                <h4>Flexible Learning:</h4>
                <p className="text-size-16 mb-0">
                  Study at your own pace, anytime, anywhere.
                </p>
              </div>
              <div className="detail">
                <h4>Continuous Support:</h4>
                <p className="text-size-16 mb-0">
                  Receive ongoing support from our team of dedicated instructors
                  and support staff.
                </p>
              </div>
              <div className="detail mb-0">
                <h4>Expert Instructors:</h4>
                <p className="text-size-16 mb-0">
                  Learn from industry experts and experienced educators.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
