import React, { useState } from "react";
import ActiveNavContext from "./ActiveNavContext";
import useNavigationClass from "../utils/useNavigationClass";

const ActiveNavProvider = ({ children }) => {
  const [pageState, setPageState] = useState({
    pageActive: false,
    coursePageActive: false,
  });

  const { activeLink, activeNav, getClassName } = useNavigationClass();

  return (
    <ActiveNavContext.Provider
      value={{
        pageState,
        setPageState,
        activeLink,
        activeNav,
        getClassName,
      }}
    >
      {children}
    </ActiveNavContext.Provider>
  );
};

export default ActiveNavProvider;
