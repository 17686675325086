import React from "react";
import { Link } from "react-router-dom";

const ArticleCard = ({ image, title, date, comments, heading }) => {
  return (
    <div className="col-lg-4 col-md-4 col-sm-8 col-12 column3">
      <div className="article-box">
        <div className="image position-relative">
          <figure className="article-image mb-0">
            <img
              src={image}
              alt=""
              className="img-fluid"
              style={{ width: "100%", height: "250px" }}
            ></img>
          </figure>
          <span>{title}</span>
        </div>
        <div className="content">
          <span className="text-size-14">
            {date}
            <span className="dot">.</span>
            <span className="comment">{comments} Comments</span>
          </span>
          <Link
            to="/single-blog"
            className="text-decoration-none"
            onClick={(e) => e.preventDefault()}
          >
            <h4 className="mb-0">{heading}</h4>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
