import React from "react";
import "../../assets/css/contactSection.css";
import contactIcon1 from "../../assets/images/contact-icon1.png";
import contactIcon2 from "../../assets/images/contact-icon2.png";
import contactIcon3 from "../../assets/images/contact-icon3.png";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ContactSection({ contactPage }) {
  const handleSupport = (event) => {
    event.preventDefault();
    window.open("https://wa.me/+917565998188", "_blank");
  };

  return (
    <section className={`contact-con ${contactPage}`}>
      <div className="contant_bg position-relative">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12">
              <div className="contact_content text-center">
                <h2 className="text-white">Contacting Us is Easy</h2>
                <p className="col-lg-10 mx-auto mb-0 text-size-18 text-white">
                  We value your feedback, questions, and inquiries. Our
                  dedicated support team is here to assist you every step of the
                  way. Feel free to reach out to us through the following
                  channels:
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-10 col-12 column3">
              <div className="contact-box">
                <figure className="icon">
                  <img src={contactIcon1} alt="" className="img-fluid"></img>
                </figure>
                <h3>Live Chat Support</h3>
                <p className="mb-0 text-size-16">
                  Our live chat support is available 24/7 to provide instant
                  assistance
                  <a
                    href="https://wa.me/+917565998188"
                    onClick={handleSupport}
                    style={{
                      marginLeft: "8px",
                      fontSize: "24px",
                      color: "green",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-10 col-12 column3">
              <div className="contact-box">
                <figure className="icon">
                  <img src={contactIcon2} alt="" className="img-fluid"></img>
                </figure>
                <h3>Email Support</h3>
                <p className="mb-0 text-size-16">
                  You can email us at{" "}
                  <a
                    href="mailto:info@circulateskilldevelopment.com"
                    className="text-size-16 text-decoration-none"
                  >
                    info@circulateskilldevelopment.com
                  </a>{" "}
                  for any general inquiries, feedback, or assistance
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-10 col-12 column3">
              <div className="contact-box mb-0">
                <figure className="icon">
                  <img src={contactIcon3} alt="" className="img-fluid"></img>
                </figure>
                <h3>Phone Support</h3>
                <p className="mb-0 text-size-16">
                  Need to speak with someone directly? Call our support team at{" "}
                  <a
                    href="tel:+917565998188"
                    className="text-size-16 text-decoration-none"
                  >
                    +91 756 599 8188
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
