import React, { useContext } from "react";
import ActiveNavContext from "../../context/ActiveNavContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

export default function CoursesCard({
  image,
  id,
  price,
  heading,
  content,
  link,
}) {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-12 courses-card">
      <div className="course-box">
        <div className="price">
          <span className="number">{id}</span>
          {/* <span className="value">₹ {price}</span> */}
        </div>
        <figure className="course-image">
          <img src={image} alt="" className="img-fluid"></img>
        </figure>
        <Link
          to={link}
          className="text-decoration-none"
          onClick={() => {
            activeNav("singleCourses");
            setPageState({
              pageActive: false,
              coursePageActive: true,
            });
          }}
        >
          <h4>{heading}</h4>
        </Link>
        <p className="text-size-16">
          {content.replace(/\s/g, "").length > 100
            ? `${content.substring(0, 100)}...`
            : content}
        </p>
        <Link
          to={link}
          className="text-decoration-none read_more"
          onClick={() => {
            activeNav("singleCourses");
            setPageState({
              pageActive: false,
              coursePageActive: true,
            });
          }}
        >
          Read More
          <i>
            <FontAwesomeIcon icon={faArrowRightLong} />
          </i>
        </Link>
      </div>
    </div>
  );
}
