import React from "react";

export default function WhatsappButton() {
  return (
    <a
      id="whatsapp"
      className="show"
      target="_blank"
      rel="noreferrer"
      href="https://wa.me/+917565998188"
    >
      {""}
    </a>
  );
}
