import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/pageLayout.css";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";
import ActiveNavContext from "../../context/ActiveNavContext";
import ScrollToTopButton from "../scrollToTopButton/ScrollToTopButton";
import WhatsappButton from "../whatsappButton/WhatsappButton";
import { scrollToTop } from "../../utils/scrollToTop";
import ChatBot from "../chatbot/ChatBot";

export default function PageLayout() {
  const {
    pageState,
    setPageState,
    activeLink,
    activeNav,
    getClassName,
  } = useContext(ActiveNavContext);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition >= 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <NavBar
        getClassName={getClassName}
        pageState={pageState}
        activeNav={activeNav}
        setPageState={setPageState}
      />
      <Outlet />
      <Footer
        activeLink={activeLink}
        activeNav={activeNav}
        setPageState={setPageState}
        vissible={true}
      />
      <ScrollToTopButton isVisible={isVisible} scrollToTop={scrollToTop} />
      <ChatBot visible={isVisible} />
      <WhatsappButton />
    </>
  );
}
