import React, { useContext, useState } from "react";
import "../assets/css/login.css";
import axios from "axios";
import { SERVER_URL } from "../utils/database_Config";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [error, setError] = useState(false);

  console.log(error);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [inputErrors, setInputErrors] = useState({});

  const validate = (email, password) => {
    const errors = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 5) {
      errors.password = "Password must be at least 6 characters";
    }

    setInputErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate(formData.email, formData.password)) {
      try {
        const { data } = await axios.post(
          `${SERVER_URL}admins/login`,
          formData
        );

        console.log(data);

        if (data.token) {
          localStorage.setItem("admin-token", data.token);
          const adminId = data.admin.id;
          localStorage.setItem("admin-Id", adminId);
          localStorage.setItem("admin-firstName", data.admin.first_name);
          localStorage.setItem("admin-lastName", data.admin.last_name);
          setIsAuthenticated(true);
          navigate("/admin/home");
        }
      } catch (error) {
        console.error("Login failed:", error.response.data.error);
        setError(true);
        toast.error(error.response.data.error);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="card border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 ">
        <div className="card-header bg-transparent border-0 text-center text-uppercase">
          <h3>Employee Login</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="form-group">
              <label className="mb-0">
                Email<span className="text-danger">*</span>
              </label>
              <input
                name="email"
                type="email"
                className="form-control"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {inputErrors.email && (
                <span style={{ color: "red" }}>* Please enter email</span>
              )}
            </div>
            <div className="form-group">
              <label className="mb-0">
                Password<span className="text-danger">*</span>
              </label>
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
              {inputErrors.password && (
                <span style={{ color: "red" }}>* Please enter password</span>
              )}
            </div>
            <p className="text-center mb-0">
              <input
                type="submit"
                className="btn btn-lg w-100 text-uppercase loginButton"
                value="Login"
              />
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
