import React, { useEffect, useState } from "react";
import "../assets/css/studentsDetails.css";
import axios from "axios";
import { saveAs } from "file-saver";
import { SERVER_URL } from "../utils/database_Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faDownload,
  faEye,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import StudentDetailsCard from "./StudentDetailsCard";
import { useNavigate,Link } from "react-router-dom";
import Receipts from "./Receipts";
import { HashLink } from "react-router-hash-link";
import CourseBatchDropDown from "./CourseBatchDropDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { exportToExcel } from "../components/ExportToExcel/ExportToExcel";

function StudentsDetails() {
  const navigate = useNavigate();

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [studentsData, setstudentsData] = useState([]);
  const [cardVisibility, setCardVisibility] = useState(false);
  const [receiptsModal, setReceiptsModal] = useState(false);
  const [studentPayments, setStudentPayments] = useState([]);
  const [studentID, setStudentID] = useState(null);
  const [students, setStudents] = useState(null);
  const [receiptLoader, setReceiptLoader] = useState(false);

  console.log("dataToDisplay", dataToDisplay);
  console.log("studentsData", studentsData);
  console.log("currentPageNumber : ", currentPageNumber);

  // console.log("receiptLoader : ", receiptLoader);

  // console.log(" filteredStudents for filter : ", studentsData);
  // console.log(
  //   " students from  ${SERVER_URL}courses/${couresWiseBatch.courseId}/batches/${couresWiseBatch.batchId}: ",
  //   students
  // );

  const token = localStorage.getItem("admin-token");

  const TOTAL_VALUES_PER_PAGE = 5;

  const goOnPrevPage = () => {
    if (currentPageNumber === 1) return;
    setCurrentPageNumber((prev) => prev - 1);
  };
  const goOnNextPage = () => {
    console.log(
      "currentPageNumber === studentsData.length / TOTAL_VALUES_PER_PAGE : ",
      currentPageNumber,
      studentsData.length / TOTAL_VALUES_PER_PAGE,
      " : ",
      currentPageNumber === studentsData.length / TOTAL_VALUES_PER_PAGE
    );
    if (currentPageNumber >= studentsData.length / TOTAL_VALUES_PER_PAGE) {
      console.log(
        "inside : currentPageNumber === studentsData.length / TOTAL_VALUES_PER_PAGE : ",
        currentPageNumber,
        studentsData.length / TOTAL_VALUES_PER_PAGE,
        " : ",
        currentPageNumber === studentsData.length / TOTAL_VALUES_PER_PAGE
      );
      return;
    }
    setCurrentPageNumber((prev) => prev + 1);
  };
  const handleSelectChange = (e) => {
    setCurrentPageNumber(e.target.value);
  };

  useEffect(() => {
    const start = (currentPageNumber - 1) * TOTAL_VALUES_PER_PAGE;
    const end = currentPageNumber * TOTAL_VALUES_PER_PAGE;
    setDataToDisplay(studentsData.slice(start, end)); // fix me [ filter status 0 students]
  }, [currentPageNumber]);

  const sortStudentsDetails = (order, heading) => {
    const key = tableheadings[heading];
    //const sortedData = studentsData.slice().sort((a, b) => {
    const sortedData = dataToDisplay.slice().sort((a, b) => {
      const valueA = a[key].toUpperCase();
      const valueB = b[key].toUpperCase();
      const numericA = !isNaN(valueA) ? parseFloat(valueA) : valueA;
      const numericB = !isNaN(valueB) ? parseFloat(valueB) : valueB;

      if (order === "asc") {
        return numericA > numericB ? 1 : numericA < numericB ? -1 : 0;
      } else {
        return numericB > numericA ? 1 : numericB < numericA ? -1 : 0;
      }
    });
    // setstudentsData(sortedData);
    setDataToDisplay(sortedData);
  };

  useEffect(() => {
    axios
      .get(`${SERVER_URL}getStudentsData`)
      .then((response) => {
        const data = response.data.filter((item) => item.status === 1);
        setstudentsData(data);
        setDataToDisplay(data.slice(0, TOTAL_VALUES_PER_PAGE));
      })
      .catch((error) => console.error("Error fetching details:", error));
  }, []);

  const fetchStudentPayments = async (studentId) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}getStudentReceiptDetails/${studentId}`
      );
      setStudentPayments(response.data);
    } catch (err) {
      console.log("An error occurred while fetching student Payments data.");
    } finally {
      console.log("Fetching student Payments data successfully.");
    }
  };

  const tableheadings = {
    "#": "",
    "First Name": "first_name",
    "Last Name": "last_name",
    Email: "email",
    "Phone Number": "phone",
    "Batch Name": "batch_name",
    "Course Name": "course_name",
    "Registration Fees": "payment_amount",
    "Fee Status": "",
    "Application Status": "",
    Receipt: "",
    Action: "",
  };
  const createAndDownloadPdf = async (traineeDetail, option) => {
    setReceiptLoader(true);
    toast.loading("Generating Receipt");
    setReceiptsModal(false);
    axios
      .post(`${SERVER_URL}generate-pdf`, traineeDetail, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() =>
        axios.get(`${SERVER_URL}fetch-pdf`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      )
      .then((res) => {
        setReceiptLoader(false);

        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        if (option === "view") window.open(pdfUrl, "_blank");
        else
          saveAs(
            pdfBlob,
            `${traineeDetail.first_name +
              " " +
              traineeDetail.last_name}_Fee_Receipt.pdf`
          );
      });
  };

  const feeStatus = (depositedFee, totalFee) => {
    if (!depositedFee || !totalFee) return "Pending";
    if (depositedFee == totalFee) {
      return "Paid";
    } else if (depositedFee > 0 && depositedFee < totalFee) {
      return `Partially Paid [ ${totalFee - depositedFee}]`;
    } else {
      return "Pending";
    }
  };

  const deleteHandler = async (id) => {
    console.log("delete token : ", token);
    await axios.put(
      `${SERVER_URL}student-delete/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setstudentsData(studentsData.filter((item) => item.id !== id));
    setDataToDisplay(dataToDisplay.filter((item) => item.id !== id));
  };

  const editHandler = async (id) => {
    navigate(`/admin/updateStudentDetails/${id}`);
  };

  const handleFilterChange = (selectedStatus) => {
    let filteredData = [];

    if (students) {
      if (selectedStatus === "partial paid") {
        filteredData = students.filter(
          (student) => student.total_payment !== 0 && student.total_payment < student.course_fees
        );
      } else if (selectedStatus === "paid") {
        filteredData = students.filter(
          (student) => student.total_payment == student.course_fees
        );
      } else if (selectedStatus === "fee pending") {
        filteredData = students.filter(
          (student) => student.total_payment === 0
        );
      } else {
        filteredData = students;
      }
    }
    const data = filteredData.filter((item) => item.status === 1);
    setstudentsData(data);
    setDataToDisplay(data.slice(0, TOTAL_VALUES_PER_PAGE));
  };

  const exportDataToExcel=(e,studentsData)=>{
    // fix me : Filter data of student who are not registered in any course.
    e.preventDefault();
    console.log(' exportDataToExcel called.');
    const excelData = studentsData.filter((item) => item.status === 1).map(student => ({
      "Enrollment Number": student.registration_number,
      "First name": student.first_name,
      "Last name": student.last_name,
      "Father name": student.father_name,
      "Course name": student.course_name,
      "Batch Start Date": student.batch_start_date?.split('T')[0],
      "Batch End Date": student.batch_end_date?.split('T')[0]
  }));
    exportToExcel(excelData);
  }

  return (
    <>
    {/* <ExportExcel userData={dataToDisplay[0]}/> */}
      {receiptLoader && (
        <ToastContainer
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "9999",
            width: "80%",
            maxWidth: "300px",
            maxHeight: "50%",
            overflow: "hidden",
          }}
        />
      )}
      <Receipts
        receiptsModal={receiptsModal}
        setReceiptsModal={setReceiptsModal}
        studentPayments={studentPayments}
        createAndDownloadPdf={createAndDownloadPdf}
      />

      {/* <div className="mr-5 d-flex justify-content-end add_std">
        <button
          className="btn btn-dark"
          onClick={() => navigate("/admin/addStudentDetails")}
        >
          Add Student
        </button>
      </div> */}
      <div className="tm-section-heading text-center mb-4 p-2 add_std">
        <h2 style={{ color: "#ff822d" }}>Students details</h2>
      </div>
      <CourseBatchDropDown
        setStudents={setStudents}
        onFilterChange={handleFilterChange}
        setstudentsData={setstudentsData}
        setDataToDisplay={setDataToDisplay}
        TOTAL_VALUES_PER_PAGE={TOTAL_VALUES_PER_PAGE}
      />
      <div
        className="container d-flex justify-content-end mb-3"
        style={{ textDecoration: "underline", color: "#007bff" }}
      >
        <Link to="#" onClick={(e)=>{exportDataToExcel(e,studentsData)}} style={{marginRight:'10px'}}>Export to Excel</Link>
        <Link to="/admin/addStudentDetails"  >Add Student</Link>
        
      </div>
      <div className="container">
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-hover">
            <thead className="thead-dark">
              <tr>
                {Object.keys(tableheadings).map((heading, index) => (
                  <th
                    key={index}
                    className="text-center"
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <span>{heading}</span>
                      {index &&
                      (heading === "First Name" ||
                        heading === "Last Name" ||
                        heading === "Email" ||
                        heading === "Phone Number") ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            style={{ margin: "-4px", cursor: "pointer" }}
                            onClick={() => sortStudentsDetails("asc", heading)}
                            className="icon-hover"
                          />
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ margin: "-4px", cursor: "pointer" }}
                            onClick={() => {
                              sortStudentsDetails("desc", heading);
                            }}
                            className="icon-hover"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataToDisplay.map((detail, index) =>
                detail.status ? (
                  <tr key={index}>
                    <td className="text-center">
                      <HashLink to="/admin/studentsDetails/#card">
                        <FontAwesomeIcon
                          icon={faPlus}
                          onClick={() => {
                            setStudentID(detail.id);
                            setCardVisibility(true);
                          }}
                        />
                      </HashLink>
                    </td>
                    <td className="text-center">{detail.first_name}</td>
                    <td className="text-center">{detail.last_name}</td>
                    <td className="text-center">{detail.email}</td>
                    <td className="text-center">{detail.phone}</td>
                    <td
                      className="text-center"
                      style={
                        !detail.batch_name
                          ? {
                              fontWeight: "bolder",
                              fontSize: 20,
                            }
                          : {}
                      }
                    >
                      {detail.batch_name ? detail.batch_name : "..."}
                    </td>
                    <td
                      className="text-center"
                      style={
                        !detail.course_name
                          ? {
                              fontWeight: "bolder",
                              fontSize: 20,
                            }
                          : {}
                      }
                    >
                      {detail.course_name ? detail.course_name : "..."}
                    </td>
                    <td
                      className="text-center"
                      style={
                        !detail.total_payment
                          ? {
                              fontWeight: "bolder",
                              fontSize: 20,
                            }
                          : {}
                      }
                    >
                      {detail.total_payment ? detail.total_payment : "..."}{" "}
                    </td>

                    <td className="text-center">
                      {feeStatus(detail.total_payment, detail.course_fees)}
                    </td>
                    <td className="text-center">
                      {detail.batch_name ||
                      detail.course_name ||
                      detail.payment_amount
                        ? "Complete"
                        : "Pending"}
                    </td>
                    <td className="text-center">
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => {
                            setReceiptsModal(true);
                            fetchStudentPayments(detail.id);
                          }}
                          className="icon-hover"
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() => {
                            //createAndDownloadPdf(detail, "download")
                            fetchStudentPayments(detail.id);
                            setReceiptsModal(true);
                          }}
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          className="icon-hover"
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          onClick={() => {
                            editHandler(detail.id);
                          }}
                          className="icon-hover"
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => deleteHandler(detail.id)}
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          className="icon-hover"
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )
              )}
              {/* {studentsData.length === 0 && ( */}
              {studentsData.length === 0 && (
                <>
                  <tr>
                    <td colSpan="12" style={{ textAlign: "center" }}>
                      No Record Founds
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div id="pagination-container">
          <div id="page-no-dropdown">
            <select
              name="page-number"
              onChange={handleSelectChange}
              value={currentPageNumber || 1}
            >
              {Array.from(
                Array(Math.ceil(studentsData.length / TOTAL_VALUES_PER_PAGE))
              )
                .map((e, i) => i + 1)
                .map((val) => {
                  return <option key={val}>{val}</option>;
                })}
            </select>
          </div>
          <div id="btn-container">
            <button onClick={goOnPrevPage}>Prev</button>
            <button onClick={goOnNextPage}>Next</button>
          </div>
        </div>
      </div>
      {cardVisibility && (
        <>
          <div
            className=" d-flex justify-content-end"
            style={{ marginRight: "10%" }}
          >
            <button
              className="btn btn-danger"
              onClick={() => setCardVisibility(false)}
            >
              X
            </button>
          </div>
          <div id="card">
            <StudentDetailsCard studentId={studentID} />
          </div>
        </>
      )}
    </>
  );
}

export default StudentsDetails;
