import React, { useContext } from "react";
import bannerWaveimage from "../../assets/images/banner-waveimage.png";
import { Link } from "react-router-dom";
import ActiveNavContext from "../../context/ActiveNavContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal, faUserGraduate } from "@fortawesome/free-solid-svg-icons";

export default function Carousel({
  bannerImg,
  className = "",
  instructorName,
  instructorRole,
  heading,
  subheading,
  summary,
  buttonText,
}) {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  return (
    <div className={className}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-md-left text-center">
          <div className="banner_wrapper position-relative">
            <figure className="banner-image mb-0">
              <img src={bannerImg} alt="" className="img-fluid"></img>
            </figure>
            <i className="graduate icon">
              <FontAwesomeIcon icon={faUserGraduate} />
            </i>
            <i className="medal icon">
              <FontAwesomeIcon icon={faMedal} />
            </i>

            <figure className="banner-waveimage mb-0">
              <img src={bannerWaveimage} alt="" className="img-fluid"></img>
            </figure>
            <div className="content">
              <h3>{instructorName}</h3>
              <span className="text-size-16">{instructorRole}</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="banner_content">
            <h3>{heading}</h3>
            <h1>{subheading}</h1>
            <div className="content">
              <p>{summary}</p>
              <Link
                to="/course"
                className="banner_button text-decoration-none"
                onClick={() => {
                  activeNav("course");
                  setPageState({
                    pageActive: false,
                    coursePageActive: true,
                  });
                }}
              >
                {buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
