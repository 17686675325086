import { useState } from "react";

const useNavigationClass = () => {
  const [activeLink, setActiveLink] = useState("home");

  const activeNav = (nav) => {
    setActiveLink(nav);
  };

  const getClassName = (page) => {
    return page === activeLink ? "nav-item active" : "nav-item";
  };

  return { activeLink, activeNav, getClassName };
};

export default useNavigationClass;
