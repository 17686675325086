import React, { useContext } from "react";
import { Link } from "react-router-dom";
import experiencePersonicon from "../../assets/images/experience-personicon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ActiveNavContext from "../../context/ActiveNavContext";

function InstructorCourseCard({
  courseImage,
  courseName,
  purchasedNum,
  price,
  numStars,
  id,
}) {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  const stars = [];

  for (let i = 0; i < numStars; i++) {
    stars.push(
      <li key={i}>
        <i>
          <FontAwesomeIcon icon={faStar} />
        </i>
      </li>
    );
  }

  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
      <div className="experience-box">
        <figure className="experience-image mb-0">
          <img
            src={courseImage}
            alt=""
            className="img-fluid"
            style={{ width: "350px", height: "240px" }}
          ></img>
        </figure>
        <div className="content">
          <ul className="list-unstyled">{stars}</ul>
          <Link
            to={`/singleCourse/${id}`}
            className="text-decoration-none"
            onClick={() => {
              activeNav("singleCourses");
              setPageState({
                pageActive: false,
                coursePageActive: true,
              });
            }}
          >
            <h4>{courseName}</h4>
          </Link>
          <div className="people">
            <span className="person">
              <img
                src={experiencePersonicon}
                alt=""
                className="img-fluid"
              ></img>
              {purchasedNum}
            </span>
            <span className="money">{price}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorCourseCard;
