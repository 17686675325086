import React, { useEffect } from "react";
import Register from "../components/home/Register";
import SubBanner from "../components/subBanner/SubBanner";
// import Sponsor from "../components/home/Sponsor";
import Detail from "../components/event/Detail";
import Highlight from "../components/event/Highlight";
import EventRegister from "../components/event/EventRegister";
import useScrollToTop from "../customHook/useScrollToTop";
import { useLocation, useParams } from "react-router-dom";
import { eventData } from "../content/content";
import Event from "./Event";

export default function EventsDetails() {
  useScrollToTop();

  const { id } = useParams();

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  if (!id) return <Event />;

  const event = eventData.find((event) => String(event.id) === id);

  return (
    <>
      <SubBanner title={"Events Details"} page={"Events Details"} />
      <Detail event={event} />
      <Highlight event={event} />
      <EventRegister />
      <Register
        pageClassName={"contactpage-register"}
        title={"Get Started"}
        subHeading={
          <>
            Register Your Account Get <span>Access</span> To Our Courses Online
          </>
        }
        note={
          "Please enter accurate information, including your qualifications and age."
        }
        buttonText={"Register Now"}
        dropDownHeading={"Choose Course"}
        dropDownOptions={[
          "Select Courses:",
          "General Duty Assistant",
          "Sewing Machine Operator",
          "Assistant Beauty Therapist",
        ]}
        url="registered_users"
      />
      {/* <Sponsor pageSponsor={"instructorpage-sponsor"} /> */}
    </>
  );
}
