import React from "react";
import "../../assets/css/notificationToast.css";
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

const NotificationToast = ({ show, message }) => {
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="notification-toast-container"
    >
      <Toast show={show} className="my-toast">
        <Toast.Body>
          {message}
          <i className="circle-check">
            <FontAwesomeIcon icon={faCircleCheck} />
          </i>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default NotificationToast;
