import React, { useEffect, useState } from "react";
import "./Dropdowns.css";
import axios from "axios";
import { SERVER_URL } from "../utils/database_Config";
const CourseBatchDropDown = ({
  onFilterChange,
  setStudents,
  setstudentsData,
  setDataToDisplay,
  TOTAL_VALUES_PER_PAGE,
}) => {
  const [courses, setCourses] = useState([]);

  const [batches, setBatches] = useState([]);

  const [couresWiseBatch, setCouresWiseBatch] = useState({
    courseId: "",
    batchId: "",
  });

  const [selectedCourse, setSelectedCourse] = useState("");

  const [feeStatus, setFeeStatus] = useState("");

  useEffect(() => {
    axios
      .get(`${SERVER_URL}courses`)
      .then((response) => setCourses(response.data))
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      axios
        .get(`${SERVER_URL}batches/${selectedCourse}`)
        .then((response) => setBatches(response.data))
        .catch((error) => console.error("Error fetching batches:", error));
    } else {
      setBatches([]);
    }
  }, [selectedCourse]);

  // useEffect(() => {

  //     axios
  //       .get(
  //         `${SERVER_URL}courses/${couresWiseBatch?.courseId}/batches/${couresWiseBatch?.batchId}`
  //       )
  //       .then((response) => {
  //         setStudents(response.data);
  //         setstudentsData(response.data);
  //       })
  //       .catch((error) => console.error("Error fetching courses:", error));

  // }, [couresWiseBatch?.batchId, couresWiseBatch?.courseId]);

  useEffect(() => {}, []);

  const fetchFilterData = () => {
    if (couresWiseBatch.courseId) {
      axios
        .get(
          `${SERVER_URL}courses/${couresWiseBatch?.courseId}/batches/${couresWiseBatch?.batchId}`
        )
        .then((response) => {
          const data = response.data.filter((item) => item.status === 1);
          setStudents(data);
          setstudentsData(data);
          setDataToDisplay(data.slice(0, TOTAL_VALUES_PER_PAGE));
        })
        .catch((error) => console.error("Error fetching courses:", error));
    } else {
      axios
        .get(`${SERVER_URL}getStudentsData`)
        .then((response) => {
          const data = response.data.filter((item) => item.status === 1);
          setstudentsData(data);
          setDataToDisplay(data.slice(0, TOTAL_VALUES_PER_PAGE));
        })
        .catch((error) => console.error("Error fetching details:", error));
    }
  };

  const enrollmentOnChange = (e) => {
    const { name, value } = e.target;
    setCouresWiseBatch({ ...couresWiseBatch, batchId: "", [name]: value });
    if (name === "courseId") {
      setSelectedCourse(e.target.value);
    }
    setFeeStatus("");
  };

  const handleChange = (e) => {
    const selectedStatus = e.target.value;
    setFeeStatus(selectedStatus);
    // fetchFilterData();
  };

  return (
    <div className="container dropdown-container">
      <div className="filter_dropdown">
        <label htmlFor="dropdown1">Courses :</label>
        <select id="courseId" name="courseId" onChange={enrollmentOnChange}>
          <option value="">Select Course</option>
          {courses.map((course) => (
            <option key={course.id} value={course.id}>
              {course.course_name}
            </option>
          ))}
        </select>
      </div>

      <div className="filter_dropdown">
        <label htmlFor="dropdown2">Batches :</label>
        <select id="batchId" name="batchId" onChange={enrollmentOnChange}>
          <option value="">Select Batch</option>
          {batches.map((batch) => (
            <option key={batch.id} value={batch.id}>
              {batch.batch_name}
            </option>
          ))}
        </select>
      </div>

      <div className="filter_dropdown">
        <label htmlFor="dropdown3">Filter by Payment Status :</label>
        <select id="filter" onChange={handleChange}>
          <option value="">Select Status</option>
          {couresWiseBatch.batchId && (
            <>
              <option value="partial paid">Partial paid</option>
              <option value="paid">Paid</option>
              <option value="fee pending">Fee pending</option>
            </>
          )}
        </select>
      </div>

      {feeStatus ? (
        <button
          className="apply-button"
          onClick={() => {
            onFilterChange(feeStatus);
          }}
        >
          Apply Filter
        </button>
      ) : (
        <button
          className="apply-button"
          onClick={async () => {
            fetchFilterData();
          }}
        >
          Apply Filter
        </button>
      )}
    </div>
  );
};

export default CourseBatchDropDown;
