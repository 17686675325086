import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function ScrollToTopButton({ isVisible, scrollToTop }) {
  return (
    <>
      {" "}
      <button
        id="button"
        className={isVisible ? "show" : ""}
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </>
  );
}
