import React, { useContext, useEffect } from "react";
import Article from "../components/home/Article";
import ContactSection from "../components/home/ContactSection";
import CoursesSection from "../components/home/CoursesSection";
// import Expert from "../components/home/Expert";
import Feature from "../components/home/Feature";
import Learning from "../components/home/Learning";
import Partner from "../components/home/Partner";
import Register from "../components/home/Register";
import Skill from "../components/home/Skill";
// import Sponsor from "../components/home/Sponsor";
import Video from "../components/home/Video";
import Banner from "../components/home/Banner";
import AboutSection from "../components/home/AboutSection";
import useScrollToTop from "../customHook/useScrollToTop";
import ActiveNavContext from "../context/ActiveNavContext";
import { useLocation } from "react-router-dom";

export default function Home() {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  useScrollToTop();

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Banner />
      <Partner />
      <Feature />
      <CoursesSection />
      <AboutSection activeNav={activeNav} setPageState={setPageState} />
      <Video />
      <Learning />
      <Register
        title={"Get Started"}
        subHeading={
          <>
            Register Your Account Get <span>Access</span> To Our Courses Online
          </>
        }
        note={
          "Please enter accurate information, including your qualifications and age."
        }
        buttonText={"Register Now"}
        dropDownHeading={"Choose Course"}
        dropDownOptions={[
          "Select Courses:",
          "General Duty Assistant",
          "Sewing Machine Operator",
          "Assistant Beauty Therapist",
        ]}
        url="registered_users"
      />
      <Skill />
      {/* <Expert /> */}
      <ContactSection />
      <Article />
      {/* <Sponsor /> */}
    </>
  );
}
