import React from "react";
import "../../assets/css/detail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLocationPin } from "@fortawesome/free-solid-svg-icons";

export default function Detail({ event }) {
  return (
    <section className="event_detail-con">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="event_detail_content_box" data-aos="fade-up">
              <figure className="event_detail-image mb-0">
                <img
                  src={event.eventImg}
                  alt=""
                  className="img-fluid"
                  style={{ width: "1110px", height: "600px" }}
                ></img>
              </figure>
              <div className="content">
                <h4>{event.heading}</h4>
                <span className="date">
                  <i>
                    <FontAwesomeIcon icon={faClock} />
                  </i>
                  {event.date}
                  <span className="dash mb-0">-</span>
                  {event.startTime} - {event.endTime}
                </span>
                <span className="mb-0 country">
                  <i>
                    <FontAwesomeIcon icon={faLocationPin} />
                  </i>
                  {event.eventPlace}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
