import React from "react";
import "../../assets/css/footer.css";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollToTop";
import logo from "../../assets/images/CSDC_Logo-removebg.png";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer({
  activeLink,
  activeNav,
  setPageState,
  vissible,
}) {
  const getNavClassName = (page) => {
    return page === activeLink ? "active" : "";
  };

  return (
    <section className="footer-con position-relative">
      <div className="upper">
        <div className="container">
          {vissible && (
            <div className="upper_portion">
              <Link
                to="/"
                onClick={() => {
                  scrollToTop();
                  activeNav("home");
                  setPageState({
                    pageActive: false,
                    coursePageActive: false,
                  });
                }}
              >
                <figure className="footer-logo mb-0">
                  <img src={logo} alt="" className="img_size"></img>
                </figure>
              </Link>
              <ul className="mb-0 list-unstyled quick_links">
                <li
                  className={getNavClassName("home")}
                  onClick={() => {
                    activeNav("home");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li
                  className={getNavClassName("instructors")}
                  onClick={() => {
                    activeNav("instructors");
                    setPageState({
                      pageActive: true,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link to="/instructors" onClick={scrollToTop}>
                    Instructors
                  </Link>
                </li>
                <li
                  className={getNavClassName("courses")}
                  onClick={() => {
                    activeNav("courses");
                    setPageState({
                      pageActive: false,
                      coursePageActive: true,
                    });
                  }}
                >
                  <Link to="/course" onClick={scrollToTop}>
                    Courses
                  </Link>
                </li>
                <li
                  className={getNavClassName("about")}
                  onClick={() => {
                    activeNav("about");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <Link to="/about" onClick={scrollToTop}>
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="container">
        <div className="middle_portion" style={{ padding: "55px 0 82px" }}>
          <div className="row">
            <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-6">
              <div className="sm_margin last_margin address">
                <h5 className="heading">Postal Address:</h5>
                <a
                  className="text-decoration-none"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.google.com/maps/place/Circulate+Healthcare+Services/@26.8631447,80.9987642,17z/data=!3m1!4b1!4m6!3m5!1s0x399be3564cff0101:0x9543dc8243b50708!8m2!3d26.8631399!4d81.0013391!16s%2Fg%2F11vqldj1dw?entry=ttu"
                >
                  <p className="mb-0 text-size-18">
                    Cubix Tower, D 3/31, Vibhuti Khand, Gomti Nagar, Lucknow.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6">
              <div className="sm_margin last_margin phone">
                <h5 className="heading">Phone:</h5>
                <a
                  href="tel:+917565998188"
                  className="text-size-18 text-decoration-none"
                >
                  +91 756 5998 188
                </a>
                <br></br>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="last_margin email">
                <h5 className="heading">Email:</h5>
                <a
                  href="mailto:info@circulateskilldevelopment.com"
                  className="text-size-18 text-decoration-none"
                >
                  info@&#8203;circulate&#8203;skilldevelopment&#8203;.com
                </a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6">
              <div className="follow">
                <h5 className="heading">Follow Us:</h5>
                <ul className="list-unstyled mb-0 social-icons">
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61558700468221"
                      className="text-decoration-none"
                    >
                      <i className=" social-networks">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://twitter.com/CirculateSkill"
                      className="text-decoration-none"
                    >
                      <i className=" social-networks">
                        <FontAwesomeIcon icon={faTwitter} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/circulateskill"
                      className="text-decoration-none"
                    >
                      <i className=" social-networks">
                        <FontAwesomeIcon icon={faInstagram} />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <span className="text">
            Copyright © 2024 Circulate skill development All Rights Reserved.
          </span>
          <span className="sign">~</span>
          <Link
            to="/privacyPolicy"
            className="text-decoration-none pages"
            onClick={(e) => {
              e.preventDefault();
              activeNav("");
              setPageState({
                pageActive: false,
                coursePageActive: false,
              });
            }}
          >
            <span>Privacy Policy</span>
          </Link>
          <span className="sign">I</span>
          <Link
            to="/terms_of_service"
            className="text-decoration-none pages"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span>Terms of Service</span>
          </Link>
        </div>
      </div>
    </section>
  );
}
