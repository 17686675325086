import React, { useContext } from "react";
import "../assets/css/about.css";
import AboutSection from "../components/home/AboutSection";
import Skill from "../components/home/Skill";
import Video from "../components/home/Video";
// import Expert from "../components/home/Expert";
import Article from "../components/home/Article";
// import Sponsor from "../components/home/Sponsor";
import useScrollToTop from "../customHook/useScrollToTop";
import SubBanner from "../components/subBanner/SubBanner";
import ActiveNavContext from "../context/ActiveNavContext";

export default function About() {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  useScrollToTop();
  return (
    <>
      <SubBanner title={"About Us"} page={"About"} />
      <AboutSection activeNav={activeNav} setPageState={setPageState} />
      <Skill aboutpageSkill={"aboutpage-skill"} />
      <Video />
      {/* <Expert aboutpageExpert={"aboutpage-expert"} /> */}
      <Article aboutpageArticle={"aboutpage-article"} />
      {/* <Sponsor /> */}
    </>
  );
}
