import React from "react";
import SubBanner from "../components/subBanner/SubBanner";
import Register from "../components/home/Register";
import ContactSection from "../components/home/ContactSection";
import Map from "../components/map/Map";
// import Sponsor from "../components/home/Sponsor";
import useScrollToTop from "../customHook/useScrollToTop";

export default function Contact() {
  useScrollToTop();

  return (
    <>
      <SubBanner title={"Contact Us"} page={"Contact"} />
      <Register
        pageClassName={"contactpage-register"}
        title={"Contact Us"}
        subHeading={"Get in Touch With Us"}
        option={false}
        buttonName={"Send Message"}
        contactPhone={"mb-md-0"}
        url="contact_us"
      />
      <ContactSection contactPage={"contactpage-contact"} />
      <Map />
      {/* <Sponsor pageSponsor={"contactpage-sponsor"} /> */}
    </>
  );
}
