/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../assets/css/faqSection.css";

export default function FaqSection() {
  const accordianData = [
    {
      question: "Assist the patient in maintaining normal elimination",
      answer:
        "This OS unit is concerning aiding a patient in elimination by General Duty Assistant (GDA). The purpose of elimination is to assist the patient in urination and defecation and maintain hygiene during the process",
    },
    {
      question: "Transferring a patient within the hospital",
      answer:
        "This OS unit is regarding transferring a patient by General Duty Assistant (GDA). The purpose of transferring is to move the patient to and from a bed, wheelchair or chair using proper body mechanics with a focus on safety and planning.",
    },
    {
      question: "Communicating appropriately with co-workers",
      answer:
        "This OS unit is regarding act with co-workers together with nurses by General Duty Assistant. Communicating effectively with co-workers is very important to make the sure continuing and affordable quality of patient care.",
    },
    {
      question: "Prevent and control infection",
      answer:
        "This OS unit is regarding following infection management procedure by General Duty Assistant. Infection management is an integral part of clinical care that focuses on preventing unfolds microorganisms whereas acting completely different patient care procedures.",
    },
    {
      question:
        "Assist nurse in performing procedures as instructed in the care plan",
      answer:
        "This OS unit is regarding the procedures to be performed by General Duty Assistant. There are certain simple procedures like an enema or preparing the patient for an operation that needs to be performed by the General Duty Assistant.",
    },
    {
      question:
        "Assist nurse in observing and reporting the change in patient condition",
      answer:
        "This OS unit is about making observations and reporting changes in the patient’s condition by General Duty Assistant. Observation and reporting by GDA are important for continued patient care and wellbeing and is an integral part of the job.",
    },
    {
      question: "Assist nurse in measuring patient parameters accurately",
      answer:
        "This OS unit is about responding to the patient’s call by General Duty Assistant. The purpose of responding to the call bell is to check if the patient needs something and to provide a solution promptly. Promptly responding to call bells is very important for patient satisfaction.",
    },
    {
      question: "Practice Code of conduct while performing duties",
      answer:
        "This OS unit is about following the rules, regulations and the code of conduct set up by the healthcare provider The Allied health professional must adhere to the protocols and guidelines relevant to the field and practice This OS unit applies to all Allied health professionals working in an organized environment and to whom specific regulations and codes of conduct apply.",
    },
    {
      question: "Follow biomedical waste disposal protocols",
      answer:
        "This OS unit is about the safe handling and management of health care waste. This unit applies to all Allied Health professionals.",
    },
    {
      question: "Respond to patient’s call",
      answer:
        "This OS unit is regarding following infection management procedure by General Duty Assistant. Infection management is an integral part of clinical care that focuses on preventing unfolds microorganisms whereas acting completely different patient care procedures.",
    },
    {
      question: "Clean medical equipment under the supervision of the nurse",
      answer:
        "This OS unit is about cleaning medical equipment by a General Duty Assistant under the supervision of the nurse.",
    },
    {
      question: "Transport patient, manage to change and transport the laundry",
      answer:
        "This OS unit is about transporting patient samples, drugs, patient documentation (patient files, discharge summary, etc.), changing/ transporting/ managing laundry/ linen on the floor.",
    },
    {
      question: "Carry out last office (Death Care)",
      answer:
        "This OS unit is about providing basic care and transferring a patient’s body post-death by General Duty Assistant.",
    },
    {
      question: "Act within the limits of your competence and authority",
      answer:
        "This OS unit is about recognizing the boundaries of the role and responsibilities and working within the level of competence in accordance with legislation, protocols, and guidelines. This is applicable to all Allied Health Professionals working in an organized, regulated environment.",
    },
    {
      question: "Work effectively with others",
      answer:
        "This OS unit is about working effectively with other people who can be part of the immediate team, organization or external to the team or organization This OS unit applies to all Allied health professionals operating during a team or cooperative atmosphere.",
    },
    {
      question: "Manage work to meet requirements",
      answer:
        "This OS unit is about planning and organizing work and developing oneself further in the organization. This unit applies to all Allied Health professionals",
    },
    {
      question: "Maintain a safe, healthy and secure environment",
      answer:
        "This OS unit is about monitoring the working environment and ensuring a safe, healthy, secure and effective working conditions This OS unit applies to all Allied Health professionals working within an organized workplace.",
    },
  ];

  return (
    <section className="faq-con position-relative">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-12">
            <div className="faq_content text-center">
              <h2>Your Guide to Learning</h2>
              <p className="col-lg-10 mx-auto text-size-18 mb-0">
                Welcome to the FAQ page of{" "}
                <span> Circulate Skill Development</span>, Here, we address
                common queries and concerns to ensure a smooth and enjoyable
                training experience for all our students.
              </p>
            </div>
          </div>
        </div>
        <div className="faq-box">
          <div className="row">
            <div className="col-12">
              <div className="accordian-inner">
                <div className="accordion" id="faq_accordion">
                  {accordianData.map((data, index) => (
                    <div className="accordion-card" key={index}>
                      <div
                        className="card-header"
                        id={`headingOne${index + 1}`}
                      >
                        <a
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <h3>{data.question}</h3>
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby={`headingOne${index + 1}`}
                        data-parent="#faq_accordion"
                      >
                        <div className="card-body">
                          <p className="text-left mb-0">{data.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
