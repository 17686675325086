import React from "react";
import "../../assets/css/feature.css";
import { featureCardData } from "../../content/content";

export default function Feature() {
  return (
    <section className="feature-con">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-12">
            <div className="feature_content text-center">
              <h2>Key Features of Circulate Skill Development</h2>
              <p className="col-xl-8 col-lg-10 mx-auto mb-0 text-size-18">
                Empower Your Career Growth with Circulate's Cutting-Edge
                Training Solutions, Designed to Elevate Your Skills and Propel
                You Towards Success.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {featureCardData.map((item, index) => {
            return (
              <FeatureCard
                key={index}
                icon={item.icon}
                heading={item.heading}
                content={item.content}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export function FeatureCard({ icon, heading, content }) {
  return (
    <div className="col-lg-4 col-md-4 col-sm-8 col-12 column3">
      <div className="feature-box position-relative text-center">
        <figure className="icon">
          <img src={icon} alt="" className="img-fluid"></img>
        </figure>
        <h3>{heading}</h3>
        <p className="mb-0 text-size-16">{content}</p>
      </div>
    </div>
  );
}
