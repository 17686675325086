import React from "react";
import "../../assets/css/coursesSection.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { coursesCardData } from "../../content/content";
import CoursesCard from "../Cards/CoursesCard";

export default function CoursesSection({ coursePage = "", children }) {
  return (
    <section className={`course-con ${coursePage}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="course_content text-center" data-aos="fade-up">
              <h2>Most Popular Courses</h2>
              <p className="col-xl-8 col-lg-10 mx-auto mb-0 text-size-18">
                Discover diverse career paths with our courses in healthcare,
                fashion, and beauty therapy. Unlock your potential and thrive in
                your chosen field.
              </p>
            </div>
          </div>
        </div>
        <div className="course-content" id="courses">
          <div className="row justify-content-center">
            {coursesCardData.map((item) => (
              <CoursesCard
                key={item.id}
                image={item.image}
                id={item.id}
                price={item.price}
                heading={item.heading}
                content={item.content}
                link={item.link}
              />
            ))}
          </div>
          {children}
        </div>
      </div>
    </section>
  );
}
