import React from "react";
import "../../assets/css/blog.css";
import courseImage1 from "../../assets/images/course-image1.jpg";
import courseImage2 from "../../assets/images/course-image2.jpg";
import courseImage3 from "../../assets/images/course-image3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCheck,
  faCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollToTop";

export default function Blog({
  title,
  blogContent,
  keyFeature,
  image,
  facebook,
  twitter,
  instagram,
  linkedIn,
}) {
  return (
    <section className="single_course-con">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image" data-aos="fade-up">
                <img src={image} alt="" className="img-fluid"></img>
              </figure>
              <div className="content1">
                <h3>{title}</h3>
                <div className="admin_icon">
                  <i>
                    <FontAwesomeIcon icon={faUser} />
                  </i>
                  <span className="text-size-14 text-mr">By : Admin</span>
                  <i className="mb-0 calendar">
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </i>
                  <span className="mb-0 text-size-14">Jun 20,2024</span>
                </div>
              </div>
              {blogContent.map((item, index) => (
                <p key={index} className="text1 text-size-18">
                  {item}
                </p>
              ))}
              <ul className="list-unstyled list">
                {keyFeature?.map((item, index) => (
                  <li className="text-size-18" key={index}>
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="icon">
                <h3>Social Share</h3>
                <ul className="mb-0 list-unstyled">
                  <li>
                    <a
                      href={linkedIn}
                      className="mb-0 text-decoration-none"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={instagram}
                      className="mb-0 text-decoration-none"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i>
                        <FontAwesomeIcon icon={faInstagram} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={facebook}
                      className="mb-0 text-decoration-none"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i>
                        <FontAwesomeIcon icon={faFacebookF} />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={twitter}
                      className="mb-0 text-decoration-none"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i>
                        <FontAwesomeIcon icon={faTwitter} />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="row">
              {/* <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                <div className="box" data-aos="fade-up">
                  <h3>Subscribe To Our Newsletter</h3>
                  <form
                    id="contactpage1"
                    method="POST"
                    action="javascript"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form_style"
                        placeholder="Enter Email Address:"
                        name="email"
                      ></input>
                    </div>
                    <button
                      type="submit"
                      className="subscribe_now text-white text-decoration-none"
                    >
                      Subscribe Now
                    </button>
                  </form>
                </div>
              </div> */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 order-lg-2 order-3">
                <div className="box box2" data-aos="fade-up">
                  <h3>Recent Post</h3>
                  <div className="all_feed">
                    <div className="feed">
                      <figure className="feed-image mb-0">
                        <img
                          src={courseImage1}
                          alt=""
                          className="img-fluid"
                        ></img>
                      </figure>
                      <Link
                        to="/singleCourse"
                        className="mb-0"
                        onClick={(e) => e.preventDefault()}
                      >
                        Dive into a World of Knowledge with Our Online Courses
                      </Link>
                    </div>
                    <div className="feed">
                      <figure className="feed-image mb-0">
                        <img
                          src={courseImage2}
                          alt=""
                          className="img-fluid"
                        ></img>
                      </figure>
                      <Link
                        to="/singleCourse"
                        className="mb-0"
                        onClick={(e) => e.preventDefault()}
                      >
                        Explore Our Latest Online Learning Offerings
                      </Link>
                    </div>
                    <div className="feed">
                      <figure className="feed-image mb-0">
                        <img
                          src={courseImage3}
                          alt=""
                          className="img-fluid"
                        ></img>
                      </figure>
                      <Link
                        to="/singleCourse"
                        className="mb-0"
                        onClick={(e) => e.preventDefault()}
                      >
                        Discover a Wealth of Online Courses Tailored for Success
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-6 col-sm-6 col-12 order-lg-3 order-2">
                <div className="box" data-aos="fade-up">
                  <h3>Category</h3>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faCircle} />
                      </i>
                      <Link
                        to="/singleCourse/1"
                        className="text-decoration-none"
                        onClick={scrollToTop}
                      >
                        General Duty Assistance
                      </Link>
                    </li>
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faCircle} />
                      </i>
                      <Link
                        to="/singleCourse/2"
                        className="text-decoration-none"
                        onClick={scrollToTop}
                      >
                        Sewing Machine Operator
                      </Link>
                    </li>
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faCircle} />
                      </i>
                      <Link
                        to="/singleCourse/3"
                        className="text-decoration-none"
                        onClick={scrollToTop}
                      >
                        Beauty Therapist Assistant
                      </Link>
                    </li>

                    {/* <li>
                      <i>
                        <FontAwesomeIcon icon={faCircle} />
                      </i>
                      <Link
                        to="/singleCourse"
                        className="mb-0 text-decoration-none"
                      >
                        Health & Wellness
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
