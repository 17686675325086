import React from "react";
import "../../assets/css/skill.css";
import skillImage from "../../assets/images/skill-img.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightDots,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";

export default function Skill({ aboutpageSkill }) {
  return (
    <section className={`skill-con ${aboutpageSkill}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-md-left text-center">
            <div className="skill_wrapper position-relative">
              <figure className="skill-image mb-0" data-aos="zoom-in">
                <img src={skillImage} alt="" className="img-fluid"></img>
              </figure>
              <div className="icon cap">
                <FontAwesomeIcon icon={faGraduationCap} />
              </div>
              <div className="icon arrow-up">
                <FontAwesomeIcon icon={faArrowUpRightDots} />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="skill_content position-relative" data-aos="fade-up">
              <h6>Professional Growth</h6>
              <h2>Start The New Year With New Skills With Us!</h2>
              <p className="text-size-18">
                As we embark on a fresh year, seize the opportunity to invest in
                your personal and professional development with Circulate Skill
                Development. Our specially curated package plans are designed to
                kickstart your learning journey and equip you with the skills
                needed to thrive in 2024.
              </p>
              {/* <Link
                to="/pricing"
                className="default-btn text-decoration-none"
                onClick={(e) => e.preventDefault()}
              >
                View Package Plans
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
