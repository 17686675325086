import React from "react";
import "../../assets/css/eventRegister.css";
import { HashLink } from "react-router-hash-link";

export default function EventRegister() {
  return (
    <section className="event_register-con position-relative">
      <div className="container">
        <div className="event_register_wrapper" data-aos="fade-up">
          <h3>Why Attend Circulate Skill Development?</h3>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="detail">
                <h4>Professional Development:</h4>
                <p className="text-size-16 mb-0">
                  Acquire new skills, gain insights, and stay updated on the
                  latest trends in industry.
                </p>
              </div>
              <div className="detail">
                <h4>Networking:</h4>
                <p className="text-size-16 mb-0">
                  Connect with like-minded individuals, build partnerships, and
                  exchange ideas with professionals from diverse backgrounds.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="detail">
                <h4>Inspiration:</h4>
                <p className="text-size-16 mb-0">
                  Be inspired by success stories, innovative approaches, and
                  transformative strategies shared by our esteemed speakers.
                </p>
              </div>
              <div className="detail">
                <h4>Resource Exploration:</h4>
                <p className="text-size-16 mb-0">
                  Discover cutting-edge resources, tools, and technologies that
                  can enhance your learning and enrich the learning experience
                  for your future.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="event_register_content" data-aos="fade-up">
          <h3>Registration Information:</h3>
          <p className="text-size-16">
            Secure your spot at Circulate Skill Development and be part of a
            transformative training experience. Limited spaces available.
            Reserve your seat now to be at the forefront of skill development
            innovation!
          </p>
          <HashLink
            to="/eventDetail/1/#register"
            className="default-btn text-decoration-none"
          >
            Enroll Now
          </HashLink>
        </div>
      </div>
    </section>
  );
}
