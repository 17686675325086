import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ApplicationForm from "./ApplicationForm";

const JobDetails = ({ job, onClose }) => {
  const [showForm, setShowForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showJobDetails, setShowJobDetails] = useState(true);

  const handleFormOpen = () => {
    setShowJobDetails(false);
    setShowForm(true);
  };

  const handleFormClose = () => {
    setShowForm(false);
    setShowJobDetails(true);
  };

  const handleFormSubmit = () => {
    setShowForm(false);
    setShowConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    onClose();
  };

  return (
    <>
      {showJobDetails && (
        <Modal show={true} onHide={onClose} centered>
          <Modal.Header>
            <Modal.Title
              className="modal-title"
              style={{
                color: "#ff822d",
                fontWeight: "bold",
              }}
            >
              {job.title} - Apply Now
            </Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={() => {
                setShowJobDetails(false);
                onClose();
              }}
              style={{
                fontSize: "14px",
                fontWeight: "bolder",
                color: "red",
                border: "1px solid red",
                padding: "5px 10px",
                borderRadius: "8px",
              }}
            >
              <span aria-hidden="true">X</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Positions:</strong> {job.positions}
            </p>
            <p>
              <strong>Salary:</strong> {job.salary}
            </p>
            <p>
              <strong>Eligibility:</strong> {job.eligibility}
            </p>
            <p>
              <strong>Available Districts:</strong> {job.districts.join(", ")}
            </p>
            <p>
              <strong>Block/Locations:</strong> {job.locations.join(", ")}
            </p>
            <Button
              variant="outline-primary"
              className="apply-btn"
              onClick={handleFormOpen}
            >
              Proceed to Apply
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {showForm && (
        <ApplicationForm
          job={job}
          onClose={handleFormClose}
          onSubmit={handleFormSubmit}
        />
      )}

      {showConfirmation && (
        <Modal show={true} onHide={handleConfirmationClose} centered>
          <Modal.Header>
            <Modal.Title
              style={{
                color: "#ff822d",
                fontWeight: "bold",
              }}
            >
              Application Submitted Successfully
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Your application for {job.title} has been submitted successfully.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="apply-btn"
              onClick={handleConfirmationClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default JobDetails;
