import React from "react";
import "../../assets/css/experience.css";
import experiencelogo from "../../assets/images/experiencelogo-image.png";
import experienceprofile from "../../assets/images/teacher.png";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import InstructorCourseCard from "../Cards/InstructorCourseCard";
import { instructorCoursesData } from "../../content/content";

export default function Experience() {
  return (
    <section className="experience-con position-relative">
      <figure className="experiencelogo-image mb-0">
        <img src={experiencelogo} alt="" className="img-fluid"></img>
      </figure>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="experience_content text-center" data-aos="fade-up">
              <h6>Get to Know Our Instructors</h6>
              <h2 className="col-xl-8 col-lg-10 mx-auto mb-0">
                Discover the Experts Behind Your Online Learning Experience.
              </h2>
            </div>
          </div>
        </div>
        <div className="experience_profile" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="profile_wrapper">
                <figure className="profile-image mb-0">
                  <img
                    src={experienceprofile}
                    alt=""
                    className="img-fluid"
                  ></img>
                </figure>
                <div className="content">
                  <h3 className="mb-0">Rashmi Nigam</h3>
                  <span className="text-size-16">Academic Coordinator</span>
                  {/* <ul className="list-unstyled mb-0 icon">
                    <li>
                      <a
                        href="https://www.facebook.com/login/"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/login"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i>
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i>
                          <FontAwesomeIcon icon={faYoutube} />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Faccounts%2Fonetap%2F%3Fnext%3D%252F%26__coig_login%3D1"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i>
                          <FontAwesomeIcon icon={faInstagram} />
                        </i>
                      </a>
                    </li>
                  </ul> */}
                </div>
                {/* <ul className="list-unstyled mb-0 info">
                  <li className="">
                    <i className="fa-sharp fa-solid fa-envelope"></i>
                    <a
                      href="mailto:catherine@skillboost.com"
                      className="text-decoration-none"
                      onClick={(e) => e.preventDefault()}
                    >
                      Rashminigam@circulateskilldevelopment.com
                    </a>
                  </li>
                  <li>
                    <i className="phone fa-solid fa-phone-volume"></i>
                    <a href="tel:+1234567890" className="text-decoration-none">
                      +1 (234 567 89 0)
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="profile_content">
                <div className="detail">
                  <h3>Passionate Experts, Dedicated Instructors</h3>
                  <p className="text-size-16 mb-0">
                    At <span>Circulate Skill Development Corporation</span>, we
                    take pride in our team of passionate and dedicated
                    instructors. Each one brings a wealth of knowledge and
                    real-world experience to the classroom, ensuring that your
                    learning experience goes beyond textbooks.
                  </p>
                </div>
                <div className="detail">
                  <h3>Teaching Skill development</h3>
                  <p className="text-size-16 mb-0">
                    <span className="heading">Educators with a Purpose</span>
                    Discover the teaching skill development that drive our
                    instructors. Whether it's fostering critical thinking
                    skills, promoting creativity, or providing practical
                    insights, our educators are committed to empowering you with
                    knowledge that goes beyond the curriculum.
                  </p>
                </div>
                <div className="detail detail3">
                  <h3>Instructor Achievements:</h3>
                  <p className="text-size-16 mb-0">
                    <span className="heading">Recognizing Excellence</span>
                    Celebrate the achievements and accolades of our esteemed
                    instructors. From published works to industry recognitions,
                    our educators bring a wealth of expertise to the classroom.
                    Learn from the best and elevate your learning experience.
                  </p>
                </div>
                {/* <Link
                  onClick={(e) => e.preventDefault()}
                  to="/instructor_spotlight#register"
                  className="profile_button text-decoration-none"
                >
                  Enquire About Collaboration
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="experience_wrapper" data-aos="fade-up">
          <div className="row">
            <div className="col-12">
              <h3>
                <span>Rashmi Nigam's</span> Courses :
              </h3>
            </div>
            {instructorCoursesData.map((course, index) => (
              <InstructorCourseCard
                key={index}
                id={course.id}
                courseImage={course.courseImage}
                courseName={course.courseName}
                purchasedNum={course.purchasedNum}
                price={course.price}
                numStars={course.numStars}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
