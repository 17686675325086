import axios from "axios";
import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { SERVER_URL } from "../utils/database_Config";

const districtBlockMapping = {
  Kaushambi: ["Sirathu", "Kada", "Manjhanpur", "Murat Ganj"],
  Prayagraj: [
    "Jasra",
    "Shankargarh",
    "Pratappur",
    "Sewaban",
    "Dhanupur",
    "Chaka",
    "Manda",
    "Bahariya",
    "Handia",
    "Baghadurpur",
  ],
  Bhadohi: ["Bhadohi", "Suriyawan", "Gyanpur", "Deegh", "Abholi", "Aurai"],
  Fatehpur: [
    "Teliyani",
    "Badha",
    "Chitahara",
    "Haswa",
    "Asothar",
    "Hathgaon",
    "Vijayipur",
    "Dhata",
    "Amrauli",
  ],
  Pratapgarh: [
    "Mandhata",
    "Kunda",
    "Kalakankar",
    "Babaganj",
    "Ewti",
    "Mangraura",
    "Aaspur Devsara",
    "Lalganj",
    "Sangipur",
    "Gaur",
    "Shivganj",
  ],
};

const ApplicationForm = ({ job, onClose, onSubmit }) => {
  //   const [showApplyModal, setShowApplyModal] = useState(true);
  //   const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const appliedJob = job.title;
  console.log("Job applied form", {
    ...formData,
    preferred_job_district: selectedDistrict,
    preferred_job_block: selectedBlock,
    applied_job: appliedJob,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${SERVER_URL}applyForJob`, {
        ...formData,
        preferred_job_district: selectedDistrict,
        preferred_job_block: selectedBlock,
        applied_job: appliedJob,
      })
      .then(() => {
        onSubmit();
        console.log("Job applied succeffully", {
          ...formData,
          preferred_job_district: selectedDistrict,
          preferred_job_block: selectedBlock,
          applied_job: appliedJob,
        });
      })
      .catch((err) => {
        console.log("Error while applying job : ", err.response);
        if (err?.response?.data?.appliedForSameJob) {
          setError(err?.response?.data?.message);
        }
      })
      .finally(() => {
        console.log("Finally job apply called");
        setLoading(false);
      });
    // console.log('Form Data Submitted:', formData);
    // onSubmit(); // Trigger submission logic to show confirmation modal
  };

  const handleDistrictChange = (e) => {
    const district = e.target.value;
    setSelectedDistrict(district);
    setSelectedBlock("");
    setError("");
  };

  const handleBlockChange = (e) => {
    setSelectedBlock(e.target.value);
    setError("");
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    // setShowApplyModal(true);
  };

  return (
    <>
      <Modal show={true} onHide={onClose} centered>
        <Modal.Header>
          <Modal.Title
            className="modal-title"
            style={{
              color: "#ff822d",
              fontWeight: "bold",
            }}
          >
            Apply for {job.title}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => {
              // setShowJobDetails(false);
              onClose();
            }}
            style={{
              fontSize: "14px",
              fontWeight: "bolder",
              color: "red",
              border: "1px solid red",
              padding: "5px 10px",
              borderRadius: "8px",
            }}
          >
            <span aria-hidden="true">X</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your phone number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                maxLength={10}
                minLength={10}
              />
            </Form.Group>

            {/* Preferred District */}
            <Form.Group controlId="districtSelect" className="mt-3">
              <Form.Label>Preferred District</Form.Label>
              <Form.Control
                as="select"
                value={selectedDistrict}
                onChange={handleDistrictChange}
                required
              >
                <option value="">Select District</option>
                {Object.keys(districtBlockMapping).map((district) => (
                  <option key={district} value={district}>
                    {district}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Block Selection */}
            {selectedDistrict && (
              <Form.Group controlId="blockSelect" className="mt-3">
                <Form.Label>Preferred Block/Place</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedBlock}
                  onChange={handleBlockChange}
                  required
                >
                  <option value="">Select Block/Place</option>
                  {districtBlockMapping[selectedDistrict].map(
                    (block, index) => (
                      <option key={index} value={block}>
                        {block}
                      </option>
                    )
                  )}
                </Form.Control>
              </Form.Group>
            )}
            {error && (
              <p
                style={{ color: "red", fontSize: "14px", marginBottom: "0px" }}
              >
                * {error}
              </p>
            )}

            <Button
              className="mt-4 submit-btn"
              variant="primary"
              type="submit"
              style={{
                backgroundColor: "#ff822d",
                border: "none",
                color: "white",
              }}
              disabled={loading}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={handleSuccessClose}>
        <Modal.Header closeButton>
          <Modal.Title>Application Submitted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{formData.name}'s application has been submitted successfully.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApplicationForm;
