import React, { useContext } from "react";
import { HashLink } from "react-router-hash-link";
import ActiveNavContext from "../../context/ActiveNavContext";

export default function LearningPartnerCard({ image, heading, summary, link }) {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
      <div className="md_margin last_margin learning-box">
        <figure className="learning-image">
          <img
            src={image}
            alt=""
            className="img-fluid"
            style={{ width: "250px", height: "250px" }}
          ></img>
        </figure>
        <h3>{heading}</h3>
        <p className="text-size-16">
          {summary.replace(/\s/g, "").length > 50
            ? `${summary.substring(0, 50)}...`
            : summary}
        </p>
        <HashLink
          className="apply_now text-decoration-none"
          to={link}
          onClick={() => {
            activeNav("home");
            setPageState({
              pageActive: false,
              coursePageActive: false,
            });
          }}
        >
          Apply Now
        </HashLink>
      </div>
    </div>
  );
}
