import React from "react";
import "../../assets/css/banner.css";
import staringImg from "../../assets/images/banner-starimage.png";
import Carousel from "../carousel/Carousel";
import { bannerCarouselData } from "../../content/content";

export default function Banner() {
  return (
    <div className="banner_outer position-relative">
      <section className="banner-con">
        <div className="container position-relative">
          <figure className="banner-starimage mb-0">
            <img src={staringImg} alt="" className="img-fluid"></img>
          </figure>
          <div
            id="bannercarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <ul className="carousel-indicators">
              <li
                data-target="#bannercarousel"
                data-slide-to="0"
                className="active"
                aria-current="true"
              ></li>
              <li
                data-target="#bannercarousel"
                data-slide-to="1"
                className=""
              ></li>
              <li
                data-target="#bannercarousel"
                data-slide-to="2"
                className=""
              ></li>
            </ul>
            <div className="carousel-inner">
              {bannerCarouselData.map((item) => (
                <Carousel
                  className={
                    item.id === 1 ? "carousel-item active" : "carousel-item"
                  }
                  key={item.id}
                  bannerImg={item.bannerImg}
                  instructorName={item.instructorName}
                  instructorRole={item.instructorRole}
                  heading={item.heading}
                  subheading={item.subheading}
                  summary={item.summary}
                  buttonText={item.buttonText}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
