import React from "react";
import Register from "../components/home/Register";
// import Sponsor from "../components/home/Sponsor";
import SubBanner from "../components/subBanner/SubBanner";
import Potential from "../components/pricing/Potential";
import Choose from "../components/pricing/Choose";
import useScrollToTop from "../customHook/useScrollToTop";

export default function Pricing() {
  useScrollToTop();

  return (
    <>
      <SubBanner title={"Pricing Plan"} page={"Pricing"} />
      <Potential />
      <Choose />
      <Register
        title={"Get Started"}
        subHeading={
          <>
            Register Your Account Get <span>Access</span> To Our Courses Online
          </>
        }
        note={
          "Please enter accurate information, including your qualifications and age."
        }
        buttonText={"Register Now"}
        dropDownHeading={"Choose Course"}
        dropDownOptions={[
          "Select Courses:",
          "General Duty Assistant",
          "Sewing Machine Operator",
          "Assistant Beauty Therapist",
        ]}
      />
      {/* <Sponsor pageSponsor={"instructorpage-sponsor"} /> */}
    </>
  );
}
